import React from 'react';
import myemotionhoc from './myemotionhoc';
import {css} from 'emotion';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';

function getFontFamily(themeFontFamily) {
    if (themeFontFamily) {
        return `'${themeFontFamily}','Poppins', 'Helvetica Neue', 'Calibri Light', Roboto, sans-serif !important`;
    }
    return `'Poppins', 'Helvetica Neue', 'Calibri Light', Roboto, sans-serif !important`;

}

const mstyle = ({themefontfamily}) => css`
	font-family: ${getFontFamily(themefontfamily)};
`;


const OutsideContainer = myemotionhoc('div', mstyle);

function mapStateToProps(state) {
    return {
        themeFontCss: state.settings.themeFontCss,
        themePrimaryColor: state.settings.themePrimaryColor,
        store_name: state.settings.store_name,
        store_logo: state.settings.store_logo,
        isRtl: state.settings.isRtl,
    };
}

const ThemedMobileToolbar = connect(mapStateToProps)((props) => props.themePrimaryColor &&
    (<Helmet>
        <meta name="theme-color" content={props.themePrimaryColor}/>
    </Helmet>));

const BrandedManifest = connect(mapStateToProps)(({store_name, themePrimaryColor, store_logo}) => {
    let myDynamicManifest = {
        name: store_name,
        short_name: store_name,
        description: store_name,
        start_url: '/?utm_source=app',
        background_color: themePrimaryColor,
        theme_color: themePrimaryColor,
        icons: [{
            src: store_logo,
            sizes: '256x256',
            type: 'image/png'
        }]
    };
    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], {type: 'application/json'});
    const manifestURL = URL.createObjectURL(blob);
    const selector = document.querySelector('link[rel=manifest]');
    if (selector) selector.setAttribute('href', manifestURL);
    return null;
});

export default connect(mapStateToProps)((props) => {
    return (
        <OutsideContainer className={`min-vh-100 ${props.isRtl ? 'rtl' : ''}`}>
            <ThemedMobileToolbar/>
            <BrandedManifest/>
            {
                props.themeFontCss ? <link href={props.themeFontCss} rel="stylesheet" async/> :
                    <link href={'//fonts.googleapis.com/css?family=Poppins'} rel={'stylesheet'} async/>
            }
            {props.children}
        </OutsideContainer>)
});
