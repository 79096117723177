import React, {useEffect, useState} from 'react';
import FormattedHelmet from '../cmp/intl/FormattedHelmet';
import {connect} from 'react-redux';
import CreativesGrid from './creatives/CreativesGrid';
import RefLinkMaker from './cmp/RefLinkMaker';
import {FormattedMessage} from 'react-intl';
import Modal from '../cmp/Modal';
import FileUploadMarketingPage from "./fileupload/FileUploadMarketingPage";
import {ExternalLinkIcon, ThreeDotsHorizontalIcon} from '../../icons/Icons'
import ProductCommissionsTableNew from "./commissions/ProductCommissionsTableNew";
import CopyButton from "../cmp/CopyButton";
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import {facebookShare, openShareWindow, pinterestShare, twitterShare, whatsAppShare} from "../../tools/func";
import {useSelector, useDispatch} from 'react-redux'
import {deleteLink, fetchLinks} from "../../redux/linkbox/linksAction";
import Button from "../auth/components/Button";

function OffersPage ({website, ref_code, show_site_stripe, aff_product_linker, aff_file_upload, aff_media_assets, link_type, aff_show_product_commissions}) {
    const [modalVisible, toggleModalVisible] = useState(false)
    const w = website.startsWith('http') ? website : `https://${website}`;
    return (
            <>
                <FormattedHelmet id={'Marketing Tools'}/>
                <div className={'mb-4'}>
                    {
                        aff_product_linker && link_type > -1 && <>

                            <div className={'row justify-content-between'}>
                                <div className={'col'}>
                                    <h3><FormattedMessage id={'productLinks'}/>
                                        {
                                            show_site_stripe ?
                                            <a href={`${w}/?toolbar=${ref_code}`}
                                               className={"ml-2 p-2"}
                                               target={"_blank"} rel={"noopener nofollow"}>
                                                <ExternalLinkIcon/>
                                            </a> : null
                                        }
                                    </h3>
                                </div>
                                {
                                    aff_show_product_commissions &&
                                    <div className={'col-auto'}>
                                        <button className="btn btn-light" onClick={()=>toggleModalVisible(true)}>
                                            <FormattedMessage id={'Commissions'}/>
                                        </button>
                                        <Modal visible={modalVisible} onClose={()=>toggleModalVisible(false)}
                                               title={<FormattedMessage id={'Product Commissions'}/>}>
                                            <ProductCommissionsTableNew />
                                        </Modal>
                                    </div>
                                }
                            </div>
                            <FormattedMessage tagName={"p"} id={'productLinkSubtitle'}/>
                            <RefLinkMaker />
                            <p className={'text-muted text-center'}>
                                <FormattedMessage id={'productLinkDescription'}/>
                            </p>
                            <LinkBox />
                            <div className={'my-2 py-2'}>
                                <hr/>
                            </div>
                        </>
                    }
                    {
                        aff_file_upload && <FileUploadMarketingPage/>
                    }
                    {
                        aff_media_assets && <CreativesGrid/>
                    }
                </div>
            </>
        );
}
function LinkBox(){
    const [loading, setLoading] = useState(false);
    const data = useSelector((state)=>state.links)
    const dispatch = useDispatch()
    useEffect(()=>{
        setLoading(true)
       dispatch(fetchLinks())
           .then(()=>{
               setLoading(false)
           })
    },[])
    if(loading) return null
    if(data.length === 0) return null;
    return <>
        <h4><FormattedMessage id={"History"}/></h4>
        <div className={"row flex-wrap"}>
        {
            data.map((item)=>{
                return <div key={item.url} className={"col-md-4 col-sm-6 mb-2"}>
                    <div className="card">
                        {item.image &&
                        <img src={item.image} style={{width: '100%', height: 200, objectFit: 'cover'}}/>
                        }
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between">
                                <div>{item.title}</div>
                                <ShareDropdown id={item.id} link={item.url} />
                            </div>
                            <small className={"text-muted"}>{item.url}</small>
                        </div>
                    </div>
                </div>
            })
        }
        </div>
    </>
}

function ShareDropdown({id, link}){
    console.log('link',{id, link})
    const shareOnFacebook = () => {
        openShareWindow(facebookShare(link), "facebook", "width=500, height=500")
    }
    const shareOnTwitter = () => {
        openShareWindow(twitterShare(link), 'twitter', 'width=600,height=300');
    }
    const shareOnPinterest = () => {
        openShareWindow(pinterestShare(link, '', ''), "pinterest", "width=600,height=300")
    }
    const shareOnWhatsApp = () => {
        window.open(whatsAppShare(link))
    }
    const dispatch = useDispatch();
    const onDelete = ()=>{
        dispatch(deleteLink(id))
    }

    return <UncontrolledDropdown>
        <DropdownToggle color={"transparent"} className={"p-0"}>
            <span><ThreeDotsHorizontalIcon/></span>
        </DropdownToggle>
        <DropdownMenu>
            <CopyButton className={"dropdown-item text-dark"}
                        text={link}
                        title={"Copy Link"}/>
            <DropdownItem header>
                <FormattedMessage id={"Share"}/>
            </DropdownItem>
            <DDItem onClick={shareOnFacebook} title={"Facebook"} src={"/images/facebook.svg"}/>
            <DDItem onClick={shareOnTwitter} title={"Twitter"} src={"/images/twitter.svg"}/>
            <DDItem onClick={shareOnPinterest} title={"Pinterest"}
                    src={"/images/pinterest.svg"}/>
            <DDItem onClick={shareOnWhatsApp} title={"WhatsApp"} src={"/images/whatsapp.svg"}/>
            <DropdownItem header>
                <FormattedMessage id={"Options"}/>
            </DropdownItem>
            <DropdownItem onClick={onDelete}><span className={"text-danger btn"}><FormattedMessage id={"Delete"} /></span></DropdownItem>
        </DropdownMenu>
    </UncontrolledDropdown>
}

function DDItem({onClick, title, src}) {
    return <DropdownItem onClick={onClick}>
        <SocialIcon title={title} src={src}/>
    </DropdownItem>
}
function SocialIcon({src, title}) {
    return <div className={"d-flex align-items-center"}>
        <img alt={title} src={src} width={18}/>
        <span className={"ml-2"}>{title}</span>
    </div>
}



function mapStateToProps(state) {
    const {
        is_redirect,
        show_site_stripe, website, ref_code, subscription, aff_file_upload, aff_show_product_commissions} = state.settings
    return {
        link_type: Number(is_redirect),
        show_site_stripe, website, ref_code, subscription, aff_file_upload,
        aff_show_product_commissions,
        aff_media_assets: state.settings.aff_media_assets === undefined ? true : state.settings.aff_media_assets,
        aff_product_linker: state.settings.aff_product_linker === undefined ? true : state.settings.aff_product_linker
    };
}

export default connect(mapStateToProps)(OffersPage);
