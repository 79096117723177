import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {post} from '../../api';
import AuthContainer from './components/AuthContainer';
import LogoRender from '../cmp/LogoRender';
import StyledButton from '../cmp/themed/StyledButton';
import StyledProgressLoader from '../cmp/themed/StyledProgressLoader';
import {FormattedMessage} from 'react-intl';
import FormInput from './components/FormInput';
import FormattedHelmet from '../cmp/intl/FormattedHelmet';
import RenderError from './components/RenderError';
import {connect} from "react-redux";

class ForgotPassword extends Component {
    state = {
        email: '',
        loading: false,
        success: null,
    }

    onChange = (e) => {
        this.setState({email: e.target.value});
    }


    handleFormSubmit = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const {email} = this.state;
        this.setState({loading: true, code: null});
        post('/forgotpassword', {
            email
        }).then((data) => {
            if (data.success) {
                this.setState({
                    success: <p className={'alert alert-success'}>
                        <FormattedMessage id={'recoverLinkSent'} values={{email}}/>
                    </p>
                });
            } else {
                this.setState({errorCode: data.code});
            }
            this.setState({loading: false});
        }).catch((e) => {
            this.setState({loading: false});
        });

    }

    render() {
        const {loading, success, errorCode, email} = this.state;
        return (
            <AuthContainer>
                <FormattedHelmet id={'recoverPassword'}/>
                <div className={'text-center mb-4'}>
                    <LogoRender/>
                </div>
                <FormattedMessage tagName={'h1'} id={'recoverPassword'}/>
                <div className={'card shadow-sm'}>
                    <div className={'card-body'}>
                        {
                            success ? success : null
                        }
                        {
                            errorCode && <RenderError code={errorCode}/>
                        }
                        {
                            !success ? (
                                <form onSubmit={this.handleFormSubmit}>
                                    <p className={'text-muted'}>
                                        <FormattedMessage id={'recoverPasswordFormDesc'}/>
                                    </p>
                                    <FormInput id={'emailAddress'}>
                                        <input type={'email'}
                                               name={'email'}
                                               autoComplete={"email"}
                                               onInput={this.onChange}
                                               value={email}
                                               className={'form-control'}
                                        />
                                    </FormInput>
                                    <div className={'row justify-content-between align-items-center'}>
                                        <div className={'col'}>
                                                <StyledButton
                                                    loading={loading}
                                                    className={'btn btn-primary'}
                                                    onClick={this.handleFormSubmit}>
                                                    <FormattedMessage id={'recoverPassword'}/>
                                                </StyledButton>
                                        </div>
                                    </div>
                                </form>
                            ) : null
                        }
                    </div>
                </div>
                <div className={'mt-4'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-auto'}>
                            <Link to={'/login'}>
                                <FormattedMessage id={'Login'}/>
                            </Link>
                        </div>
                        {
                            this.props.allow_partner_registrations &&
                            <div className={'col-auto'}>
                                <Link to={'/create-account'}>
                                    <FormattedMessage id={'createAccount'}/></Link>
                            </div>
                        }
                    </div>
                </div>
            </AuthContainer>
        );
    }
}

function mapStateToProps(state) {
    return {
        allow_partner_registrations: state.settings.allow_partner_registrations
    }
}

export default connect(mapStateToProps)(ForgotPassword);
