import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

class LogoRender extends Component {
    render() {
        if (this.props.isEmbedded) return null;
        const {store_logo} = this.props;
        const style = this.props.logo_width ? {
            width: this.props.logo_width,
            height: 'auto',
            maxWidth: '100%'
        } : {
            maxWidth: '100%',
            minHeight: '44px',
            maxHeight: this.props.logo_height
        };


        return (
            <LinkedContainer linked={this.props.linked} className={this.props.className}
                             logo_link={this.props.logo_link}>
                <img src={store_logo} style={style} alt={this.props.store_name} title={this.props.store_name}/>
            </LinkedContainer>
        );
    }
}

function LinkedContainer({linked, logo_link, children, className}) {
    if (linked) {
        if (logo_link.startsWith('http')) {
            return <a href={logo_link} className={className} title={logo_link}>{children}</a>;
        }
        return <Link to={logo_link}>{children}</Link>;

    }
    return <span>{children}</span>;


}

function mapStateToProps(state, props) {
    const {logo_link} = (state.settings && state.settings.page_sections && state.settings.page_sections.default_navigation && state.settings.page_sections.default_navigation.props) || {}
    return {
        store_logo: state.settings.store_logo || 'https://static.goaffpro.com/7/images/1560763976309.png',
        isEmbedded: state.settings.isEmbedded,
        logo_height: props.logo_height || Number(state.settings.logo_height) || 80,
        logo_width: props.logo_width,
        store_name: state.settings.store_name,
        logo_link: logo_link || state.settings.logo_link || '/',
        linked: true, //for now link it always
    };
}

export default connect(
    mapStateToProps
)(LogoRender);
