import React, {Component} from 'react';
import {connect} from "react-redux";
import Reaptcha from 'reaptcha';

class RecaptchaRenderer extends Component {

    onVerified = (response) => {
        if (this.props.onChange) {
            this.props.onChange({target: {value: response}});
        }
    }

    render() {
        if (!this.props.recaptcha_enabled) return null;
        return (<div className={"form-group"}>
                <Reaptcha sitekey={this.props.recaptcha_site_key} onVerify={this.onVerified}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        recaptcha_enabled: state.settings.recaptcha_enabled,
        recaptcha_site_key: state.settings.recaptcha_site_key || '6Lf_jsQUAAAAAOLW40PpDXgZQDIjjnGldAE1fhYr',
        locale: state.settings.locale,
    }
}

export default connect(mapStateToProps)(RecaptchaRenderer);
