import React, {useEffect, useState} from 'react';
import {Link, Route, Switch, withRouter} from 'react-router-dom';
import Dashboard from './Dashboard';
import LoginPage from './auth/LoginPage';
import ForgotPassword from './auth/ForgotPassword';
import ResetPassword from './auth/ResetPassword';
import {get} from '../api';
import {connect} from 'react-redux';
import {actionMergeSetting, buildPageSections} from '../redux/settings/settingsAction';
import FbPixel from './cmp/FbPixel';
import LogoutPage from './auth/components/LogoutPage';
import OAuthCallback from './auth/components/OAuthCallback';
import VerifyEmail from './auth/VerifyEmail';
import LandingPage from './LandingPage';
import ContactUs from './pages/ContactUs';
import LandingContact from './pages/landing/LandingContact';
import LandingSectionsIndexLazy from './pages/landing/LandingSectionsIndexLazy';
import LandingLegalIndexLazy from './pages/landing/LandingLegalIndexLazy';
import {getCurrentQueryObject} from '../tools/func';
import * as localStorage from '../tools/storage';
import {IntlProvider} from 'react-intl';
import {Helmet} from "react-helmet";
import InvitationPage from './auth/InvitationPage';
import CreateAccountClassic from './auth/signup/CreateAccountClassic';
import CreateAccountModern from './auth/signup/CreateAccountModern';
import CreateAccountSleek from './auth/signup/CreateAccountSleek';
import StyledAppContainer from './cmp/themed/StyledAppContainer';
import ReactGaIntegration from './cmp/ReactGAIntegration';
import {getLocale, PrimeIntl} from '../aff-client-commons/translations/localehandler';
import AutoLogin from "./pages/AutoLogin";
import AccountPendingBlock from "./auth/AccountPendingBlock";
import AccountBlockedBlock from "./auth/AccountBlockedBlock";
import LazyFacebookChat from "./cmp/chat/LazyFacebookChat";
import ShopifyLoginIndex from "./pages/shopify-login/ShopifyLoginIndex";
import LazyContractPage from "./pages/taxforms/contract/LazyContractPage";
import PasswordExpiredBlock from "./pages/PasswordExpiredBlock";
import UnsafeJsRender from "./pages/cmp/UnsafeJSRender";
import SubscribePayment from "./pages/payment-plans/SubscribePayment";
import CustomPage from "./pages/CustomPage";
import CouponPage from "./pages/referral-candy-coupon-page/CouponPage";
import StripeOnCallback from "./pages/payment-plans/stripe/StripeOnCallback";
import PaypalOnCallback from "./pages/payment-plans/paypal/PaypalOnCallback";
import QRCodePage from "./pages/qr-code-page/QRCodePage";
import Signup from "./Signup";
import EmptyPage from './EmptyPage';

function getSignupComponent(signupData) {
    const {type} = signupData;
    switch (type) {
        case 'classic':
            return CreateAccountClassic;
        case 'modern':
            return CreateAccountModern;
        case 'sleek':
            return CreateAccountSleek;
        default:
            return CreateAccountSleek;
    }
}

function App(props){
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [messages, setMessages] = useState({})
    const {pending_partner_dashboard_disabled, mergeSettings, account_status, password_expired,
        gtag_containerid,
        snapchat_pixel_id,
        admin_fb_pixel_id,
        aff_custom_css,
        aff_custom_js,
        fb_chat_enabled, fb_page_id,
        locale, is_app_installed, isEmbedded,
        google_analytics,
        email, signupData,
        noIndex,
    } = props

    function loadInitialData(){
        const obj = getCurrentQueryObject();
        const {ref, aff} = obj;
        const {utm_source, source, lang} = obj;

        if (ref || aff) {
            localStorage.setItem('ref', ref || aff);
        }
        if (utm_source || source) {
            localStorage.setItem('utm_source', utm_source || source);
        }
        if (lang) {
            localStorage.setItem('locale', lang);
        }
        setLoading(true);
        get('/', {ref: ref || aff}).then(async (data) => {
            const {settings} = data;
            if (!settings) {
                //portal does not exist;
                setError({error: 'Affiliate portal does not exists. Kindly contact the store owner'})
            }
            /*
            if (!isDev && settings.partner_portal_subdomain.toUpperCase() !== new URL(window.location.href).hostname.toUpperCase()) {
                const u = new URL(window.location.href);
                u.hostname = settings.partner_portal_subdomain;
                window.location = u.href;
            }
             */

            InjectFavicon(settings.favicon || settings.store_logo);
            try {
                settings.locale = getLocale(settings.locale, settings.localeAuto);
                settings.isRtl = ['ar', 'iw'].indexOf(settings.locale) > -1
                settings.mLocaleMap = settings.mLocaleMap ? JSON.parse(settings.mLocaleMap) : {};
            } catch (e) {
            }
            console.log(settings.localeUri, settings.locale)
            const messages = await PrimeIntl(settings.locale, settings.localeUri, settings.mLocaleMap);
            settings.locale_messages = messages[settings.locale];
            console.log(messages[settings.locale].referralLinkDesc)
            settings.page_sections = buildPageSections(settings);
            //TODO update utm parameters (referral_link_parameters) and replace any {fname} with affiliate's data
            if (settings.referral_link_params) {
                settings.referral_link_params = replaceParams(settings.referral_link_params, settings);
            }
            mergeSettings(settings);
            return messages;
        }).then((msgs) => {
            setMessages(msgs)
            setLoading(false)
        }).catch((e) => {
            switch (e.status) {
                case 422:
                   setError(<div className={"card shadow-sm"}><div className="card-body">
                            This affiliate portal is currently not attached.<br/><br/>If you are the admin, please set the same URL in the <strong>Look & Feel</strong> page -> <strong>Affiliate portal URL</strong> section to activate this URL</div>
                        </div>);
                    break;
                default:
                    setError('Error loading partner portal');
            }
            setLoading(false)
        });
    }

    useEffect(()=>{
        loadInitialData();
    },[])
    if (loading) {
        return (<div className={'d-flex min-vh-100 justify-content-center align-items-center'}>
            <div className="spinner-border text-secondary" role="status" style={{width: '4rem', height: '4rem'}}/>
        </div>);
    }
    if (error) {
        return (<div className={'container d-flex min-vh-100 justify-content-center align-items-center'}>
                <div className="row justify-content-center">
                    <div className="col-6 text-center">
                        <p className={"text-danger"}>{error}</p>
                        <button className={'btn btn-link ml-2'} onClick={()=>window.reload()}>Reload page</button>
                    </div>
                </div>
            </div>
        );
    }
    if(is_app_installed !== undefined && !is_app_installed){
        return <div className={'d-flex min-vh-100 justify-content-center align-items-center'}>
            <div>
                <h1>Affiliate program is currently disabled</h1>
                <a href={"https://goaffpro.com"}>Goaffpro</a>
            </div>
        </div>
    }
    const l = locale && locale.startsWith("zh") ? locale.split("_").join("-") : locale;
    let Cmp = Dashboard;
    if (pending_partner_dashboard_disabled) {
        if (account_status === "pending") {
            Cmp = AccountPendingBlock;
        }
        if (account_status === "blocked") {
            Cmp = AccountBlockedBlock;
        }
    }
    if (password_expired) {
        Cmp = PasswordExpiredBlock
    }
    if (account_status === "payment_pending") {
        Cmp = SubscribePayment
    }

    return (
        <IntlProvider locale={l || 'en'} onError={(e) => null} messages={messages[locale]}>
            <StyledAppContainer>
                <div className={"py-2 text-white text-center w-100"} style={{backgroundColor: '#000'}}>
                    Glowb affiliate program will be closing on <span className={"text-primary"}>April 15th 6am UK.</span>
                    <br />
                    All affiliate commissions will be paid out the following Monday. Please contact <a href={'mailto:help@glowb.shop'}>help@glowb.shop</a> if you have any questions.
                </div>
                <InjectGoogleTagManager gtag_containerid={gtag_containerid}/>
                <InjectSnapPixel snapchat_pixel_id={snapchat_pixel_id} />
                <FbPixel admin_fb_pixel_id={admin_fb_pixel_id}/>
                {
                    isEmbedded && <InjectContentResizer/>
                }
                <InjectNoIndex noIndex={noIndex} />
                <InjectCharset locale={locale}/>
                <InjectCustomCSS css={aff_custom_css} />
                <UnsafeJsRender script={aff_custom_js}/>
                {
                    fb_chat_enabled && fb_page_id &&
                    <LazyFacebookChat fb_page_id={fb_page_id}/>
                }
                <Switch>
                    <Route path={"/qr"} component={QRCodePage} />
                    <Route path={'/logout'} component={LogoutPage}/>
                    <Route path={"/discount/:coupon"} component={CouponPage}/>
                    <Route path={'/login'} component={isEmbedded && email ? Dashboard : LoginPage}/>
                    <Route path={'/login-as/:access_token'} component={AutoLogin}/>
                    <Route path={'/verifyemail/:hash'} component={VerifyEmail}/>
                    <Route path={'/create-account/sleek'} component={CreateAccountSleek}/>
                    <Route path={'/create-account/modern'} component={CreateAccountModern}/>
                    <Route path={'/create-account/classic'} component={CreateAccountClassic}/>
                    {/*Commented the following route to run /signup route for glowb-signup*/}
                    {/*<Route path={'/signup/:handle?'} component={CreateAccountExtraPages}/>*/}
                    <Route path={'/create-account'} component={isEmbedded && email ? Dashboard : getSignupComponent(signupData)}/>
                    <Route path={'/forgot-password'} component={ForgotPassword}/>
                    <Route path={'/reset-password/:resetkey?'} component={ResetPassword}/>
                    <Route path={'/invite/:invitation_key?'} component={InvitationPage}/>
                    <Route path={'/auth/:type'} component={OAuthCallback}/>
                    <Route path={'/program-details'} component={LandingSectionsIndexLazy}/>
                    <Route path={'/program-legal'} component={LandingLegalIndexLazy}/>
                    <Route path={'/contact'} component={email ? ContactUs : LandingContact}/>
                    <Route path={"/document/:contract_id"} component={LazyContractPage}/>
                    <Route path={'/shopify-login/:customer_id'} component={ShopifyLoginIndex}/>
                    <Route path={'/pages/:page_id'} component={email ? Cmp: CustomPage}/>
                    <Route path="/choose_plan/stripe_success" component={StripeOnCallback}/>
                    <Route path="/choose_plan/paypal_success" component={PaypalOnCallback}/>
                    <Route path={"/choose_plan"} component={SubscribePayment} />
                    {/*
                    <Route path={'/signup'} component={Signup}/>
                    */}
                    <Route path={"/signup"} component={CreateAccountSleek} />
                    <Route path={'/'} component={email ? Cmp : LoginPage}/>
                </Switch>
                {
                    google_analytics &&
                    <ReactGaIntegration google_analytics={google_analytics}/>
                }
            </StyledAppContainer>
             </IntlProvider>
    );
}
function InjectNoIndex({noIndex}){
    return noIndex ? <Helmet>
        <meta name="robots" content="noindex" />
    </Helmet> : null
}
function InjectCustomCSS({css}){
    if(!css) return null;
    return <Helmet>
        <style type={"text/css"}>{css}</style>
    </Helmet>
}

function InjectSnapPixel({snapchat_pixel_id}){
    if(!snapchat_pixel_id) return null;
    return <Helmet>
        <script>{`
                (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
            {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
                a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
                r.src=n;var u=t.getElementsByTagName(s)[0];
                u.parentNode.insertBefore(r,u);})(window,document,
                'https://sc-static.net/scevent.min.js');
                snaptr('init', '${snapchat_pixel_id}');
                snaptr('track', 'PAGE_VIEW');
            `}
        </script>

    </Helmet>
}

function InjectGoogleTagManager({gtag_containerid}) {
    if(!gtag_containerid) return null;
    return <><Helmet>
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtag_containerid}');`}
        </script>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${gtag_containerid}`}/>
        <script>
            {`
			window.dataLayer = window.dataLayer || [];
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());
			gtag('config', '${gtag_containerid}');
		`}
        </script>

    </Helmet>
        <noscript>
            <iframe src={`https://www.googletagmanager.com/ns.html?id=${gtag_containerid}`} height="0" width="0" style="display:none;visibility:hidden"/>
        </noscript>

    </>
}
function InjectCharset({locale}){
    return locale === "el" ? <Helmet>
            <meta charSet={"ISO-8859-7"}/>
            <meta httpEquiv={"content-type"} content={'Type=text/html; charset=ISO-8859-7'}/>
        </Helmet>
        : <Helmet>
            <meta charSet={"utf-8"}/>
        </Helmet>
}

function InjectContentResizer() {
    return null;/*
    return (<Helmet script={[
        {
            src: 'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.0.4/iframeResizer.contentWindow.js',
            type: 'text/javascript'
        }
    ]}
    />);
    */
}


function InjectFavicon(store_logo) {
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.rel = 'shortcut icon';
    link.href = store_logo;
    document.getElementsByTagName('head')[0].appendChild(link);
}

function replaceParams(string, object) {
    let str = string;
    Object.keys(object).forEach((key) => {
        str = str.replace("{" + key + "}", object[key])
    })
    return str;
}

const mapStateToProps = (state) => ({
    email: state.settings.email,
    noIndex: state.settings.noIndex,
    aff_custom_js: state.settings.aff_custom_js,
    password_expired: state.settings.password_expired,
    locale: state.settings.locale,
    store_logo: state.settings.store_logo,
    isEmbedded: state.settings.isEmbedded,
    signupData: state.settings.signupData ? JSON.parse(state.settings.signupData) : {},
    aff_custom_css: state.settings.aff_custom_css,
    admin_fb_pixel_id: state.settings.admin_fb_pixel_id,
    pending_partner_dashboard_disabled: state.settings.pending_partner_dashboard_disabled,
    account_status: state.settings.status,
    gtag_containerid: state.settings.gtag_containerid,
    fb_chat_enabled: state.settings.fb_chat_enabled,
    fb_page_id: state.settings.fb_page_id,
    snapchat_pixel_id: state.settings.snapchat_pixel_id,
    is_app_installed:state.settings.is_app_installed,
});

const mapDispatchToProps = (dispatch) => ({
    mergeSettings: (data) => dispatch(actionMergeSetting(data))
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
