import React, {Component} from 'react';
import {googleAuthConfig} from '../../../config';
import {FormattedMessage} from 'react-intl';

class GoogleButton extends Component {

    doSignIn = () => {
        let authorize = googleAuthConfig.oauth_proxy + '?';
        authorize += `callback_uri=${encodeURIComponent(googleAuthConfig.callback_uri)}&`;
        authorize += `proxy_uri=`;
        authorize += encodeURIComponent(`https://accounts.google.com/o/oauth2/v2/auth?response_type=token&client_id=${googleAuthConfig.client_id}&redirect_uri=${googleAuthConfig.oauth_proxy}&scope=profile email`);
        if (window.top !== window.self) {
            window.top.location.href = authorize;
        } else {
            window.location = authorize;
        }
    }

    render() {
        return (
            <div className={'my-2'}>
                <button className={'btn btn-light btn-block'} onClick={this.doSignIn}>
                    <div className={'row no-gutters'}>
                        <div className={'col-auto'}>
                            <img src={"/icons/google.svg"} width={16} height={16}/>
                        </div>
                        <div className={'col'}>
                            <small><FormattedMessage id={'signInGoogle'}/></small>
                        </div>
                    </div>
                </button>
            </div>
        );
    }
}

export default GoogleButton;
