import {getHttpClient} from "../apiClient";


export const loadCommissions = ()=>{
    return (dispatch, getState)=>{
        getHttpClient().get('/commissions')
            .then(({data})=>{
                if(data.commissions){
                    dispatch({
                        type:'set-commissions',
                        commissions: data.commissions[0]
                    })
                }
            })
    }
}
