import Loadable from "react-loadable";
import LazyLoadingBar from "../LazyProgressLoader";
import PropTypes from "prop-types";

const LazyRenderQRCode = Loadable({
    loader: () => import('./RenderQRCode'),
    loading: LazyLoadingBar,
});

LazyRenderQRCode.propTypes = {
    value:PropTypes.string.isRequired,
    size:PropTypes.number,
    level: PropTypes.string
};


export default LazyRenderQRCode
