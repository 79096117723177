import React from 'react';
import PropTypes from 'prop-types';
import RenderFormInput from "./RenderFormInput";

function FormRenderer ({value, formFields, onChange}){

        const onFormInput = (key) => (e) => {
           onChange({...value, [key]:e.target.value});
        }
        return (
            <>
                <div className={'row flex-wrap'}>
                    {
                       formFields
                            .filter(({isEnabled}) => isEnabled)
                            .map((item, index) =>
                                (<RenderFormInput key={item.id + "_" + index} {...item}
                                            value={value[item.id] || ""}
                                            onChange={onFormInput}
                                />))
                    }
                </div>
            </>
        );
}

FormRenderer.propTypes = {
    formFields: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
}


export default FormRenderer
