import Loadable from "react-loadable";
import PropTypes from 'prop-types';
import React from 'react';

const LazyFacebookChat = Loadable({
    loader: () => import('./FacebookChatViaPage'),
    loading: () => <></>
});

LazyFacebookChat.propTypes = {
    fb_page_id: PropTypes.string.isRequired,
};


export default LazyFacebookChat
