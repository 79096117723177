import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {upload} from "../../api";
import StyledProgressLoader from "./themed/StyledProgressLoader";
import {isImage} from '../../aff-client-commons/tools/functions';

const uploadingStates = {
    default: 0,
    uploading: 1,
    uploaded: 2,
    cancelled: 0,
    error: -1,
}


class SimpleFileUpload extends Component {

    state = {
        uploadProgress: 0,
        uploadingState: uploadingStates.default,
    }

    getValue = (key) => {
        return this.state[key] === undefined ? this.props[key] : this.state[key];
    }


    onChange = (e) => {
        var reader = new FileReader();
        reader.onload = (e) => {
            this.setState({imageDataUri: e.target.result});
        };
        reader.readAsDataURL(e.target.files[0]);
        this.setState({...e.target.files[0]})
        const form = new FormData();
        form.append("upload", e.target.files[0]);
        this.setState({uploadingState: uploadingStates.uploading});
        upload(this.props.upload_endpoint, form)
            .then((data) => {
                this.setState({...data, uploadingState: uploadingStates.uploaded});
                if (this.props.onChange) {
                    this.props.onChange(data);
                }
            }).catch((e) => {
            this.setState({uploadingState: uploadingStates.error})
        })
    }

    onDelete = () => {
        if (this.props.onChange) {
            this.props.onChange({});
        }
        this.setState({uploadingState: uploadingStates.default, location: null, width: null, height: null})
    }


    render() {
        const location = this.getValue('location');
        const imgWidth = this.getValue('width');
        const imgHeight = this.getValue('height');
        const {uploadingState, uploadProgress} = this.state;
        if (uploadingState === uploadingStates.uploading) {
            return <RenderUploading/>
        } else if (location) {
            return <RenderUploaded uri={location} onDelete={this.onDelete} {...this.state} imgWidth={imgWidth}
                                   imgHeight={imgHeight}/>
        } else {
            return <input {...this.props.inputProps || {}} type={"file"} className={"form-control-file"}
                          onChange={this.onChange}/>
        }
    }
}

function RenderUploaded({uri, onDelete, imgWidth, imgHeight, originalname}) {
    return <div>
        <a title={"Open in a new tab"} target={"_blank"} href={uri}>
            {
                isImage(uri) ?
                    <img src={uri} height={imgHeight}
                         style={{maxHeight: 200, width: imgHeight !== 'auto' ? 'auto' : imgWidth}}
                         alt={"Uploaded file"} className={"bg-light"}/>
                    : originalname
            }
        </a>
        <br/>
        <button type={"button"} className={"btn btn-link text-danger"} onClick={onDelete}>Delete</button>
    </div>
}

function RenderUploading({imageDataUri, uploadProgress}) {

    return <div style={{width: '200px'}} className={"text-center"}>
        <small className={"d-block text-muted"}>Uploading</small>
        <StyledProgressLoader/>
    </div>

}

SimpleFileUpload.propTypes = {
    onChange: PropTypes.func.isRequired,
    location: PropTypes.string,
    upload_endpoint: PropTypes.string,
    width: PropTypes.any,
    height: PropTypes.any
}
SimpleFileUpload.defaultProps = {
    upload_endpoint: '/files/upload',
    width: 200,
    height: 'auto'
}

export default SimpleFileUpload;
