import React, {Component} from 'react';
import Modal from "../../cmp/Modal";
import SimpleFileUpload from "../../cmp/SimpleFileUpload";
import {post} from "../../../api";
import {FormattedMessage} from "react-intl";
import Label from "../../auth/components/Label";
import TextArea from 'react-textarea-autosize';

class FileUploadModal extends Component {

    state = {
        file_title: '',
        file_description: '',
    }
    onChange = (data) => {
        this.setState(data);
        if (data.originalname) {
            const removeExt = data.originalname.split(".")[0];
            this.setState({file_title: removeExt});
        } else {
            this.setState({file_title: ''});
        }
    }
    onInputChange = key => e => {
        this.setState({[key]: e.target.value});
    }
    submit = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState({confirmLoading: true})
        const {originalname, location, size, width, height, mimetype, file_title, file_description} = this.state;
        const data = {
            file_title: file_title || originalname,
            file_description: file_description,
            filename: originalname,
            url: location,
            filesize: size,
            metadata: JSON.stringify({
                width, height
            }),
            filetype: mimetype,
        }
        post('/files', data)
            .then((data) => {
                this.setState({url: undefined, confirmLoading: false})
                this.props.onNewFile(data);
                this.props.onClose();
            }).catch(() => {
            this.setState({confirmLoading: false})
        })
    }

    render() {
        return (
            <Modal visible={this.props.visible}
                   onOk={this.submit}
                   disabled={!this.state.location}
                   title={<FormattedMessage id={"Add File"}/>}
                   confirmLoading={this.state.confirmLoading}
                   onClose={this.props.onClose}>
                <div className="form-group">
                    <SimpleFileUpload onChange={this.onChange}/>
                </div>
                <div className="form-group">
                    <Label>
                        <FormattedMessage id={"File name"}/>
                    </Label>
                    <input type="text" className="form-control" value={this.state.file_title}
                           onChange={this.onInputChange('file_title')}/>
                </div>
                <div className="form-group">
                    <Label>
                        <FormattedMessage id={"File description"}/>
                    </Label>
                    <TextArea className={"form-control"} value={this.state.file_description}
                              onChange={this.onInputChange('file_description')}/>
                </div>
            </Modal>
        );
    }
}

export default FileUploadModal;
