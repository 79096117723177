import React from 'react';

function SimpleDateInput({placeholder, id, name, value, onChange}) {
    return (<input placeholder={placeholder} type={'date'} id={id} name={name}
                defaultValue={value} onChange={onChange}
                className={'form-control'}
        />);
}

export default SimpleDateInput;
