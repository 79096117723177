import React from 'react';
import InputMask from 'react-input-mask';
function MaskedInput({mask, value, onChange, autoComplete, id, name, type}) {
    if(mask){
        return <InputMask mask={mask} maskChar={"_"}
                          onChange={onChange}
                          type={type} id={id} name={name} value={value}
                          autoComplete={autoComplete}
                          alwaysShowMask={true}
                          className={'form-control'}
               />
    }else{
        return <input type={type} id={id} name={name} value={value} onChange={onChange}
                      autoComplete={autoComplete}
                      className={'form-control'}
        />
    }
}

export default MaskedInput;
