import React, {Component} from 'react';

class TextComponent extends Component {
    render() {
        return (
            <div>
                {
                    this.props.text
                }
            </div>
        );
    }
}

export default TextComponent;
