import React, {Component} from 'react';
import {post} from '../../api';
import StyledButton from '../cmp/themed/StyledButton';
import StyledProgressLoader from '../cmp/themed/StyledProgressLoader';
import {FormattedMessage} from 'react-intl';
import FormattedHelmet from '../cmp/intl/FormattedHelmet';
import RenderError from './components/RenderError';

class VerifyEmail extends Component {

    state = {
        loading: false
    }

    componentDidMount() {
        this.verifyEmail();
    }

    verifyEmail = () => {
        const hash = this.props.match.params.hash;
        this.setState({loading: true, error: false});
        post('/verifyemail', {
            code: hash
        }).then((e) => {
            if (e.success) {
                this.setState({loading: false});
            } else {
                this.setState({errorCode: true, loading: false});
            }
        }).catch((e) => {
            this.setState({loading: false});
        });
    }

    toDashboard = () => {
        if(localStorage.getItem('access_token')){
            window.location = '/';
        }else{
            window.location = "/login"
        }
    }


    render() {

        const {loading, errorCode} = this.state;

        const cmp = errorCode ? (<div>
                <RenderError code={errorCode}/>
                <button className={'btn btn-outline-primary'} onClick={this.verifyEmail}>
                    <FormattedMessage id={'tryAgain'}/>
                </button>
            </div>) :
            (<div>
                <FormattedMessage tagName={'h1'} id={'emailVerified'}/>
                <FormattedMessage tagName={'p'} id={'emailVerifiedThanks'}/>
                <StyledButton className={'btn btn-primary'} onClick={this.toDashboard}>
                    <FormattedMessage id={'returnToDashboard'}/></StyledButton>
            </div>);

        return (
            <div className={'bg-light'}>
                <FormattedHelmet id={'verifyingEmail'}/>
                <div className={'container-fluid'}>
                    <div className={'row justify-content-center align-items-center  min-vh-100'}>
                        <div className={'card col-md-4 shadow-md'}>
                            <div className={'card-body'}>
                                {
                                    loading ? <div className={'my-4 text-center'}>
                                        <FormattedMessage tagName={'h1'} id={'verifyingEmail'}/>
                                        <StyledProgressLoader/>
                                    </div> : cmp
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VerifyEmail;
