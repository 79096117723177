import {cx} from 'emotion';
import {connect} from 'react-redux';
import React from 'react';
import Button from "../../auth/components/Button";
//export default style;
function mapStateToProps(state) {
    const {themePrimaryColor, themeFontFamily, themeFontCss} = state.settings;
    return {
        themeprimarycolor: themePrimaryColor,
        themefontfamily: themeFontFamily,
        themefontcss: themeFontCss
    };
}

const componentsMap = {
    'Button': Button
}

const Element = connect(mapStateToProps)((props) => {
    const Tag = props.tag;
    const className = cx(props.className, props.cssfunc(props));
    const otherProps = {...props, className};
    delete otherProps.cssfunc;
    delete otherProps.dispatch;
    return React.createElement(componentsMap[Tag] || Tag, otherProps)
     return <Tag {...otherProps} className={className}/>;
});


const Exp = (tag, cssFunc) => (props) => <Element tag={tag} cssfunc={cssFunc} {...props} />;
export default Exp;
