import React, {Component} from 'react';
import Label from "../auth/components/Label";
import {FormattedMessage} from "react-intl";
import Button from "../auth/components/Button";
import {post} from "../../api";

class PasswordExpiredBlock extends Component {
    state = {}
    handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        const {new_password, current_password, new_password_confirm} = this.state;
        this.setState({loading: true, error: false, success: false});
        post('/change_password', {
            new_password,
            new_password_confirm,
            current_password
        }).then((data) => {
            if (data.success) {
                window.location = "/";
            } else {
                this.setState({error: data.error});
            }
            this.setState({loading: false});
        }).catch((e) => {
            this.setState({error: <FormattedMessage id={'Network error'}/>, loading: false});
        });
    }
    onChange = (key) => (e) => {
        this.setState({[key]: e.target.value});
    }

    isEnabled = () => !this.state.loading && this.state.new_password && this.state.new_password_confirm && this.state.new_password === this.state.new_password_confirm

    render() {
        const {new_password, current_password, new_password_confirm} = this.state;
        return (
            <div className={"container mt-4"}>
                <div className="row">
                    <div className="col-md-3 col-lg-3"/>
                    <div className="col-md-6 col-lg-6">
                        <div className="card">
                            <div className="card-body">
                                <h3><FormattedMessage id={'Change Password'} defaultMessage={'Change Password'}/></h3>
                                {
                                    this.state.error && <p className={'alert alert-danger'}>{this.state.error}</p>
                                }
                                {
                                    this.state.success && <p className={'alert alert-success'}>{this.state.success}</p>
                                }
                                <form onSubmit={this.handleSubmit} disabled={!this.isEnabled()}>
                                    <div className="form-group">
                                        <Label><FormattedMessage id={'Current Password'}
                                                                 defaultMessage={'Current Password'}/></Label>
                                        <input type="password" className="form-control" value={current_password}
                                               onInput={this.onChange('current_password')}/>
                                    </div>
                                    <div className="form-group">
                                        <Label><FormattedMessage id={'New Password'}
                                                                 defaultMessage={'New Password'}/></Label>
                                        <input type="password" className="form-control" value={new_password}
                                               onInput={this.onChange('new_password')}/>
                                    </div>
                                    <div className="form-group">
                                        <Label><FormattedMessage id={'Confirm Password'}
                                                                 defaultMessage={'Confirm Password'}/></Label>
                                        <input type="password" className="form-control" value={new_password_confirm}
                                               onInput={this.onChange('new_password_confirm')}/>
                                        {
                                            new_password && new_password_confirm && new_password_confirm !== new_password &&
                                            <span className="text-danger"><FormattedMessage
                                                id={"Passwords do not match"}/></span>
                                        }
                                    </div>
                                    <div className="d-flex">

                                        <Button loading={this.state.loading} className="btn btn-primary"
                                                disabled={!this.isEnabled()} onClick={this.handleSubmit}>
                                            <FormattedMessage id={'Submit'}/>
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PasswordExpiredBlock;
