import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {del, get} from "../../../api";
import {TrashIcon} from '../../../icons/Icons'
import FileUploadModal from "./FileUploadModal";
import Button from "../../auth/components/Button";
import {connect} from 'react-redux'
class FileUploadMarketingPage extends Component {
    state = {
        files: [],
        loading: false,
        deletingIndex: [],
    }

    componentDidMount() {
        this.loadFiles();
    }


    deleteFile = (fileId) => () => {
        if (window.confirm('Confirm ?')) {
            const deleting = (isDeleting) => {
                if (isDeleting) {
                    this.setState({deletingIndex: [...this.state.deletingIndex, fileId]});
                } else {
                    this.setState({
                        deletingIndex: this.state.deletingIndex.filter((item) => item !== fileId)
                    })
                }
            }
            deleting(true);
            del('/files/' + fileId).then((data) => {
                deleting(false)
                this.setState({files: this.state.files.filter((item) => item.file_id !== fileId)})
            }).catch((e) => {
                deleting(false)
            })
        }
    }

    loadFiles = () => {
        this.setState({loading: true})
        get('/files').then((data) => {
            this.setState({files: data, loading: false})
        }).catch((e) => {
            this.setState({loading: false})
        })
    }
    onNewFile = (data) => {
        this.setState({files: [data, ...this.state.files]})
    }

    toggleFileUploadModal = () => {
        this.setState({fileUploadModal: !this.state.fileUploadModal})
    }

    render() {

        if (this.state.loading) {
            return null;
        }
        return <>
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h3><FormattedMessage id={'My Files'}/></h3>
                <button className={"px-0 btn btn-link"} onClick={this.toggleFileUploadModal}>
                    <FormattedMessage id={"Add File"}/>
                </button>
            </div>
            {
                this.state.files && this.state.files.length > 0 ?
                    <ul className="list-group mb-4">{
                        this.state.files.map((item) => {
                            return <li className="list-group-item d-flex justify-content-between align-items-center">
                                <div>
                                    <a href={item.url} target={"_blank"}>
                                        {item.file_title}
                                    </a>
                                    {
                                        item.file_description &&
                                        <div>
                                            <small style={{whiteSpace: 'pre-line'}}>{item.file_description}</small>
                                        </div>
                                    }

                                </div>
                                {
                                    item.can_delete === 1 &&
                                    <button className={"py-0 btn text-danger btn-link"}
                                            onClick={this.deleteFile(item.file_id)}>
                                        <TrashIcon/></button>
                                }
                            </li>
                        })
                    }
                    </ul> : <p className={"text-center"}>
                        <Button className={"btn btn-primary"} onClick={this.toggleFileUploadModal}>
                            <FormattedMessage id={"Add File"}/>
                        </Button>
                    </p>
            }
            <FileUploadModal onNewFile={this.onNewFile} visible={this.state.fileUploadModal}
                             onClose={this.toggleFileUploadModal}/>

        </>
    }
}

function mapStateToProps(state){
    const {aff_file_upload} = state.settings
    return {
        aff_file_upload
    }
}

export default connect(mapStateToProps)(FileUploadMarketingPage);
