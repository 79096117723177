import React, {Component} from 'react';
import {connect} from 'react-redux';
import StyledHeaderSvg from '../../../cmp/themed/StyledHeaderSvg';
import HeroBanner from "./HeroBanner";
import HeroContent from "./HeroContent";

class HeroComponent extends Component {

    render() {
        const {hero_banner, hero_use_bg} = this.props;
        return (
            <div className={"bg-light"}>
                {
                    hero_banner && hero_use_bg ? <HeroBanner {...this.props} /> :
                        <div className={'container mx-auto'}>
                            <div className={'row'} style={{paddingTop: '3rem', paddingBottom: '3rem'}}>
                                <div className={'col-md-6'}>
                                    <HeroContent {...this.props} />
                                </div>
                                <div className={'col-md-6 d-none d-md-block text-right'}>
                                    {
                                        hero_banner ? <img alt={""} style={{height:300}} src={hero_banner}/>
                                        : <StyledHeaderSvg height={300}/>
                                    }
                                </div>
                            </div>
                        </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    const {default_commission_type, default_commission_value, page_sections} = state.settings;
    const {hero_use_bg, hero_banner, hero_supertext, hero_subtext, hero_text, hero_btntext, hero_align, hero_textcolor} = (page_sections.default_banner && page_sections.default_banner.props) || {};

    const show_commission = default_commission_value > 0;
    return {
        hero_banner,
        hero_use_bg: hero_use_bg === undefined ? true : hero_use_bg,
        hero_supertext: hero_supertext !== undefined ? hero_supertext : 'heroline',
        hero_subtext: hero_subtext !== undefined ? hero_subtext : 'herosubtext',
        hero_text: hero_text !== undefined ? hero_text : show_commission ? 'heroDescHasCommission' : 'heroDescNoCommission',
        hero_btntext: hero_btntext !== undefined ? hero_btntext : 'joinNow',
        hero_textcolor: hero_textcolor,
        hero_align: hero_align || 'left',
        default_commission_value,
        default_commission_type,
    };
}

export default connect(
    mapStateToProps
)(HeroComponent);
