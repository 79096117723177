import './styles/helpers.scss';
import './styles/rtl.css';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './components/App';
import {Route, Router, Switch} from 'react-router-dom';
import store from './redux/store';
import {connect, Provider} from 'react-redux';
import {history} from './config';
import EmptyPage from "./components/EmptyPage";

const IntlApp = connect(mapStateToProps)(({ locale }) => {
	return (
		<Router history={history}>
			<Switch>
				<Route path={"/empty"} component={EmptyPage} />
				<Route path={"/"} component={App} />
			</Switch>
		</Router>);
});


function doRender() {
	ReactDOM.render(
		// <ErrorBoundary>
			<Provider store={store}>
				<IntlApp/>
			</Provider>
		// </ErrorBoundary>
		, document.getElementById('root'));
}
require('intl');
/*
if (!global.Intl) {
	import('intl')
		.then((d)=>{
			console.log('int loaded', d);
			//return import('intl/locale-data/jsonp/en.js')
		}).then(doRender)
} else {
	doRender()
}
 */
doRender();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

window.addEventListener("message", (event) => {
	if (event.data){
		console.log(event.data)
//		store.dispatch(event.data)
	}
}, false);
function mapStateToProps(state){
	return {
		locale: state.settings.locale
	};
}
