import React, {Component} from 'react';
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import {connect} from "react-redux";
import {langsMap} from "../../aff-client-commons/translations/localehandler";

class LangsSelector extends Component {
    state = {
        locale: undefined
    }

    getValue = () => {
        return this.state.locale === undefined ? this.props.locale : this.state.locale;
    }

    select = (key) => () => {
        this.setState({locale: key})
        localStorage.setItem('locale', key);
        document.location.reload();
    }

    render() {
        const locale = this.getValue();
        const items = Object.keys(langsMap).filter((lang) => lang !== locale).map((key) => {
            return <DropdownItem key={key} onClick={this.select(key)}>{langsMap[key]}</DropdownItem>
        })
        //TODO add svg flags and language code here
        return (<>
                <UncontrolledDropdown direction={"up"}>
                    <DropdownToggle className={"text-secondary p-0"} color={"transparent"} caret>
                        {
                            <small>{langsMap[locale]}</small>
                        }
                    </DropdownToggle>
                    <DropdownMenu>
                        {items}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        locale: localStorage.getItem("locale") || state.settings.locale
    }
}


export default connect(mapStateToProps)(LangsSelector);
