import React, {useState, useEffect} from "react";
import useInputState from "../../cmp/hooks/useInputState";
import {get} from "../../../api";
import fecha from "fecha";
import CurrencyNumber from "../../cmp/CurrencyNumber";
import Modal from "../../cmp/Modal";
import BootStrapPagedTable from "../../cmp/BootStrapPagedTable";
import Pagination from "../../cmp/StateBasedPagination";
import Skeleton from 'react-loading-skeleton';
import {FormattedMessage} from 'react-intl'
export default function TransactionsIndex() {
    const [transactions, setTransactions] = useState([])
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0)
    const [forceReload, setForceReload] = useState(1)
    const [limit, setLimit] = useInputState(10);
    const [page, setPage] = useState(0)
    const [dates, setDates] = useState([])
    const [entityId, setEntityId] = useState(null)

    useEffect(()=>{
            setLoading(true)
            get('/transactions',{
                    limit,
                    offset: page * limit,
            })
            .then((data)=>{
                setTransactions(data.transactions);
                setCount(data.count)
                setLoading(false)
            })
    }, [forceReload, page, limit])

    useEffect(()=>{
        if(page > 0){
            setPage(0);
        }else{
            setForceReload(forceReload+1)
        }
    },[dates])

    const columns = [
        {
            title:'Date',
            dataIndex:'created_at',
            render:(date, record)=>{
                return <span>{fecha.format(new Date(date), "DD-MMM-YYYY")}<br/><small>{fecha.format(new Date(date),'hh:mm a')}</small></span>
            },
        },
        {
            title:'Transaction',
            dataIndex:'metadata',
            render:(metadata, record)=>{
                const Cmp = getComponent(record.event_type, record.entity_type)
                if(Cmp){
                    return <Cmp metadata={metadata} onShowEventLog={()=>setEntityId(record.entity_id)} />
                }else{
                    console.log('not found for ', record)
                }
            }
        },
        {
            title:'Amount',
            dataIndex:'amount',
            type:'number',
            render:(amount)=><span className={ amount > 0 ? "text-success" : 'text-danger'}><CurrencyNumber value={amount} /></span>
        },
        {
            title:'Final Balance',
            dataIndex:'endingBalance',
            type:'number',
            render:(endingBalance)=><span className={ endingBalance > 0 ? "" : 'text-danger'}><CurrencyNumber value={endingBalance} /></span>
        }
    ]
    return (
        <>
            <h3><FormattedMessage id={"Transactions"} /></h3>
            <RelatedEntries entity_id={entityId} onClose={()=>setEntityId(null)}/>
            {
                loading ? <Skeleton count={3.4 * limit}/> :
                    <BootStrapPagedTable loading={loading} dataSource={transactions} columns={columns}/>
            }
            {
                <div className={"text-center mb-1"}>
                    <FormattedMessage id={"Page currentPageNumber of totalPages"} defaultMessage={"Page {page, number} of {totalPages, number}"}
                    values={{
                        page:page+1,
                        totalPages: Math.ceil(count/limit)
                    }}/>
                </div>
            }
            {
                count > 0 && <div className={"d-flex justify-content-center"}>
                        <Pagination count={count}
                               limit={limit} page={page}
                               onLimitChange={setLimit}
                               goToPage={setPage}/>
                </div>
            }
        </>
    );
}
function getComponent(eventType, entityType){
    const key = `${entityType}-${eventType}`;
    switch(key){
        case "orders-delete":
            return OrderDeleted;
        case "orders-update":
            return OrderUpdated;
        case "orders-insert":
            return OrderInserted;
        case "payouts-insert":
            return PayoutInserted;
        case "payouts-update":
            return PayoutUpdated;
        case "payouts-delete":
            return PayoutDeleted;
        case "rewards-insert":
            return RewardInserted;
        case "rewards-update":
            return RewardUpdated;
        case "rewards-delete":
            return RewardDeleted;
        default:
            return null;

    }
}
function RenderOrderStatus({status}){
    switch(status){
        case "rejected":
            return <span className="badge badge-sm badge-danger">
                <FormattedMessage id={"Rejected"} />
            </span>
        case "new":
            return <span className="badge badge-sm badge-warning"> <FormattedMessage id={"Pending"} /> </span>
        case "approved":
            return <span className="badge badge-sm badge-success"> <FormattedMessage id={"Approved"} /> </span>
        case "deleted":
            return <span className="badge badge-sm badge-danger"> <FormattedMessage id={"Deleted"} /> </span>
        default:
            return null;

    }
}

function OrderDeleted({metadata}){
    const {order_number, affiliate_commission, status} = metadata;
    const deltas = []
    if(affiliate_commission !== 0 && status === "approved"){
        deltas.push(<div>Δ Commission <CurrencyNumber value={affiliate_commission}/>  → <CurrencyNumber value={0}/></div>)
    }
    deltas.push(<div>Δ Status <RenderOrderStatus status={status}/> → <RenderOrderStatus status={"deleted"} /></div>)

    return <><span className="text-danger">{order_number}<br/></span><small>{deltas}</small></>
}
function OrderInserted({metadata}){
    const {order_number, affiliate_commission, status} = metadata;
    return <span>{order_number} {status !== "approved" ? <RenderOrderStatus status={status}/> : null}</span>
}
function OrderUpdated({metadata, onShowEventLog}){
    const {order_number, affiliate_commission, status} = metadata;
    const {new_status, old_status, new_affiliate_commission, old_affiliate_commission} = metadata;
    const deltas = []
    if(new_affiliate_commission !== old_affiliate_commission){
        deltas.push(<div>Δ Commission <CurrencyNumber value={old_affiliate_commission}/>  → <CurrencyNumber value={new_affiliate_commission}/></div>)
    }
    if(new_status !== old_status){
        deltas.push(<div>Δ Status <RenderOrderStatus status={old_status}/> → <RenderOrderStatus status={new_status} /></div>)
    }else{
        deltas.push(<div>Status: <RenderOrderStatus status={new_status}/></div>)
    }

    return <><button className="px-0 py-0 btn text-warning" onClick={onShowEventLog}>{order_number}</button>
        <small><br/>
            {deltas}
        </small>
    </>

}

function PayoutDeleted({metadata}){
    const {payment_method} = metadata;
    return <span className="text-danger">Payout Deleted {payment_method}</span>
}
function PayoutInserted({metadata}){
    const {payment_method} = metadata || {};
    return <span>Payout {payment_method}</span>
}
function PayoutUpdated(){
    return null
}
function RewardDeleted({metadata}){
    const {order_number} = metadata
    return <span className={"text-danger"}>Deleted {order_number} (Network)</span>
}
function RewardInserted({metadata}){
    const {reward_data, status, reward_type, created, order_number, commission_on} = metadata;
    switch(reward_type){
        case "wallet_adjustment":
            return <span><FormattedMessage id={"Wallet adjustment"}/><br/><div className={"text-muted"}>{reward_data}</div>
            <small><FormattedMessage id={"Date"}/>: {fecha.format(new Date(created),'MMM D')}</small></span>
        case "sale_commission":
            return <span><FormattedMessage id={"Network commission"}/><br/>
                <small>{order_number} = <CurrencyNumber value={commission_on}/>&nbsp;
                    {status !== "approved" ? <RenderOrderStatus status={status}/> : null}
                </small>
            </span>
        case "signup_bonus":
            return <span><FormattedMessage id={"Signup Bonus"}/></span>
        case "recruitment_bonus":
            return <span><FormattedMessage id={"Recruitment Bonus"}/></span>
        case "target_bonus":
            return <span><FormattedMessage id={"Target Bonus"}/></span>
        default:
            return reward_type;
    }

}

function RewardUpdated({metadata, onShowEventLog}){
    const {order_number, affiliate_commission, status} = metadata;
    const {new_status, old_status, new_affiliate_commission, old_affiliate_commission} = metadata;
    const deltas = []
    if(new_affiliate_commission !== old_affiliate_commission){
        deltas.push(<div>Δ Commission <CurrencyNumber value={old_affiliate_commission}/>  → <CurrencyNumber value={new_affiliate_commission}/></div>)
    }
    if(new_status !== old_status){
        deltas.push(<div>Δ Status <RenderOrderStatus status={old_status}/> → <RenderOrderStatus status={new_status} /></div>)
    }else{
        deltas.push(<div>Status: <RenderOrderStatus status={new_status}/></div>)
    }

    return <><button className="btn px-0 text-warning" onClick={onShowEventLog}><FormattedMessage id={"Network Commission"}/> {order_number}</button>
        <small><br/>
            {deltas}
        </small>
    </>
}

function RelatedEntries({affiliate_id, entity_id, onClose}){
    const [transactions, setTransactions] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(()=>{
        if(entity_id) {
            setLoading(true);
            get("/transactions/" + entity_id)
                .then((data) => {
                    setLoading(false);
                    setTransactions(data.transactions.sort((a, b)=>new Date(a.created_at).getTime() - new Date(b.created_at).getTime()))
                })
        }
    },[entity_id])
    const columns = [
        {
            title:'Date',
            dataIndex:'created_at',
            render:(date, record)=>{
                return <span>{fecha.format(new Date(date), "DD-MMM-YYYY")}<br/><small>{fecha.format(new Date(date), 'hh:mm a')}</small></span>
            },
        },
        {
            title:'Transaction',
            dataIndex:'metadata',
            render:(metadata, record)=>{
                const Cmp = getComponent(record.event_type, record.entity_type)
                if(Cmp){
                    return <Cmp metadata={metadata} />
                }else{
                    console.log('not found for ', record)
                }
            }
        },
        {
            title:'Amount',
            dataIndex:'amount',
            render:(amount)=><span className={ amount > 0 ? "text-success" : 'text-danger'}><CurrencyNumber value={amount} /></span>
        },
        {
            title:'Final Balance',
            dataIndex:'endingBalance',
            render:(endingBalance)=><span className={ endingBalance > 0 ? "" : 'text-danger'}><CurrencyNumber value={endingBalance} /></span>
        }
    ]

    return <Modal size={"lg"} visible={entity_id} onClose={onClose} title={"Event log"}>
        <BootStrapPagedTable loading={loading} dataSource={transactions} columns={columns}/>
    </Modal>
}

