import React, { useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {buildRefLink,} from '../../../aff-client-commons/tools/functions';
import ShareBar from "../../cmp/ShareBar";
import ShopifyProductSearchAutoComplete from "./ShopifyProductSearchAutoComplete";
import Button from "../../auth/components/Button";
import {useDispatch} from 'react-redux'
import {saveLink} from "../../../redux/linkbox/linksAction";
function RefLinkMaker({ref_code, ref_identifiers,referral_link_params, settings, integration, website}){
    const [link, setLink] = useState('');
    const [reflink, setRefLink] = useState('');
    const [saving, setSaving] = useState(false);
    const [options, setOptions] = useState([])
    const dispatch = useDispatch()
    function save(e){
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        setSaving(true);
        dispatch(saveLink(reflink, 'manual')).then(()=>{
            setSaving(false)
        })
    }
    function onChange(e){
        if (e && e.preventDefault && e.stopPropagation) {
            e.preventDefault();
            e.stopPropagation();
        }
        setLink(e.target.value)
    };
    useEffect(()=>{
        try {
            setRefLink(buildRefLink(ref_code, link, ref_identifiers,  referral_link_params, settings))
        } catch (e) {
            setRefLink('')
        }
    },[link]);

    const w = website.startsWith('http') ? website : `https://${website}`
    return (
        <form className={'my-4 row align-items-start'} onSubmit={onChange}>
            <div className={'col-md-6'}>
                <label className={'text-muted'}>
                    <small>
                        <FormattedMessage id={'productPageLinkText'}/>
                    </small>
                </label>
                {
                    integration === "shopify" ?
                        <ShopifyProductSearchAutoComplete onURLChange={onChange}/> :
                        <input className={'form-control'} placeholder={`${w}/product-1`} value={link} onChange={onChange}/>
                }
            </div>
            <div className={'col-md-6'}>
                <label className={'text-muted'}>
                    <FormattedMessage tagName={"small"} id={'generatedAffiliateLinkText'}/>
                </label>
                <input readOnly value={reflink} className={'form-control'} placeholder={''}/>
                <div className="d-flex justify-content-between">
                    <ShareBar link={reflink}/>
                    {
                        reflink &&
                        <Button type={"button"} loading={saving} onClick={save} className={'btn btn-link'} title={"Save"}/>
                    }
                </div>
            </div>
        </form>
    );
}
const mapStateToProps = (state) => ({
    ref_code: state.settings.ref_code,
    website: state.settings.website,
    ref_identifiers: state.settings.ref_identifiers,
    referral_link_params: state.settings.referral_link_params,
    website_match: state.settings.website_match,
    integration: state.settings.integration,
    settings: state.settings
});

export default connect(mapStateToProps)(RefLinkMaker);
