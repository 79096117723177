import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import moment from '../../../mydayjs';

function CurrentLeaderboard(){
  const [data, setData] = useState(null)
  const affiliate_id = useSelector((state)=>state.settings.affiliate_id)
  useEffect(()=>{
    const key = moment().format('MMMM[_]YYYY')
    fetch('https://j0umgiq21b.execute-api.eu-central-1.amazonaws.com/default/glowb-leaderboard?affiliate_id='+affiliate_id+'&key='+key)
        .then((data)=>data.json())
        .then((d)=>{
          console.log('leaderboard got data', d)
          setData(d)
    })
  },[affiliate_id])
  console.log('leaderboard', {data})
  if(!data) return null;
  const {leaderboard, myRank} = data;
  return <div className={"card border-0"}>
    {/*Removed your rank section*/}
    {/*<div className="card-body">*/}
    {/*  <div className="display-4">{myRank}</div>*/}
    {/*  <div className={'text-muted'}>Your rank</div>*/}
    {/*</div>*/}
    {
      leaderboard && leaderboard.length > 0 ?
          <ul className="list-group list-group-flush">
            {
              leaderboard.map((item, index) => {
                return <li key={String(index)}
                           className="list-group-item d-flex">
                  <span style={{width: 50}}>#{index + 1}</span>
                  <span>{item.name}</span>
                </li>
              })
            }
          </ul> : <div className={"card-body"}>
              No Data
          </div>
    }
  </div>
}

export default CurrentLeaderboard;
