import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import moment from '../../../mydayjs'
import CurrencyNumber from '../../cmp/CurrencyNumber';
const bonuses = [0, 2500, 5000, 10000, 20000, 40000, 80000]
function CurrentBonusProgressBar(){
  const affiliate_id = useSelector((state)=>state.settings.affiliate_id)
  const [data, setData] = useState(null)

  useEffect(()=>{
    if(affiliate_id){
      const key = moment().format('MMMM_YYYY')
      fetch('https://custom-api.goaffpro.com/glowb/comp-plan-data?affiliate_id='+affiliate_id+'&key='+key)
      .then((data)=>data.json())
      .then((data)=>{
        const {totalBonusVolume, volume} = data;
        return setData(totalBonusVolume|| 0)
      })
    }
  },[affiliate_id])
  console.log({data})
  return <BonusProgressBar myVolume={data} />
}

function BonusProgressBar({myVolume}){
  const {percent, startVolume, endVolume} =  useMemo(()=>{
    const nextIndex = bonuses.findIndex((item)=>item > myVolume);
    let startVolume = 80000;
    let endVolume = '-';
    let percent = 100;
    if(nextIndex > -1) {
      endVolume = bonuses[nextIndex];
      startVolume = bonuses[nextIndex - 1]
      percent = ((myVolume - startVolume)/(endVolume - startVolume)) * 100
    }
    return {
      startVolume,
      endVolume,
      percent
    }

  },[myVolume])
  return <div>
    <div className="progress" style={{height:36, borderRadius:18}}>
      <div className="progress-bar" style={{width:percent+"%", fontSize:16, backgroundColor:"#FE91B0"}} role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
        {<CurrencyNumber value={myVolume} />}</div>
    </div>
    <div className="d-flex align-items-center justify-content-between">
      <span>{<CurrencyNumber value={startVolume} />}</span>
      <span>{<CurrencyNumber value={endVolume} />}</span>
    </div>
  </div>

}

export default CurrentBonusProgressBar;
