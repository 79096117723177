import React, {Component} from 'react';
import * as localStorage from "../../../tools/storage";
import {get} from "../../../api";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";

class SponsorName extends Component {
    state = {}

    componentDidMount() {
        this.getReferredBy();
    }

    getReferredBy = () => {
        const ref = localStorage.getItem('ref');
        if (ref) {
            get('/who_is_the_referrer', {ref}).then(({name}) => {
                this.setState({referred_by: name});
            }).catch((e) => {

            });
        }
    }

    render() {
        if (this.state.referred_by && this.props.mlm_show_referred_by) {
            return <div className={'text-muted ml-4'}>
                <FormattedMessage id="Referred by"/>
                <div>{this.state.referred_by}</div>
            </div>
        } else {
            return null;
        }

    }
}


function mapStateToProps(state) {
    return {
        mlm_show_referred_by: state.settings.mlm_show_referred_by
    };
}

export default connect(mapStateToProps)(SponsorName);
