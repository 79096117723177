import React, {Component, useState} from 'react';
import {connect} from 'react-redux';
import StyledButton from '../../cmp/themed/StyledButton';
import {FormattedMessage} from 'react-intl';
import OAuthSignIn from '../OAuthSignIn';
import RenderError from '../components/RenderError';
import * as localStorage from '../../../tools/storage';
import {post} from '../../../api';
import CountrySelector from '../components/CountrySelector';
import Label from '../components/Label';
import RadioGroup from '../../cmp/form/RadioGroup';
import Checkbox from '../../cmp/form/Checkbox';
import SponsorName from "../components/SponsorName";
import LegalDocModal from "./LegalDocModal";
import LazyMultipleSelector from "../../cmp/multiple-selector/LazyMultipleSelector";
import SimpleFileUpload from "../../cmp/SimpleFileUpload";
import RecaptchaRenderer from "../../cmp/RecaptchaRenderer";
import SignatureInput from "../../cmp/form/SignatureInput";
import LazyPhoneInput from "../../cmp/form/phone/LazyPhoneInput";
import LazySponsorChooser from "../../cmp/form/sponsor-chooser/LazySponsorChooser";
import MaskedInput from "../../cmp/form/MaskedInput";
import moment from "moment";
import SimpleDateInput from "../../SimpleDateInput";
class AccountCreationForm extends Component {

    state = {

    }

    onChange = (key) => (e) => {
        console.log(key, e.target.value);
        this.setState({[key]: e.target.value});
    }


    handleFormSubmit = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (!this.shouldEnable()) {
            return;
        }
        if(this.state.password.length < 5){
            return this.setState({code:'Password should be at least 5 characters long'})
        }
        if (this.props.gtag_signup_conversion_id) {
            try {
                window.gtag('event', 'conversion', {
                    'send_to': this.props.gtag_signup_conversion_id,
                });
            } catch (e) {
            }
        }
        if(this.props.snapchat_pixel_id){
            try{
                window.snaptr('track','SIGN_UP')
            }catch(e){

            }
        }
        if (this.props.fb_signup_conversion_event) {
            try {
                window.fbq('track', this.props.fb_signup_conversion_event);
            } catch (e) {
            }
        }
        const postData = {};
        this.props.signupFields.forEach((item) => {
            if (item.isEnabled && this.state[item.id]) {
                postData[item.id] = this.state[item.id];
            }
        });
        this.setState({loading: true, code: null});
        const ref = this.state.ref || localStorage.getItem('ref');
        const page_handle = this.props.page_handle;
        const utm_source = (localStorage.getItem('utm_source') || page_handle);
        const keys = Object.keys(postData);
        const toPost = {};
        for(let item of keys){
            if(postData[item]) {
                toPost[item] = typeof postData[item] === "string" ? postData[item].trim() :  postData[item]
            }
        }
        post('/register_new', {
            ...toPost,
            page_handle,
            ref,
            utm_source : utm_source ? ["undefined","null"].indexOf(utm_source) > -1 ? undefined : utm_source : undefined,
            recaptcha_response: this.state.recaptcha_response
        }).then((data) => {
            if (data.access_token) {
                localStorage.setItem('access_token', data.access_token);
                window.location = `/${this.props.isEmbedded ? '?embedded=1' : ''}`;
            } else {
                const {code} = data;
                this.setState({code, loading: false});
            }
        }).catch(() => {
            this.setState({loading: false});
        });
    }

    emailValidator = new RegExp(/\S+@\S+\.\S+/);


    shouldEnable = () => {
        const {email, password} = this.state;
        for (let x = 0; x < this.props.signupFields.length; x++) {
            const {id, isRequired, isEnabled} = this.props.signupFields[x];
            const val = typeof this.state[id] === "string" ? this.state[id].trim() : this.state[id]
            if (isEnabled && isRequired && (!val || val.length === 0)) {
                return false;
            }
        }
        return email && password
            && this.emailValidator.test(email)
            && (this.props.recaptcha_enabled ? this.state.recaptcha_response : true)
    }

    render() {
        if (!this.props.allow_partner_registrations) {
            return <p className="alert alert-danger"><FormattedMessage
                id={"We are not accepting registrations at this moment. Kindly check back later"}/></p>;
        }
        return (
            <div>
                {
                    this.state.code && <RenderError code={this.state.code}/>
                }
                <form onSubmit={this.handleFormSubmit}>
                    <div className={'row flex-wrap'}>
                        {
                            this.props.signupFields
                                .filter(({isEnabled}) => isEnabled)
                                .map((item, index) =>
                                    (<FormInput key={item.id} {...item}
                                                value={this.state[item.id] || ""} onChange={this.onChange(item.id)}
                                    />))
                        }
                    </div>
                    {
                        this.props.recaptcha_enabled &&
                        <RecaptchaRenderer
                            onChange={this.onChange('recaptcha_response')}
                        />
                    }
                    <div className="d-flex justify-content-between align-items-center">
                        <div className={'d-flex align-items-center'}>
                            <StyledButton loading={this.state.loading} className={'btn btn-primary'} disabled={!this.shouldEnable()}
                                          onClick={this.handleFormSubmit}>
                                <FormattedMessage id={this.props.signupButtonText}/>
                            </StyledButton>
                            <SponsorName/>
                        </div>
                        <small className={'text-danger'}><FormattedMessage id={'required'}/></small>
                    </div>
                </form>
                <OAuthSignIn/>
            </div>
        );
    }
}

function FormInput({isRequired, label, value, onChange, placeholder, id, prepend, append, type, size, options, info, inputMask}) {
    let inputCmp = null;
    switch (type) {
        case "signature":
            inputCmp = <SignatureInput onChange={onChange}/>
            break;
        case 'textarea':
            inputCmp = (<textarea id={id} name={fieldNameMapper[id] || label}
                                  value={value} onInput={onChange}
                                  className={'form-control'}
            />);
            break;
        case 'country':
            inputCmp = (
                <CountrySelector value={value} onChange={onChange}/>
            );
            break;
        case 'select':
            inputCmp = (
                options &&
                <select className={'form-control'} value={value} id={id} name={label} onChange={onChange}>
                    <option/>
                    {
                        options.split('\n').map((item) => item.trim()).filter((i) => i)
                            .map((item) => <option value={item}>{item}</option>)
                    }
                </select>
            );
            break;
        case "multiple-select":
            const ar = typeof value === "string" && value.length > 0 ? value.split(",") : value;
            const va = ar && ar.map((val) => {
                return {value: val, label: val}
            });
            inputCmp = (
                options &&
                <LazyMultipleSelector options={options.split('\n').map((item) => item.trim()).filter((i) => i)
                    .map((item) => {
                        return {
                            label: item,
                            value: item,
                        }
                    })} value={va}
                                      onSelected={(val) => onChange({target: {value: val ? val.map(({value}) => value) : undefined}})}/>
            )
            break;
        case 'radio':
            inputCmp = (options &&
                <RadioGroup options={options.split('\n').map((item) => item.trim()).filter((i) => i)}
                            onChange={onChange} checked={value} name={label}
                />
            );
            break;
        case 'fileupload':
            let inputProps = {};
            if (id === "profile_photo") {
                inputProps = {
                    accept: 'image/jpg, image/png, image/jpeg'
                }
            }
            inputCmp =
                <SimpleFileUpload inputProps={inputProps} onChange={(data) => onChange({target: {value: data}})}/>
            break
        case 'checkbox':
            return <div className={`form-group col-${12 / (size || 1)}`}>
                <Checkbox label={<>
                    <FormattedMessage id={label} defaultMessage={label} values={
                    {
                        terms: text => <LegalDocModal to={"/program-legal/terms"}>{text}</LegalDocModal>,
                        privacy: text => <LegalDocModal to={"/program-legal/privacy"}>{text}</LegalDocModal>,
                        md: text => <div className={"d-inline"} dangerouslySetInnerHTML={{__html:text}}/>,
                        html:text=><span dangerouslySetInnerHTML={{__html:text}}/>
                    }
                }/> {isRequired && <span className={"text-danger"}>*</span>}</>} checked={value} value={label} onChange={(e) => onChange({target: {value: e.target.checked}})}/>
            </div>;
        case "phone":
            inputCmp = <LazyPhoneInput onChange={onChange} value={value}/>
            break;
        case "sponsor":
            inputCmp = <LazySponsorChooser onChange={onChange} value={value}/>
            break;
        case "date":
            const val = new Date(value).toString() !== "Invalid Date" ? moment(value).format('YYYY-MM-DD') : value
            inputCmp = (<SimpleDateInput placeholder={placeholder} id={id} name={fieldNameMapper[id] || label} value={val} onChange={onChange} />);
            /*
            inputCmp = <LazyDatePicker config={{dateFormat:"d F, Y"}} onChange={(date)=> {
                if(date && date[0]){
                    onChange({target:{value: moment(date[0]).format('YYYY-MM-DD')}})
                }
            }} />

             */
            break;
        case "sponsor-field":
            return null; //TODO
            break;
        default:
            if (id === "phone") {
                inputCmp = <LazyPhoneInput onChange={onChange} value={value}/>
            } else {
                inputCmp = (<MaskedInput type={type || inputTypeMapper[id] || 'text'} id={id} name={fieldNameMapper[id] || label}
                           value={value} onChange={onChange} autoComplete={autofillFieldMapper[id] || 'on'}
                           mask={inputMask}
                    />);
            }

    }
    return (<div className={`form-group col-${12 / (size || 1)}`}>
        <Label required={isRequired}><FormattedMessage id={label} defaultMessage={label}/></Label>
        {
            (prepend || append) ?
                <div className="input-group">
                    {
                        prepend &&
                        <div className="input-group-prepend">
                            <span className="input-group-text">{prepend}</span>
                        </div>
                    }
                    {
                        inputCmp
                    }
                    {
                        append &&
                        <div className="input-group-append">
                            <span className="input-group-text">{append}</span>
                        </div>
                    }
                </div> : inputCmp

        }
        {
            info && <small className="text-muted" dangerouslySetInnerHTML={{__html:info}} />
        }
    </div>);
}

function DatePicker(){
    const [day, setDay] = useState(1);
    const [month, setMonth] = useState(1);
    const [year, setYear] = useState(new Date().getFullYear() - 18);
    return <div>
        <select>

        </select>

    </div>
}

const autofillFieldMapper = {
    name: 'name',
    fname: 'given-name',
    honorific:'honorific-prefix',
    phone: 'tel',
    date_of_birth:'bday',
    gender:'sex',
    address: 'address',
    address_1: 'address-line1',
    address_2: 'address-line2',
    lname: 'family-name',
    email: 'email',
    password: 'new-password',
    state: 'address-level1',
    city: 'address-level2',
    zip: 'postal-code',
    country: 'country'
};


const inputTypeMapper = {
    email: 'email',
    phone: 'tel',
    paypal: 'email',
};
const fieldNameMapper = {
    name: 'name',
    fname: 'fname',
    lname: 'lname',
    phone: 'phone',
    email: 'email',
    address_1: 'address',
    address: 'address',
    city: 'city',
    state: 'state',
    country: 'country',
    zip: 'zip',
};

const defaultFieldsMap = [
    {
        label: 'Name',
        id: 'name',
        isRequired: false,
        isDefault: true,
        isEnabled: true
    },
    {
        label: 'Email',
        id: 'email',
        isRequired: true,
        isDefault: true,
        type: 'input',
        isEnabled: true
    },
    {
        label: 'Password',
        id: 'password',
        isRequired: true,
        isDefault: true,
        type: 'password',
        isEnabled: true
    }
];


function mapStateToProps(state, props) {
    const {isEmbedded, signupFields, signupPageTitle, signupButtonText} = props.page_data ? props.page_data : state.settings;
    return {
        recaptcha_enabled: state.settings.recaptcha_enabled,
        signupFields: signupFields ? JSON.parse(signupFields) : defaultFieldsMap,
        isEmbedded,
        mlm_show_referred_by: state.settings.mlm_show_referred_by,
        signupPageTitle: signupPageTitle || 'Create Account',
        signupButtonText: signupButtonText || 'Create Account',
        allow_partner_registrations: state.settings.allow_partner_registrations !== undefined ? state.settings.allow_partner_registrations : true,
        gtag_signup_conversion_id: state.settings.gtag_signup_conversion_id,
        fb_signup_conversion_event: state.settings.fb_signup_conversion_event || 'AccountCreated',
        snapchat_pixel_id: state.settings.snapchat_pixel_id
    };
}


export default connect(
    mapStateToProps
)(AccountCreationForm);
