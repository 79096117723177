import React from 'react';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import MarkdownLoader from "../pages/landing/MarkdownLoader";

class AccountPendingApproval extends React.Component {

    render() {
        const {pending_partner_message, pending_partner_dashboard_disabled} = this.props;
        if (pending_partner_message === 'accountPendingVerification') {
            return <p className={'alert alert-warning'}>
                <FormattedMessage id={pending_partner_message} defaultMessage={pending_partner_message} values={{
                    updateYourProfile: <Link to={'/settings'} className={'alert-link'}><FormattedMessage
                        id={'updateYourProfile'}/></Link>
                }}/>
            </p>
        } else if (pending_partner_message.endsWith('html')) {
            if (!pending_partner_dashboard_disabled) {
                return <div className={'alert alert-warning'}>
                    <MarkdownLoader source={pending_partner_message}/>
                </div>
            } else {
                return <div className="card account-pending-message">
                    <div className="card-body">
                        <MarkdownLoader source={pending_partner_message}/>
                    </div>
                </div>
            }
        } else {
            return <p className={'alert alert-warning'}>
                <div dangerouslySetInnerHTML={{__html: pending_partner_message}}/>
            </p>
        }
    }
}

function mapStateToProps(state) {
    return {
        pending_partner_dashboard_disabled: state.settings.pending_partner_dashboard_disabled,
        pending_partner_message: state.settings.pending_partner_message || 'accountPendingVerification'
    };
}

export default connect(mapStateToProps)(AccountPendingApproval);


