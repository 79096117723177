import Loadable from "react-loadable";
import LazyLoadingBar from "../LazyProgressLoader";
import PropTypes from "prop-types";

const LazyMultipleSelector = Loadable({
    loader: () => import('./MultipleSelector'),
    loading: LazyLoadingBar,
});

LazyMultipleSelector.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.any.isRequired,
    onSelected: PropTypes.func.isRequired

};


export default LazyMultipleSelector
