import React, { useState } from "react"
import Switch from "react-switch";

const Signup = () => {
    const [email, setEmail] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [isAcceptingPromotionalContent, setIsAcceptingPromotionalContent] = useState(false)
    const [isReferred, setIsReferred] = useState(false)
    const [userName, setUserName] = useState('')
    const [userPassword, setUserPassword] = useState('')
    const [reEnteredUserPassword, setReEnteredUserPassword] = useState('')
    const [termsAndPolicies, setTermsAndPolicies] = useState('')
    const [hasConsented, setHasConsented] = useState(false)
    const [dob, setDob] = useState({
        month: '',
        day: '',
        year: ''
    })
    const [address, setAddress] = useState({
        streetAddress: '',
        apartmentDetails: '',
        city: '',
        country: '',
        state: '',
        postcode: ''
    })

    return <main style={{color: "#ffa3c6"}}>
        <section className={"text-center pb-2"} style={{backgroundColor: "#FEE2ED"}}>
            <img src={'/glowb-images/glowb-signup-logo.png'} alt={"glowb"} className={"w-25"} />
        </section>

        <section className={"text-center p-3"}>
            <h2>Application to become a glowb Ambassador</h2>
            <h6 className={"mt-4"}>We are so excited that you have decided to join us.</h6>
            <h6>Please fill in the required information below as our system walks you through joining the glowb ambassador program.</h6>
            <h6>We are excited to welcome you to the team!</h6>
        </section>

        <div className={"row"}>
            <HeartLogo height={"38vh"} />

            <div className={"col-lg-11"}>
                <FormLayout title={"Personal Information"} className={"pl-lg-0"}>
                    <FormInput label={"First Name"} type={"text"} value={firstName} required={true} onChange={(e) => setFirstName(e.target.value)} />
                    <FormInput label={"Last Name"} type={"text"} value={lastName} required={true} onChange={(e) => setLastName(e.target.value)} />
                    <FormInput label={"Mobile Phone"} type={"number"} value={mobileNumber} required={true} onChange={(e) => setMobileNumber(e.target.value)} />
                    <FormInput label={"Email Address"} type={"email"} value={email} required={true} onChange={(e) => setEmail(e.target.value)} />

                    <label className={"mt-3"}>Date of Birth</label>
                    <div className={"row"}>
                        <FormSelect className={"col-4"} label={"Month"} value={dob.month} options={dobData.month} onChange={(e) => setDob({...dob, month: e.target.value})} />
                        <FormSelect className={"col-4"} label={"Day"} value={dob.day} options={dobData.day} onChange={(e) => setDob({...dob, day: e.target.value})} />
                        <FormSelect className={"col-4"} label={"Year"} value={dob.year} options={dobData.year} onChange={(e) => setDob({...dob, year: e.target.value})} />
                    </div>

                    <FormSwitch checked={isAcceptingPromotionalContent} onChange={(e) => setIsAcceptingPromotionalContent(e)} text={"Yes, I would like to receive future communication regarding promotions, offers and my account details from glowb."} />
                    <FormSwitch checked={isReferred} onChange={(e) => setIsReferred(e)} text={"Have you been referred by someone?"} />
                </FormLayout>
            </div>
        </div>

        <BorderLayer bgColor={"#FBE2ED"} />

        <div className={"row"} style={{backgroundColor: "#FBE2ED"}}>
            <HeartLogo height={"30vh"} bgColor={"#FBE2ED"} />

            <div className={"col-lg-11"}>
                <FormLayout title={"Address Details"} bgColor={"#FBE2ED"} className={"pl-lg-0"}>
                    <FormInput label={"Street Address"} type={"text"} value={address.streetAddress} onChange={(e) => setAddress({...address, streetAddress: e.target.value})} />
                    <FormInput label={"Apartment/Unit"} type={"text"} value={address.apartmentDetails} onChange={(e) => setAddress({...address, apartmentDetails: e.target.value})} />
                    <FormInput label={"City"} type={"text"} value={address.city} onChange={(e) => setAddress({...address, city: e.target.value})} />
                    {/*TODO - Add countries and state*/}
                    <FormSelect label={"Country"} value={address.country} options={dobData.month} onChange={(e) => setAddress({...address, country: e.target.value})} />
                    <FormSelect className={"mt-4"} label={"State"} value={address.state} options={dobData.month} onChange={(e) => setAddress({...address, state: e.target.value})} />
                    <FormInput label={"Postcode"} type={"number"} value={address.postcode} onChange={(e) => setAddress({...address, postcode: e.target.value})} />
                </FormLayout>
            </div>
        </div>

        <div className={"row"} style={{backgroundColor: "#FBE2ED"}}>
            <HeartLogo height={"20vh"} bgColor={"#FBE2ED"} />

            <div className={"col-lg-11"}>
                <FormLayout title={"Account Information"} bgColor={"#FBE2ED"} className={"pl-lg-0"}>
                    <FormInput label={"Choose Your Username"} type={"text"} value={userName} required={true} onChange={(e) => setUserName(e.target.value)} />
                    <p>{`glowb.shop/?ref=${userName ? userName : '{username}'}`}</p>

                    <FormInput label={"Choose Your Password"} type={"password"} value={userPassword} required={true} onChange={(e) => setUserPassword(e.target.value)} />
                    <FormInput label={"Re-enter Your Password"} type={"password"} value={reEnteredUserPassword} required={true} onChange={(e) => setReEnteredUserPassword(e.target.value)} />
                </FormLayout>
            </div>
        </div>

        <BorderLayer bgColor={"#FBE2ED"} />

        {/*TODO - Fix scrollbar color and width*/}
        <FormLayout title={"Ambassador Agreement"}>
            <TermsList title={"glowb Ambassador Terms"} listItems={glowb.ambassadorTerms} />
            <TermsList className={"border-top mt-4"} title={"glowb Ambassador Policies"} listItems={glowb.ambassadorPolicies} />

            <div className={"p-2 mt-4"}>
                <p>Please type ‘ACCEPT’ to confirm that you have read the Ambassador Terms and Ambassador Policies agreements. By registering as a glowb Ambassador, you acknowledge that you agree to be bound to the terms, conditions and policies of the Ambassador Terms and Ambassador Policies agreements.</p>
                <input type={"text"} value={termsAndPolicies} className={"form-control rounded"} style={{borderColor: "#ffa3c6"}} onChange={(e) => setTermsAndPolicies(e.target.value)} />

                <FormSwitch checked={hasConsented} onChange={(e) => setHasConsented(e)} text={"I consent to my personal information being collected, mananged, stored, and analysed as per the glowb Privacy Policy."} />
            </div>
        </FormLayout>

        <div className={"text-center pb-4"}>
            <button className={"btn p-3 px-4 text-white rounded shadow-sm"} type={"submit"} style={{backgroundColor: "#ffa3c6"}}>
                SIGN UP
            </button>
        </div>
    </main>
}

const HeartLogo = ({ height, bgColor }) => {
    return <div className={"col-lg-1 d-none d-lg-block"} style={{backgroundColor: bgColor}}>
        <img src={'/glowb-images/glowb-signup-heart.png'} alt={"glowb"} className={"w-50 ml-4 mt-5"} />
        <div className={"d-lg"} style={{borderLeft: "2px solid pink", height: height, position: "absolute", left: "58%"}} />
    </div>
}

const BorderLayer = ({ className, bgColor }) => <img src={'/glowb-images/glowb-signup-layer.png'} alt={"border"} className={"w-100 " +className} style={{backgroundColor: bgColor}}/>

const TermsList = ({ title, listItems, className }) => {
    return <ul className={"p-4 text-dark " +className} style={{overflowY: "scroll", height: "550px"}}>
        <h5>{title}</h5>
        {listItems.map((item, index) => <li className={"py-2"} style={{listStyleType: "none"}} key={index}>{item}</li>)}
    </ul>
}

const FormLayout = ({ title, className, bgColor = "white", children }) => {
    return <section className={`p-5 pr-lg-5 py-lg-5 ${className}`} style={{backgroundColor: bgColor}}>
        <h4>{title}</h4>
        {children}
    </section>
}

const FormSwitch = ({ checked, onChange, text }) => {
    return <div className={"d-flex align-items-center mt-5"}>
        <Switch checked={checked} onChange={onChange} onColor={"#ffa3c6"} offColor={"#f8ccdf"} uncheckedIcon={false} checkedIcon={false} />
        <span className={"ml-3"}>{text}</span>
    </div>
}

const FormSelect = ({ label, value, options, className, onChange }) => {
    return <div className={"d-flex flex-column " +className}>
        <label>{label}</label>
        <select onChange={onChange} value={value} required={true} className={"border-left-0 border-right-0 border-top-0"} style={{backgroundColor: "inherit", borderColor: "#ffa3c6", outline: "none"}}>
            {options.map((option, index) => <option key={index}>{option}</option>)}
        </select>
    </div>
}

const FormInput = ({ label, type, value, onChange, required = false }) => {
    return <div className={"form-group mt-4 d-flex flex-column"}>
        <label>{label}</label>
        <input style={{backgroundColor: "inherit", borderColor: "#ffa3c6", outline: "none"}} type={type} value={value} required={required} className={"border-left-0 border-right-0 border-top-0"} onChange={onChange} />
    </div>
}

function getValidYears () {
    const minYear = 1921
    const maxYear = new Date().getFullYear() - 18
    const validYears = []

    for (let i = minYear; i <= maxYear; i++)
        validYears.push(i)

    return validYears
}

const dobData = {
    month: [
        "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
    ],
    day: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
    ],
    year: getValidYears()
}

const glowb = {
    ambassadorTerms: [
        "Please ensure that you fully read and understand this Ambassador Terms, Our Policies and Procedures," +
        " Rewards Plan and Social Media Guide.",

        "This agreement is between Bombshells Investments LTD, trading as glowb, formerly trading as Business" +
        " Bombshells, a company incorporated in England and Wales under registered number 12596237, whose registered office is at St Mary's House, Crewe Road, Alsager, Stoke-On-Trent, United Kingdom, ST7 2EW (the Company) and yourself when applying to join as an ambassador of glowb.",

        "1. I confirm that I am 18 or over and legally able to enter into this agreement. That by entering this" +
        " agreement, I am able to make an informed decision as to whether this business is the right choice for me" +
        " and that I am fully able to give my informed consent to enter into this agreement.",

        "2. I agree that as an Independent Ambassador, I am responsible for my own activity and that I am not an" +
        " employee, partner, franchisee, or legal representative of glowb. I understand and agree that I will not" +
        " receive any employee benefits or rights from the company.",

        "3. I agree that I am responsible for the payment of all income taxes, any other taxes, business expenses and" +
        " any other associated costs in being an ambassador.",

        "4. I agree that I have read the Ambassador terms, Policies and Procedures, Rewards Plan and Social Media" +
        " Guilefully and that I understand fully the agreement which I am entering into. I agree to abide by all terms," +
        " covenants and agreements and understand that from time-to-time glowb may change these agreements and agree to abide by the current versions.",

        "5. I understand and agree that All success is down to myself. My passion, drive, skills and determination. Any" +
        " materials, literature, social media content, videos or any other promotional materials are for illustrative" +
        " purposes and do not represent a guarantee of income or success.",

        "6. I accept and understand that if I fail to comply with this agreement, including the Policies and Procedures" +
        " and Social Media Guide then I will be subject to sanctions, up to and including forfeiture of commissions and" +
        " termination of my agreement.",

        "7. I understand that I can terminate this agreement at any time by emailing legal@glowb.shop",

        "8. The term of this agreement is for 1 year. I understand that when I first join I have 14 days to activate my" +
        " account through placing an order of any value through my own shop. This can be a personal purchase or a" +
        " personal sale. You are not required to buy any products. If I fail to do this, after 14 days I understand that my account will be removed and I am free to join again at any time. I understand that once I have activated my account, if I have been inactive for longer than 6 months then I may be removed from the ambassador program at the discretion of the company. I understand Inactive is classed as I have made no personal sales in any given month. The agreement will automatically renew upon any sales being made after 1 year.",

        "9. I agree that I am rewarded a sales commission for sales made, as an Independent glowb ambassador and" +
        " that I shall put my primary focus on referring customers to the company.",

        "10. I understand that there is no reason for me to purchase inventory and I understand that I am prohibited" +
        " from buying commercially unreasonable amounts of products or from purchasing products to solely achieve a" +
        " position or qualify for a bonus.",

        '11. I understand that when I first become as an ambassador, I will be given an exclusive "welcome offer"' +
        ' and that this is time limited to 48 hours of joining. I understand that I am under no obligation to' +
        ' purchase this. Additionally, I understand that I am not paid any personal sales commissions for the' +
        ' welcome offer however it will count towards all rewards plan requirements.',

        "12. I agree and understand that I will not be paid commissions in a qualifying month until the value of the" +
        " commission reaches above £10. Any commissions under this amount, will roll over until the amount has been" +
        " reached.",

        "13. I agree that the company liability to me shall not under any circumstances and for any damage or loss" +
        " of any kind including indirect, special, punitive, compensatory or consequential damages, losses or loss" +
        " of profits which may result from any cause, including but not limited to, breach of warranty, delay, act, error or omission of Bombshells Investments, or the cancellation of my glowb Ambassador business, exceed an amount equal to the remuneration paid to me by Bombshells Investments in the 12 months prior to such liability arising. This provision does not exclude or limit any liability which may not be lawfully excluded or restricted.",

        "14. By joining glowb as an independent ambassador, I understand the agreement grants me the limited" +
        " authority to promote glowb products.",

        "15. I agree to grant glowb the irrevocable right to use any images, assets or any other social media" +
        " content I create with Glowb products indefinitely and without any form or remuneration, special benefits" +
        " or any in kind benefit.",

        "16. I agree that I will operate in a lawful, ethical and moral manner and will not engage in or perform any" +
        " misleading, deceptive or unethical practices in operating as an Independent Ambassador. I agree to act" +
        " morally at all times in the promotion of the ambassador program, the products and wider community. I agree to only promote the ambassador program to people who are able to enter into this agreement under their own informed choice and able to make an informed decision for themselves about its benefits and risks. In the event that I violate any of these conditions, this Agreement may be terminated by the company without further payment or compensation of any kind.",

        "17. I acknowledge that the company expressly reserves all proprietary rights to the glowb name, logo," +
        " trademarks, service marks and copyrighted materials. I understand, acknowledge and agree the company has" +
        " provided me with a non-exclusive licence, during the term of the Agreement to use the glowb Proprietary Marks and Copyrights in conjunction with the marketing programme provided to me. I further agree that I will not use glowb Proprietary Marks or Copyrights in any form whatsoever except as permitted in writing by the company.",

        "18. I understand that as an Independent Ambassador, I am free to select my own means, methods, and manners" +
        " of operation and that I am free to choose the hours and location of my activities.",

        "19. I agree to indemnify and hold harmless the company from any and all claims, losses, damages and" +
        " expenses, including legal fees, arising out of my actions or conduct in violation of this Agreement," +
        " including the company rewards Plan. I agree that in order to recoup any damages and expenses it has incurred due to such violations, the company may offset any commissions or other payments due to me.",

        "20. I understand and agree that certain personal information relating to me and my business will be" +
        " collected, used and retained by the company in accordance with its Privacy Policy, as amended from time to" +
        " time, and otherwise in accordance with applicable privacy laws, and I hereby consent to the same. I also consent to any amendments or revisions to the company Privacy Policy, provided such changes are not unreasonably made.",

        "21. I authorise the company to send me email messages, and/or notify me by phone regarding its products," +
        " services, compensation, and offer other topics that the company determines may be of interest or benefit" +
        " to me. I agree that my receipt of such messages will not be deemed a violation of any legislation or regulations.",

        "22. I agree and acknowledge that I am voluntarily becoming an Independent Ambassador with the company. I" +
        " agree and acknowledge that I am fully able to understand, interpret and comprehend the decision that I am" +
        " making. I am also able to fully understand and comprehend the terms contained in this agreement as well as the policies and procedures and rewards plan. I am able to make an informed choice about entering into this agreement and that this decision is not under the duress or from the direction of anyone else.",

        "23. I agree to act in good faith at all times and in an ethical manner. I fully understand and agree to" +
        " abide by the Policies and Procedures, Rewards Plan and Social media guide, as they are amended from time" +
        " to time. I understand that all success is my own and the company makes no guarantees of earnings or success. I understand that if deemed in violation of these terms at any time then the company may terminate this agreement.",

        "24. I understand that the company may decline my application to join the Ambassador program for any reasons" +
        " it deems fit within a reasonable time frame and this agreement is terminated immediately.",

        "25. In the event any provision of this agreement is deemed void or unenforceable for any reason, that" +
        " provision may be severed and the rest of the agreement shall remain in full force and effect.",

        "26. This Agreement will be governed by and interpreted according to English and Welsh law. All disputes and" +
        " claims arising under the Agreement (including non-contractual disputes or claims) will be subject to the exclusive jurisdiction of the English and Welsh courts."
],
    ambassadorPolicies: [
        "glowb Policies and Procedures",

        "Our Policies and Procedures form part of your Agreement with glowb alongside the Ambassador terms and" +
        " Rewards plan. The Policies and Procedures are composed of the following parts:",

        "- OUR COMMITMENT TO YOU",
        "- YOUR RESPONSIBILITIES AND SOCIAL MEDIA GUIDE",

        "OUR COMMITMENT TO YOU",
        "glowb IS Different. As the Home of Social Selling we are different, by design in the opportunity we have" +
        " created, the community we are building and the company we operate as. This agreement is designed to give" +
        " you clear guidelines on how to maximise the glowb Ambassador program, how to protect and uphold our brand and how we support YOU in building your dreams.",

        "We built the company as a home for you. To embrace modern technology, create an income through social" +
        " selling and to be part of a community that uplifts, inspires and drives sisterhood forward globally. As" +
        " Ambassadors we exist to support you. We wanted to build an environment that was clear, transparent, fair and enables you to choose your OWN version of success.",

        "This is our commitment to you. It isn’t marketing talk, it’s our operating guide. It’s our north star and" +
        " how we make decisions. It truly is a commitment between us and each and every Bombshell.",

        "WE STAND FOR THE FOLLOWING:",
        "We believe in an Inclusive Community",
        "We believe that EVERYONE from all walks of life deserves an opportunity to flourish. That no matter" +
        " someone’s race, religion, beliefs, gender, orientation, views or even what planet they are from they are" +
        " welcome in our community. We hold everyone’s right to their own identity and belief system as a human right and fiercely protect that.",

        "We believe our support team exists to support YOU, our Ambassadors. All of our support teams globally exist" +
        " to help you build a business and make a difference in the world. We are here to help you grow, to help you" +
        " develop, to help you shine.",

        "We believe in working together to grow our impact",

        "We strongly believe in working in partnership with you to help grow. That means hearing your feedback," +
        " working with you to resolve conflicts and supporting you in what you need to build a successful business.",

        "We believe in your voice",
        "We believe you deserve to be heard. Monthly leadership calls, instant feedback in our systems and regular" +
        " engagement with YOU gives you the platform to shape the community we are. We actively seek the unpopular" +
        " opinion, the view that differs and give space for everyone to be heard.",

        "We believe that everyone deserves to benefit from the growth in our movement. As we grow, we will take every" +
        " opportunity to reward you more. That means fun and incentive trips. It means reviewing our rewards plan to" +
        " make sure it stays highly lucrative. It means giving you the best learning and personal growth experiences.",

        "We believe in being transparent and honest. Sometimes uncomfortable conversations have to be had. We believe" +
        " in having those conversations openly, directly and honestly. That means including you in challenges we may" +
        " face, the decisions we make and any changes to our opportunity.",

        "We believe in YOU guiding our future direction",

        "We understand that you are at the front of our business, working with customers, ambassadors and future" +
        " ambassadors every single day. We want to give you input in how we grow and we actively seek out ways for" +
        " you to guide us in what you need today and what you need for the future.",

        "We believe in providing you world class training",

        "We strive and endeavour to make our training truly world class. We find and work with the best of the best" +
        " so you get everything you need inside of the glowb community to grow and build a successful business. This" +
        " means hiring executive coaches, trainers and teachers that have the specific skills you need to thrive. No more having to guess where to learn from or whom to follow.",

        "We believe in WOW Service. WOW matters to us. We want your customers to be wow’d in every interaction with" +
        " our team. We want you, our ambassadors to know that we have your backs and do everything we can to support" +
        " you in building a stable and growing customer base and being able to turn customers into raving fans. This also applies to YOU. In every interaction with glowb HQ we aim to go above and beyond, to strive for excellence in all we do.",

        "Policies and Procedures - Your responsibilities",

        "1. You understand that this agreement constitutes part of your agreement with Bombshells Investments LTD" +
        " (known as “glowb”, formerly known as “Business Bombshells”) as an Independent Ambassador. Please ensure" +
        " you have read the glowb Ambassador terms which form a part of this agreement.",

        "2. glowb at its sole discretion may amend this agreement with seven days notice. By joining the Ambassador" +
        " program you agree to abide by the most current version as found as amended by glowb from time to time. The" +
        " most recent copy can be found on the glowb back office.",

        "3. glowb shall not be responsible for delays or failure in performance of its obligations when performance" +
        " is made commercially impracticable due to circumstances beyond its reasonable control. This includes," +
        " without limitation, strikes, labor difficulties, riot, war, fire, death, pandemic, curtailment of a source of supply, or government decrees or orders.",

        "4. If any provision of the Agreement, in its current form or as may be amended, is found to be invalid or" +
        " unenforceable for any reason, only the invalid portion(s) of the provision shall be severed and the" +
        " remaining terms and provisions shall remain in full force and effect.",

        "5. You understand that you are joining as an independent Ambassador and you are not required to purchase" +
        " any products or services to be a part of the program.",

        "6. Once your application to become a glowb Ambassador has been accepted you have the benefits of the" +
        " rewards plan, the right to refer others to the Ambassador program, earn a commission from any product" +
        " sales and access support from glowb. You understand that you are only allowed one Ambassador account with glowb. For the purpose of this agreement, one ambassador account constitutes your entire household. Any exception to this policy requires written agreement from the directors of the company. Any attempt to circumnavigate or contravene by falsely using another person’s address will result in termination.",

        "7. You agree to act at all times in good faith as a glowb ambassador. This includes your actions in" +
        " promoting the products, the ambassador program, your communications with the company and in any other" +
        " actions tied to the rewards plan. You understand and accept that the company reserves the right to withhold any remuneration if it believes that the Ambassador has not acted in good faith.",

        "8. All ambassadors shall at all times safeguard and promote the good reputation of glowb, its products," +
        " other ambassadors and all employees and/or its appointed agents. Any and all marketing behaviour shall be" +
        " consistent with the public interest, and must avoid all discourteous, deceptive, misleading, unethical or immoral conduct or practices. You agree at all times to hold your conduct, especially on social media to the highest standard. You understand that glowb does not tolerate bullying in any form.",

        "9. You agree at all times to use the official glowb websites (including your own replicated site), back" +
        " office and to not recreate all or any part of any sales or marketing channels. You agree not to use any" +
        " other website to market, sell or advertise the Bombshell Ambassador program or any glowb products. This includes yet is not limited to Amazon, Ebay, Gumtree or any other website not officially owned by glowb. You also agree not to use buy/sell/swap or work from home sites on facebook groups, pages or any other buy/sell/swap sites on social media for the purpose of making sales or building a team. Additionally, you agree not to “spam” any sites, pages, social media channels or any other marketing channels.",

        "10. glowb Ambassadors may create and upload glowb related videos and content as long as it aligns with the" +
        " glowb Our Commitment to You and is compliant with all policies set in this agreement. All content must" +
        " clearly identify you as an Independent glowb Ambassador.",

        "11. You are not allowed to sell any products via any channels other than your replicated glowb website. You" +
        " are not allowed to hold, store or request customer information and agree that all orders will be placed by" +
        " customers themselves through your replicated site. You agree that any products purchased by you are for your sole personal use and not for resale in any form.",

        "12. Social media advertising is allowed yet must go to a social media account that fairly and accurately" +
        " represents you as an independent glowb ambassador or your replicated website. You are not allowed to" +
        " portray yourself as an employee, owner, agent or any associated roles other than as an independent ambassador.",

        "13. It is your responsibility to abide by the terms of all social media platforms that you use. If the" +
        " social media site does not allow its site to be used for commercial activity, you must abide by the site’s" +
        " terms of use.",

        "14. Social Media content and assets will be provided for you. You are not allowed to modify these in any" +
        " way that breaches any glowb Trademarks, copyright or in any way that could cause damage to the brand.",

        "15. The name of glowb and other names that may be adopted by Bombshells Investments including yet not" +
        " limited to; glowb, GLOWB, and Beauty by BB are proprietary trade names, trademarks and service marks. You" +
        " are only allowed to use these marks as indicated in the social media guide. You also agree that you will not recreate or use any glowb marks on any merchandise for retail. This includes donations or sale of any kind. You have a limited license to use our marks for merchandise for personal use only.",

        "16. Independent Ambassadors must not attempt to respond to media inquiries regarding glowb. All media" +
        " inquiries must be referred to legal@glowb.shop",

        "17. You agree not to undertake any advertising that misleads or misrepresents your relationship with glowb.",

        "18. You agree that you will not purchase products for the sole purpose of a promotion, new position or any" +
        " bonus. You also agree that you will not entice someone to enroll in the ambassador program for the sole" +
        " benefit of helping you to achieve any bonuses. You agree to limit all purchases to personal use and refer any and all customers to your replicated website. What constitutes personal use is at the sole discretion of the company. The company retains the right, at any time to cancel orders that it, at its sole discretion feels does not constitute personal use.",

        "19. To protect all ambassadors, no change of mentor (the person who referred you to the Ambassador program)" +
        " is allowed after registration. Any changes, in extreme circumstances, are at the sole discretion of the" +
        " company. If you wish to change mentors, you can at any time, email legal@glowb.shop to request the closure of your account. You may then rejoin the ambassador program after waiting six calendar months.",

        "19.a Upon joining the glowb Ambassador program, you have 14 days to activate your account through the" +
        " purchase or sale of any product. If you fail to do this, your ambasador account will be removed and no" +
        " longer exist. You are free to rejoin at any point if your account was not activated.",

        "20. Once your account is activated, you are allowed to cancel your ambassador agreement at any time by" +
        " emailing legal@glowb.shop. You are entitled to re-join the Ambassador program after an inactive period of" +
        " six months. After six months of inactivity your account will be removed from our system. Inactivity is classed as not achieving the position of 1* Rising at any time in six full calendar months. You must act in good faith at all times and will make no attempt to circumvent this agreement in any way. Any attempt to circumvent this policy will result in severe action, not limited to loss of remuneration and/or termination from the ambassador program.",

        "21. An Ambassador is fully responsible for all of their verbal and/or written statements made in regards to" +
        " glowb and the rewards plan that are not expressly contained in official Business glowb materials. You" +
        " agree to indemnify glowb and its directors, officers, employees, and agents, and hold them harmless from any and all liability including judgments, civil penalties, refunds, legal fees, court costs, or lost business incurred by glowb as a result of the Ambassadors unauthorised representations or actions.",

        "22. No product claims will be made as to therapeutic, curative or beneficial properties of any products" +
        " offered by glowb except those contained in the official materials. You also agree that you will not make" +
        " product comparisons against products of other companies.",

        "23. You agree to not make any vague, misleading, false or partial income claims. You agree to be" +
        " transparent in the work involved in building your ambassador organisation. You also agree to not" +
        " misrepresent others’ earnings as your own or use the earnings of others to entice more people into the social selling program. You agree not to make any income guarantees, promises or projections. You agree to fairly represent the work, time and effort that went into building your business, especially tied to any income statements.",

        "24. You agree to make clear to any perspective ambassadors that All success is down to their own hard work." +
        " Their passion, drive, skills and determination. You agree to make it clear that any materials, literature," +
        " social media content, videos or any other promotional materials are for illustrative purposes and do not represent a guarantee of income or success.",

        "25. You agree to not make any claims around the rewards plan that are false, misleading or deceptive. You" +
        " agree to make it clear that the rewards plan is centred around the referral of customers for retails" +
        " purchases. You will make no claims that may lead to someone believing that they can be successful without hard work, skills and effort.",

        "26. All glowb products may only be sold in their original packaging. You may not repackage, re-label or" +
        " resell any glowb. Any Products sold in a bundle are prohibited from being sold individually. You agree to" +
        " not offer samples or trials of any products outside of the options provided to you through your personalised shop.",

        "27. glowb is a Social Selling company. This is a unique business model and you agree not to promote the" +
        " Ambassador program in any way other than as Social Selling. glowb is not a Network Marketing, MLM, Direct" +
        " Sales, Party Plan, Affiliate or any similar business model. You agree not to promote or advertise it as such.",

        "28. You are prohibited from promoting or selling any products that directly compete with glowb. This is" +
        " defined by generic categories (such as skincare or health products). You agree to not sell, or attempt to" +
        " sell other products or services of any kind to other glowb ambassadors. You agree not to bundle or package glowb products for sale with any other products.",

        "29. You agree not to promote, recruit, market or advertise any other business opportunities to any glowb" +
        " Ambassadors. Additionally, you agree not to promote, recruit, market or advertise any other business" +
        " opportunities at the same time as glowb. Failure to adhere to this policy may result in termination at the discretion of the company.",

        "30. You will not make any false or misleading claims with regards to the Social Selling business model.",

        "31. You are responsible for all taxes and costs that arise from being an Independent ambassador and on any" +
        " income earned.",

        "32. You agree to comply with any local, state or country laws and accept it is your responsibility to stay" +
        " up to date with any changes that may affect you.",

        "33. You agree to act in good faith and in an ethical manner in regards to the rewards plan. Any intentional" +
        " manipulation of unintended loopholes or unethical behaviour is prohibited.",

        "34. You agree not to promote to other Ambassadors the services of yourself, or any speaker, coach," +
        " consultant, trainer or similar business without expressed approval from the company. You understand that" +
        " you personally can provide training for your team however may not charge directly or indirectly for this.",

        "35. If any member of your household engages in activity which, if performed by an Ambassador would violate" +
        " this or any agreement with glowb, such activity will be deemed a violation by the Ambassador and glowb" +
        " will take appropriate disciplinary action pursuant to these policies and procedures against the ambassador.",

        "36. In the event of death or incapacitation, your ambassador account can pass to your heirs as long as they" +
        " are legally able to enter into all ambassador agreements themselves.",

        "37. Any Ambassador that is in good standing with glowb may refer others to the Ambassador program. Each" +
        " Ambassador has the right to choose their own mentor. There is no “claims” or “ownership” over a" +
        " prospective ambassador of any kind. All prospective ambassadors must fill out their own registration under their own free will and agree to the terms.",

        "38. glowb makes available an online back office available to all Ambassadors. This back office provides" +
        " access to confidential and proprietary information that may be used solely and exclusively to promote the" +
        " development of a glowb Ambassador business and increase sales of glowb products. glowb reserves the right to deny Ambassadors access to the back office at its sole discretion.",

        "39. It is your responsibility to ensure that all of your details, including yet not limited too; Address," +
        " email and bank details are correct and up to date. glowb is not liable for any delays in remuneration made" +
        " due to incorrect or out of date personal information. You understand that to be paid, your bank details must match the details of your agreement with glowb.",

        "40. glowb has in place a variety of opportunities for an Ambassador to give feedback. We value your opinion" +
        " and your voice. Any and all complaints will be addressed in a timely manner. Whilst we welcome" +
        " constructive feedback, negative comments serve no purpose other than to demotivate. You agree not to disparage, demean or make negative remarks about glowb, its owners, directors, officers or employees.",

        "41. Any ambassador observing a violation of these or any policies associated with glowb should report this" +
        " to legal@glowb.shop immediately.",

        "42. An Ambassador must be active and in compliance with the agreement to qualify for bonuses and commissions.",

        "43. You understand that if a product is cancelled or returned to glowb that it is at the sole discretion of" +
        " the company whether it passes any charges onto the Ambassador. You accept that the company has a right to" +
        " reclaim any commissions or bonuses that have been paid on a returned or cancelled product at its sole discretion. You agree to act in good faith at all times when placing orders, referring customers to place orders and referring others to the ambassador program with regards to any commissions or bonuses. The company retains the right to cancel or withhold bonuses and commissions it believes to be earned in bad faith. You agree and understand that for each order you personally return that an administration fee of up to £25 (or equivalent in local currency) will be charged per order, taken from commissions owed. This does not affect your rights under our returns policy.",

        "44. You agree and understand that glowb will cover the costs associated of one payment per month to you and" +
        " for you to make one withdrawal per month. You understand that any withdrawals outside of this will be" +
        " charged at £10 per withdrawal and that the administration fee will be taken from any future commissions owed. You are responsible for all other fees that may arise that are outside of the company's control.",

        "45. Any information provided by glowb in any Ambassador activity is believed to be accurate and reliable." +
        " ALL PERSONAL AND GROUP VOLUME INFORMATION IS PROVIDED “AS IS” WITHOUT WARRANTIES, EXPRESS OR IMPLIED OR" +
        " REPRESENTATIONS OF ANY KIND. IN PARTICULAR BUT WITHOUT LIMITATION THERE SHALL BE NO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE, OR NON-INFRINGEMENT. TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, GLOWB AND OR OTHER PERSONS CREATING OR TRANSMITTING THE INFORMATION WILL IN NO EVENT BE LIABLE TO ANY AMBASSADOR OR ANYONE ELSE FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES THAT ARISE OUT OF THE USE OF OR ACCESS TO PERSONAL AND GROUP INFORMATION (INCLUDING BUT NOT LIMITED TO LOST PROFITS, BONUSES OR COMMISSIONS, LOSS OF OPPORTUNITY AND DAMAGES THAT MAY RESULT FROM INACCURACY, INCOMPLETENESS INCONVENIENCE, DELAY OR LOSS OF THE USE OF THE INFORMATION).",

        "46. You agree that at no time will you claim to be endorsed, sponsored, in partnership with or have any" +
        " other agreement in place except for the glowb Ambassador agreement.",

        "47. You are allowed to create and maintain a private (non-public) facebook group as long it does not in any" +
        " way imply or infer that it is an official glowb Facebook group. This includes in the name of the group," +
        " description and in all materials and communications. The group name must not include “glowb” or “Beauty by BB”.",

        "48. You agree that at no point will you infer, suggest, claim, hint or imply that you, your team or anyone" +
        " associated with you has a “privileged” position, has benefits that other Ambassadors do not or make any" +
        " claim that would give you a perceived advantage. You accept that all glowb Ambassadors have the same opportunity to be successful and it is solely down to hard work, skill, determination and effort.",

        "49. You agree that at no time will you make any promises of success, guarantees of referrals or guarantees" +
        " of success. Breach of this clause will result in significant disciplinary action including and not limited" +
        " to Termination of this agreement.",

        "50. You agree and accept that you will not create any social media accounts using any glowb trademarks" +
        " including yet not limited to “glowb” or “GLOWB” or “Beauty by BB”. You agree to make it clear in your bio" +
        " that you are an Independent glowb ambassador and will not make any claim to a location, geographic area or demographic as exclusive or limited in any way to you.",

        "51. You understand and agree that any commissions paid out to you on returned products will be deducted" +
        " from any refund you are due on those products. You will only be charged a restocking fee and the" +
        " commission paid out to you based on those orders. You agree to not purchase products for the purposes of getting any form of payment and all orders placed will be for your own personal use. You agree and understand that any bonuses paid out on orders that have subsequently been returned by you, or any member of your team, thus making you no longer meeting the requirements for that bonus, may be, at any time subject to recovery by the company. This is at the sole discretion of the company.",

        "52. If the Ambassador is in breach of this or any agreements made with glowb, the company reserves the" +
        " right to take the necessary action to protect its interests at its sole discretion. This includes" +
        " termination of the agreement.",

        "53. You agree and understand that any discount codes you qualify for, are given or that are active during a" +
        " promotion are for the sole use by you for your own personal customer base. You agree not to share any" +
        " discount code publicly and understand any orders placed using your discount code that are not from personal customers that you have pre-existing relationships with will result in the forfeiture of these orders counting towards any and all promotions, commissions and bonuses. You agree not to give anyone you have referred to the ambassador program your discount code for them to use.",

        "54. Any payout of commissions due under £10 (or equivalent in local currency) on any given month will roll" +
        " over to the next eligible month for payout when crossing the £10 threshold. You can at anytime request" +
        " this to be paid out to you by emailing legal@glowb.shop",

        "55. This agreement is governed by the laws of England and Wales."
    ]
}

export default Signup