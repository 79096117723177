import React, {Component} from 'react';
import Modal from "../../cmp/Modal";
import Big from 'big.js';
import {get, post} from "../../../api";
import CurrencyNumber from "../../cmp/CurrencyNumber";
import Spinner from "../../cmp/Spinner";
import CopyButton from "../../cmp/CopyButton";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import DefaultCurrencyRender from "../../cmp/DefaultCurrencyRender";
import InputGroup from "../../cmp/form/InputGroup";
import {getCouponLink} from "../../../tools/func";

class StoreDiscountCouponAutoRedeem extends Component {
    state = {
        redeem_amount: 0,
    }


    componentDidMount() {
        this.load()
    }


    load = () => {
        this.setState({loading: true})
        get('/payments/autopay/discount_code/amount_payable')
            .then((data) => {
                const {amount_payable,
                    autopay_dc_increments,
                    autopay_dc_conversion, autopay_dc_lag_time, autopay_dc_max_amount, autopay_dc_min_amount} = data;

                const max = autopay_dc_max_amount ? Math.min(autopay_dc_max_amount, amount_payable) : amount_payable;
                const min = autopay_dc_min_amount || 0;
                this.setState({
                    amount_payable,
                    autopay_dc_conversion,
                    autopay_dc_lag_time,
                    autopay_dc_increments,
                    loading: false,
                    max, min
                })
            })
    }

    redeem = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState({redeeming: true, error: false, redeemed: false})
        const {redeem_amount} = this.state
        post('/payments/autopay/discount_code', {
            redeem_amount
        }).then((data) => {
            const {error, coupon, discount_amount} = data
            this.setState({error, coupon, discount_amount, redeeming: false, redeemed: true})
        })
    }


    onChange = (e) => {
        const val = e.target.value;
        const {max, min} = this.state;
        if(isNaN(Number(val))){
            this.setState({redeem_amount: e.target.value})
        }else {
            this.setState({redeem_amount: Number(val) > max ? max : val})
        }
    }

    render() {
        const {redeem_amount, min, max} = this.state;
        return (
            <Modal title={"Redeem"}
                   onSubmit={!this.state.coupon && this.redeem}
                   okText={!this.state.coupon && "Redeem"}
                   confirmLoading={this.state.redeeming}
                   visible={this.props.visible}
                   onClose={this.props.onClose}
                   disabled={Number((redeem_amount || 0)) === 0 || Number((redeem_amount || 0)) < Number((min || 0))}
                   onCancel={!this.state.coupon && this.props.onClose}>

                {
                    this.state.loading ?
                        <div style={{height: 200}} className={"d-flex align-items-center justify-content-center"}>
                            <Spinner/>
                        </div> : <>
                            {
                                this.state.coupon ?
                                    <div className={"text-center"}>
                                        <p>
                                            <FormattedMessage id={"Here is your amount discount coupon"} values={{
                                                amount: <CurrencyNumber value={this.state.discount_amount}/>
                                            }}/></p>
                                        <p className={"lead text-center"} style={{fontSize: '2rem'}}>{this.state.coupon}</p>
                                        <div className="row">
                                            <div className="col text-center">
                                                <CopyButton text={this.state.coupon} className={"btn btn-outline-primary btn-sm"}/>
                                                {
                                                    ['shopify','woocommerce'].indexOf(this.props.integration) > -1 &&
                                                    <a
                                                        rel={"noreferrer noopener"}
                                                        target={"_blank"}
                                                       href={getCouponLink(this.props.integration, this.props.website, this.state.coupon)}
                                                       title={"Use discount code"}
                                                       className={"ml-2 btn btn-outline-primary btn-sm"}>
                                                        <FormattedMessage id={"Use discount code"}/>
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                    </div> :
                                    <div className="text-center">
                                        <div><FormattedMessage id={"Spend"}/></div>
                                        {
                                            this.state.autopay_dc_increments ? <>
                                                    <div className="display-4"><CurrencyNumber value={this.state.redeem_amount || 0} /></div>
                                                </> :
                                            <div className="py-2">
                                                <InputGroup prefix={<DefaultCurrencyRender/>}
                                                            className="text-center form-control-lg form-control display-4"
                                                            onChange={this.onChange}
                                                            step={1}
                                                            min={0}
                                                            type={"number"}
                                                            max={max}
                                                            value={this.state.redeem_amount}/>
                                            </div>
                                        }

                                        {
                                            max || min ?
                                                <>
                                                    <div className="row">
                                                        {
                                                            min &&
                                                            <div className="col text-center">
                                                                <FormattedMessage id={"Minimum Amount"} values={{
                                                                    amount: <CurrencyNumber value={min}/>
                                                                }}/>
                                                            </div>
                                                        }
                                                        {
                                                            max &&
                                                            <div className="col text-center">
                                                                <FormattedMessage id={"Maximum Amount"} values={{
                                                                    amount: <CurrencyNumber value={max}/>
                                                                }}/>
                                                            </div>
                                                        }
                                                    </div>
                                                </> : null
                                        }
                                        <input type={"range"}
                                               disabled={this.state.redeeming}
                                               max={max}
                                               step={this.state.autopay_dc_increments || 1}
                                               className={"form-control"}
                                               onChange={this.onChange}
                                               value={this.state.redeem_amount}
                                        />
                                        <p>
                                            <small className={"text-muted"}>
                                                <FormattedMessage
                                                    id={"Move the slider to select the amount you want to redeem"}/>
                                            </small>
                                        </p>
                                        <div><FormattedMessage id={"Get discount code worth"}/></div>
                                        <p className={"display-4"}>
                                            <CurrencyNumber
                                                value={Big(Number(this.state.redeem_amount) || 0).times(this.state.autopay_dc_conversion || 1).toString()}/>
                                        </p>
                                    </div>
                            }
                        </>
                }
                {
                    this.state.error && <p className={"text-danger"}>{this.state.error}</p>
                }

            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        website: state.settings.website,
        integration: state.settings.integration,
    }
}

export default connect(mapStateToProps)(StoreDiscountCouponAutoRedeem);
