import React, {Component, useEffect} from 'react';
import OffersPage from './pages/OffersPage';
import Payments from './pages/Payments';
import {Link, NavLink, Route, Switch} from 'react-router-dom';
import Home from './pages/Home';
import EmailAddressNotVerified from './cmp/EmailAddressNotVerified';
import AccountPendingApproval from './cmp/AccountPendingApproval';
import {connect} from 'react-redux';
import ContactUs from './pages/ContactUs';
import {FormattedMessage} from 'react-intl';
import * as localStorage from '../tools/storage';
import CustomPage from './pages/CustomPage';
import LogoRender from "./cmp/LogoRender";
import LazyPageBuilder from "./pages/pagebuilder/LazyPageBuilder";
import LazyStoreIndex from "./pages/inportalstore/LazyStoreIndex";
import LazyMLMPage from "./pages/mlm/LazyMLMPage";
import LazyHelpPage from "./pages/help/LazyHelpPage";
import LazyAnalyticsPage from "./pages/analytics/LazyAnalyticsPage";
import LazySettingsPage from "./pages/LazySettingsPage";
import LazyW9Page from "./pages/taxforms/w9/LazyW9Page";
import {loadCommissions} from "../redux/commissions/commissionsActions";
import LandingFooter from "./pages/landing/components/LandingFooter";
import TransactionsIndex from "./pages/transactions/TransactionsIndex";
import {useSelector} from 'react-redux'
import LazyTipaltiIndex from './pages/tipalti/LazyTipaltiIndex'
import EarningsReport from './pages/glowb/EarningsReport';
class Dashboard extends Component {
    state = {
        selected: {},
        opts: [],
        collapsed: true,
    }

    componentDidMount() {
        this.props.loadCommissions();
    }

    handleLogout = () => {
        localStorage.removeItem('access_token');
        this.props.history.push('/logout');
    }

    toggleNavbar = () => {
        this.setState({collapsed: !this.state.collapsed})
    }

    navigate = (e) => {
        this.props.history.push(e.target.value)
    }

    getNavValue = () => {
        const foundPath = this.getTabs().find((item) => {
            return this.props.history.location.pathname.startsWith(item.to) && !item.exact;
        });

        return foundPath ? foundPath.to : this.props.history.location.pathname
    }

    getTabs = () => {
        const tabs = [];
        if (this.props.aff_home_enabled) tabs.push({to: '/', id: 'navlinkHome', exact: true, active_tab_color: "#FDE7EA"});
        if (this.props.enable_page_builder) tabs.push({to: '/mypages', id: "Pages", active_tab_color: "#FDE7EA"});
        tabs.push({to:'/earnings-report',id:'Earnings Report',active_tab_color: "#FDE7EA"})
        if (this.props.aff_products_enabled) tabs.push({to: '/products', id: "Marketing Tools", active_tab_color: "#FDE7EA"});
        if (this.props.aff_analytics_enabled) tabs.push({to: '/analytics', id: "Analytics", active_tab_color: "#FDE7EA"});
        //if (this.props.mlm_enabled && this.props.aff_network_tab_show) tabs.push({to: '/network', id: "Network", active_tab_color: "#FDE7EA"});
        tabs.push({to: '/network', id: "Network", active_tab_color: "#FDE7EA"});
        
        if(this.props.aff_inportalstore_enabled) tabs.push({to:'/store',id:'Store', active_tab_color: "#FDE7EA"});
        if(this.props.aff_show_transactions) tabs.push({to:"/transactions",id:'Transactions', active_tab_color: "#FDE7EA"});
        if (this.props.aff_payments_enabled) tabs.push({to: '/payments', id: "navlinkPayments", active_tab_color: "#FDE7EA"});
        this.props.aff_extra_tabs.filter((item) => String(item.page_status) === '1' && String(item.title).trim()).forEach((item) => tabs.push({
            to: `/pages/${item.id}`,
            id: item.title,
            active_tab_color: "#FDE7EA"}))
        if (this.props.aff_settings_enabled) tabs.push({to: '/settings', id: "navlinkSettings"});
        return tabs;
    }

    render() {
        const bgColorStyle = {
            backgroundColor: this.props.dashboard_bg_color || 'inherit'
        };
        const {active_tab_color, inactive_tab_color, logo_align} = this.props;
        const inactiveStyle = inactive_tab_color ? {color: inactive_tab_color} : {};
        const tabs = this.getTabs();
        return (
            <div className={`${this.props.isEmbedded || this.props.dashboard_bg_color ? '' : 'bg-light '} min-vh-100`} style={bgColorStyle}>
                {/*Displays main banner on top of the page*/}
                {/*<a target={"_blank"} href={'https://us02web.zoom.us/j/84927753179?pwd=eW9pbjJ0M1NTdkFzTFFlSFhSaCs0UT09'}>*/}
                {/*    <img src={'/glowb-images/hangout-banner.jpg'} alt={"Hangout with soph"} className={"w-100"} />*/}
                {/*</a>*/}

                <div className="container px-0 py-3" style={{position: 'relative'}}>
                    <div>
                        {
                            this.props.aff_logo_on_dashboard &&
                            <div className={`mt-2 ${logo_align === "center" ? 'text-center' : ''}`}>
                                <LogoRender height={80}/>
                            </div>
                        }
                    </div>
                </div>
                <div className={`container px-0 ${this.props.isEmbedded ? '' : ' pt-4 pb-4'}`}>
                    {
                        !this.props.email_verified && this.props.notify_affiliate_email_verification ?
                            <EmailAddressNotVerified/> : null
                    }
                    {
                        this.props.account_status === 'pending' ? <AccountPendingApproval/> : null
                    }
                    {
                        this.props.account_status === 'blocked' ? <AccountBanned/> : null
                    }
                    <select className={"form-control d-block d-md-none border-bottom"} value={this.getNavValue()}
                            onChange={this.navigate}>
                        {
                            tabs.map((item) => {
                                return <FormattedMessage key={String(item.id)} id={item.id}>{text => <option
                                    value={item.to}>{text}</option>}</FormattedMessage>
                            })
                        }
                        <option value={"/"}>---</option>
                        {
                            !this.props.isShopifyAutoLogin ?
                                <FormattedMessage id={"Logout"}>{text => <option
                                    value="/logout">{text}</option>}</FormattedMessage> : null
                        }

                    </select>

                    <ul className="nav nav-tabs d-none d-md-flex">
                        {
                            tabs.map((item) => <TabLink to={item.to} exact={item.exact} id={item.id} key={item.id}
                                                        active_tab_color={item.active_tab_color}
                                                        inactive_tab_color={inactive_tab_color}/>)
                        }
                        {
                            !this.props.isShopifyAutoLogin ? <>
                                    <li className={'justify-content-end d-none d-md-flex'} style={{flex: 1}}>
                                        <button className={'px-0 btn btn-link'} onClick={this.handleLogout}
                                                style={inactiveStyle}>
                                            <FormattedMessage id={'logout'}/></button>
                                    </li>
                            </> : null
                        }
                    </ul>
                    <div style={{backgroundColor: "#FDE7EA"}} className={`border-right border-left border-bottom p-4 rounded-bottom ${!this.props.isEmbedded && 'shadow'}`}>
                        {/*TODO - Fix hyperlink and button color on marketing tools tab*/}
                        <Switch>
                            <Route path={'/payments'} component={Payments}/>
                            <Route path={"/analytics"} component={LazyAnalyticsPage}/>
                            <Route path={'/products'} component={OffersPage}/>
                            <Route path={"/settings/legal/w9"} component={LazyW9Page}/>
                            <Route path={'/settings'} component={LazySettingsPage}/>
                            <Route path={'/network'} component={LazyMLMPage}/>
                            <Route path={'/help'} component={LazyHelpPage}/>
                            <Route path={'/mypages'} component={LazyPageBuilder}/>
                            <Route path={"/earnings-report"} component={EarningsReport} />
                            {
                                this.props.aff_inportalstore_enabled &&
                                <Route path={"/store"} component={LazyStoreIndex}/>
                            }
                            <Route path={'/pages/:page_id'} component={CustomPage}/>
                            <Route path={'/partner-contact'} component={ContactUs}/>
                            <Route path={"/transactions"} component={TransactionsIndex} />
                            <Route path={"/tipalti"} component={LazyTipaltiIndex} />
                            <Route path={'/'} component={Home}/>
                        </Switch>
                    </div>
                </div>
                <div className="pb-4"/>
                {
                    !this.props.isEmbedded && <LandingFooter inDashboard/>
                }
                <EmbeddedIFrameSendReferralCodeToParent />
            </div>
        );
    }
}

function TabLink({to, id, exact, active_tab_color, inactive_tab_color}) {
    const inactive_tab_style = inactive_tab_color ? {color: inactive_tab_color} : {};
    const active_tab_style = active_tab_color ? {backgroundColor: active_tab_color} : {};
    return (<li className="nav-item">
        <NavLink className="nav-link text-dark" exact={exact} activeClassName={"text-dark"} to={to} style={inactive_tab_style}
                 activeStyle={active_tab_style}>
            <FormattedMessage id={id}/>
        </NavLink>
    </li>);
}

function AccountBanned() {
    return (<p className={'alert alert-danger'}>
        <FormattedMessage id={'accountBannedMessage'}/>
    </p>);
}

function EmbeddedIFrameSendReferralCodeToParent(){
    const isEmbedded = useSelector((state)=>state.settings.isEmbedded)
    const ref_code = useSelector((state)=>state.settings.ref_code)
    useEffect(()=>{
        if(window.top && ref_code){
            window.top.postMessage('set-ref-code:'+ref_code, '*')
        }
    },[isEmbedded, ref_code])
    return null
}

const mapStateToProps = (state, props) => {
    const {
        notify_affiliate_email_verification,
        page_sections, aff_logo_on_dashboard, store_logo, store_name,
        email_verified, status,
        aff_analytics_enabled,
        aff_inportalstore_enabled,
        aff_show_transactions,
        mlm_enabled,
    } = state.settings;
    const {logo_align} = (page_sections.default_navigation && page_sections.default_navigation.props) || {};
    let aff_extra_tabs = [];
    if(state.settings.aff_extra_tabs){
        aff_extra_tabs = typeof  state.settings.aff_extra_tabs === "string" ? JSON.parse(state.settings.aff_extra_tabs) : state.settings.aff_extra_tabs
    }
    console.log(state.settings)
    
    return {
        notify_affiliate_email_verification,
        store_logo,
        store_name,
        email_verified,
        account_status: status,
        website: state.settings.website,
        mlm_enabled,
        aff_home_enabled: state.settings.aff_home_enabled === undefined ? true : state.settings.aff_home_enabled,
        aff_payments_enabled: state.settings.aff_payments_enabled === undefined ? true : state.settings.aff_payments_enabled,
        aff_settings_enabled: state.settings.aff_settings_enabled === undefined ? true : state.settings.aff_settings_enabled,
        aff_products_enabled: state.settings.aff_products_enabled === undefined ? true : state.settings.aff_products_enabled,
        aff_analytics_enabled,
        subscription: state.settings.subscription,
        isEmbedded: state.settings.isEmbedded,
        dashboard_bg_color: state.settings.dashboard_bg_color,
        active_tab_color: state.settings.active_tab_color,
        inactive_tab_color: state.settings.inactive_tab_color,
        aff_extra_tabs,
        aff_logo_on_dashboard,
        enable_page_builder: state.settings.enable_page_builder,
        aff_show_transactions,
        aff_network_tab_show: state.settings.aff_network_tab_show === undefined ? true : state.settings.aff_network_tab_show,
        logo_align,
        aff_inportalstore_enabled
    }
}

function mapDispatchToProps(dispatch){
    return {
        loadCommissions: ()=>dispatch(loadCommissions()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
