import React, {Component} from 'react';
import {ArrowLeft, ArrowRight, EmptyBoxIcon, SortDownIcon, SortUpIcon} from '../../icons/Icons';
import PropTypes from 'prop-types';
import StyledProgressLoader from './themed/StyledProgressLoader';
import {FormattedMessage} from "react-intl";

class BootStrapPagedTable extends Component {

    state = {
        currentPage: 0,
        sortColumnKey: '',
        sortDirection: 1
    }


    setSortColumnKey = (sortColumnKey) => () => {
        if (this.state.sortColumnKey === sortColumnKey) {
            this.setState({sortDirection: this.state.sortDirection === 1 ? 0 : 1});
        } else {
            this.setState({sortColumnKey});
        }
    }

    onNext = () => {
        this.setState({currentPage: this.state.currentPage + 1});
    }

    onPrev = () => {
        this.setState({currentPage: this.state.currentPage - 1});
    }

    render() {
        const {columns, per_page, showfilter} = this.props;
        const {sortColumnKey, currentPage, sortDirection} = this.state;
        let data = this.props.dataSource;

        if (sortColumnKey) {
            const sorter = columns.find((item) => (item.key || item.dataIndex) === sortColumnKey).sorter;
            // console.log(sorter);
            data = this.props.dataSource.sort(sorter);
            if (sortDirection === 0) {
                data = data.reverse();
            }
        }
        //        console.log('per_page',per_page, data.length)

        return (
            <div className={'table-responsive'}>
                <table
                    className={`table table-striped vertical-align ${this.props.bordered ? 'table-bordered' : ''} ${this.props.small ? 'table-sm' : ''}`}>
                    <thead>
                    <tr>
                        {
                            columns.map((item, index) => {
                                let sortIcon = null;
                                if (item.sorter) {
                                    if ((item.key || item.dataIndex) === sortColumnKey) {
                                        //      console.log('comparing', item, sortColumnKey);
                                        sortIcon = <RenderSortDirectionIcon sortDirection={sortDirection}/>;
                                    } else {
                                        sortIcon = null;
                                    }
                                }
                                const onClick = item.sorter ? this.setSortColumnKey(item.key || item.dataIndex) : null;
                                let thCls = onClick ? ' table-sortable ' : null;
                                thCls += sortIcon ? ' table-sortable-active' : '';
                                thCls += item.type === 'number' ? ' text-right' : '';
                                return (<th onClick={onClick} className={thCls} key={String(item.key || item.dataIndex || index)}>
                                    <div className={'row align-items-center no-gutters'}>

                                        <div className={'col'}>{sortIcon}
                                            {
                                                typeof item.title === "string" ?
                                                    <FormattedMessage id={item.title}/>
                                                    : item.title
                                            }</div>
                                    </div>
                                </th>);
                            })
                        }
                    </tr>
                    </thead>
                    <tbody>

                    {data.length === 0 && !this.props.loading ? <RenderEmptyBox columns={columns}/> : null}
                    {data.length === 0 && this.props.loading ? <tr>
                        <td colSpan={columns.length} className={'my-4 py-4 text-center'}><StyledProgressLoader/></td>
                    </tr> : null}
                    {
                        data.slice(currentPage * per_page, currentPage * per_page + per_page).map((item, index) =>
                            //  console.log(item);
                            <RenderRow onRowClick={this.props.onRowClick} key={String(item.key || item.dataIndex || index)}
                                       item={item} columns={columns}/>
                        )
                    }
                    </tbody>
                </table>
                {
                    per_page < data.length ? <div className={'text-right mb-2'}>
                            <RenderPagination current_page={this.state.currentPage} per_page={per_page} total={data.length}
                                              onNext={this.onNext} onPrev={this.onPrev}
                            />
                        </div>
                        : null
                }
            </div>
        );
    }
}

function RenderEmptyBox({columns}) {
    return (<tr>
        <td colSpan={columns.length}>
            <div className={'text-center'}>
                <EmptyBoxIcon className={'display-3 text-muted'}/>
                <p><FormattedMessage id={"No Data"}/></p>
            </div>
        </td>
    </tr>);
}

function RenderSortDirectionIcon({sortDirection}) {
    return sortDirection === 1 ? <SortUpIcon style={{width: '24px', height: '24px'}}/> :
        <SortDownIcon style={{width: '24px', height: '24px'}}/>;
}

function SortIndeterminateIcon() {
    return (<div>
        <SortUpIcon/>
        <SortDownIcon/>
    </div>);
}

function RenderPagination({total, per_page, current_page, onNext, onPrev}) {
    const nextEnabled = total / per_page > current_page + 1;
    const prevEnabled = current_page > 0;

    return (<div className="btn-group" role="group" aria-label="Basic example">
        <button type="button" className="btn btn-light px-3 py-2" disabled={!prevEnabled} onClick={onPrev}>
            <div className={'row align-items-center justify-content-center'}>
                <ArrowLeft className={'col-auto'}/>
            </div>
        </button>
        <button type="button" className="btn btn-light px-3 py-2" disabled={!nextEnabled} onClick={onNext}>
            <div className={'row align-items-center justify-content-center'}>
                <ArrowRight className={'col-auto'}/>
            </div>
        </button>
    </div>);
}


function RenderRow({columns, item, onRowClick}) {
    let cls = onRowClick ? 'clickableRow' : '';
    return (
        <tr onClick={onRowClick ? onRowClick(item) : undefined} className={cls}>
            {
                columns.map((col, index) => {
                    if (col.render) {
                        return <td key={`${index}-${item.key}`}
                                   className={col.type === 'number' ? 'text-right' : ''}>{col.render(item[col.dataIndex], item)}</td>;
                    }
                    return <td key={`${index}-${item.key}`}
                               className={col.type === 'number' ? 'text-right' : ''}>{item[col.dataIndex]}</td>;

                })
            }
        </tr>
    );
}

BootStrapPagedTable.defaultProps = {
    per_page: 10
};

BootStrapPagedTable.propTypes = {
    per_page: PropTypes.number,
    dataSource: PropTypes.any.isRequired,
    columns: PropTypes.any.isRequired,
    bordered: PropTypes.bool,
    striped: PropTypes.bool,
    small: PropTypes.bool,
    loading: PropTypes.bool,

};

export default BootStrapPagedTable;
