import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import OAuthSignIn from './OAuthSignIn';
import {post} from '../../api';
import AuthContainer from './components/AuthContainer';
import LogoRender from '../cmp/LogoRender';
import StyledButton from '../cmp/themed/StyledButton';
import FormInput from './components/FormInput';
import FormattedHelmet from '../cmp/intl/FormattedHelmet';
import {FormattedMessage} from 'react-intl';
import RenderError from './components/RenderError';
import {connect} from 'react-redux';
import * as localStorage from '../../tools/storage';
import {getCurrentQueryObject} from "../../tools/func";
import RecaptchaRenderer from "../cmp/RecaptchaRenderer";

class LoginPage extends Component {

    state = {
        email: '',
        password: '',
        loading: false,
        errorCode: ''
    }

    onChange = (key) => (e) => {
        this.setState({[key]: e.target.value});
    }

    handleFormSubmit = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        const partner_portal_subdomain = document.location.host.startsWith('localhost') ? 'goaffprotest1.goaffpro.com' : document.location.host;
        const {email, password, recaptcha_response} = this.state;
        this.setState({loading: true, error: null});
        post('/login', {
            email, password,
            partner_portal_subdomain,
            recaptcha_response
        }).then((data) => {
            if (data.access_token) {
                localStorage.setItem('access_token', data.access_token);
                const {next} = getCurrentQueryObject();
                if (next) {
                    window.location = next;
                } else {
                    window.location = `/${this.props.isEmbedded ? '?embedded=1' : ''}`;
                }
            } else {
                const {code} = data;
                this.setState({code, loading: false});
            }
        }).catch((e) => {
            this.setState({loading: false});
        });
    }

    canSubmit = ()=>{
        if(this.props.recaptcha_enabled && !this.state.recaptcha_response){
           return false;
        }
        return this.state.email && this.state.password
    }

    render() {
        const {email, password, loading} = this.state;
        return (
            <AuthContainer>
                <FormattedHelmet id={'Login'}/>
                <div className={'text-center mb-4'}>
                    <LogoRender/>
                </div>
                <h1 className={"text_on_bg"}><FormattedMessage id={'Login'}/></h1>
                <div className={'card shadow-sm'}>
                    <div className={'card-body'}>
                        {
                            this.state.code && <RenderError code={this.state.code}/>
                        }
                        <form onSubmit={this.handleFormSubmit}>
                            <FormInput id={'emailAddress'}>
                                <input type={'email'} autoComplete={'email'} value={email} name={'email'}
                                       onInput={this.onChange('email')} className={'form-control'}/>
                            </FormInput>
                            <FormInput id={'password'}>
                                <input type={'password'} autoComplete={'current-password'} value={password}
                                       name={'password'} onInput={this.onChange('password')}
                                       className={'form-control'}/>
                            </FormInput>
                            <RecaptchaRenderer onChange={(e)=>this.setState({recaptcha_response: e.target.value})}/>
                            <div className={'row justify-content-between align-items-center'}>
                                <div className={'col'}>
                                    <StyledButton loading={loading} disabled={!this.canSubmit()} onClick={this.handleFormSubmit}                                                       className={'btn btn-primary'}><FormattedMessage id={'Login'}/></StyledButton>
                                </div>
                                <div className={'col-auto'}>
                                    <Link to={'/forgot-password'}>
                                        <FormattedMessage id={'forgotPassword'}/>
                                    </Link>
                                </div>
                            </div>
                        </form>

                        <OAuthSignIn/>

                    </div>
                </div>
                {
                    this.props.allow_partner_registrations ?
                    <div className={'mt-4 text-center text_on_bg'}>
                        <span><FormattedMessage id={'dontHaveAccount'}/></span>
                        <Link to={'/create-account'} className={'ml-1'}><FormattedMessage id={'createAccount'}/></Link>
                    </div> : null
                }
            </AuthContainer>
        );
    }
}

function mapStateToProps(state) {
    const {isEmbedded, recaptcha_enabled} = state.settings
    return {
        isEmbedded,
        allow_partner_registrations: state.settings.allow_partner_registrations,
        recaptcha_enabled,
    };
}

export default connect(mapStateToProps)(LoginPage);
