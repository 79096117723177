import React from 'react';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        isEmbedded: state.settings.isEmbedded
    };
}

export default connect(mapStateToProps)(({children, isEmbedded}) => {
    return (
        <div className={`d-flex justify-content-center align-items-center ${!isEmbedded && 'min-vh-100'}`}>
            <div className={`col-lg-4 col-md-6 ${!isEmbedded && ' mt-4 mb-4'}`}>
                {children}
            </div>
        </div>
    );
});
