import React, {Component} from 'react';
import Youtube from './Youtube';
import {apiServer} from '../../api';

class YoutubePlayListRender extends Component {

    state = {
        videoId: '',
        playlistItems: []
    }


    componentDidMount() {
        const {playlistId} = this.props;
        this.setState({loading: true});
        fetch(`${apiServer}/extras/fetch_youtube_playlist_details/${playlistId}`).then((data) => data.json()).then((data) => {
            this.setState({playlistItems: data, videoId: data.length > 0 && data[0].videoId, loading: false});
        }).catch(() => {
            this.setState({loading: false});
        });
    }


    setVideo = (videoId) => () => {
        this.setState({videoId});
    }

    render() {
        const {videoId} = this.state;
        if (this.state.playlistItems.length === 0) return null;
        return (
            <div>
                <div className="row no-gutters rounded" style={{backgroundColor: '#222', overflow: 'hidden'}}>
                    <div className="col-md-5" style={{overflowY: 'scroll'}}>
                        <ul className="list-group list-group-flush ">

                            {
                                this.state.playlistItems.map((item) => <RenderPlaylistItem {...item} key={item.videoId}
                                                                                           onClick={this.setVideo}/>)
                            }
                        </ul>

                    </div>
                    <div className="col-md-7">
                        <Youtube videoId={videoId}/>
                    </div>
                </div>
            </div>
        );
    }
}


function RenderPlaylistItem({thumbnail_uri, title, description, videoId, onClick}) {
    return (
        <div className={'d-flex p-2 align-items-center'} onClick={onClick(videoId)}
             style={{borderBottom: '1px solid #444'}}>
            <img src={thumbnail_uri} style={{width: 80, height: 60}}/>
            <div className={'mx-1'}>
                <div style={{color: 'white'}}>{title}</div>
            </div>
        </div>
    );
}

export default YoutubePlayListRender;
