import React, {Component} from 'react';
import Youtube from '../../../cmp/Youtube';

class YoutubeVideoComponent extends Component {
    render() {
        return (
            <div className="d-flex justify-content-center">
                <div className="col-lg-8">
                    <Youtube videoId={this.props.videoId}/>
                </div>
            </div>
        );
    }
}

export default YoutubeVideoComponent;
