import TextComponent from './TextComponent';
import HTMLComponent from './HTMLComponent';
import YoutubeVideoComponent from './YoutubeVideoComponent';
import YoutubePlaylistComponent from './YoutubePlaylistComponent';

export default {
    text: TextComponent,
    html: HTMLComponent,
    yt_video: YoutubeVideoComponent,
    yt_playlist: YoutubePlaylistComponent
};
