import React, {useEffect, useState} from 'react';
import {get} from '../../../api';
import CurrencyNumber from '../../cmp/CurrencyNumber';
import {FormattedDate, FormattedMessage, FormattedTime, useIntl} from 'react-intl';
import {connect} from "react-redux";
import AffiliateSalesLineItemsModalNew from "./AffiliateSalesLineItemsModalNew";
import Spinner from "../../cmp/Spinner";
import useInputState from "../../cmp/hooks/useInputState";
import FormGroup from "../../cmp/form/FormGroup";
import useDebounce from "../../cmp/hooks/useDebounce";
import Pagination from "../../cmp/StateBasedPagination";
import BootStrapTable from "../../cmp/BootStrapTable";

function AffiliateSalesDetails(props){
    const intl = useIntl()
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [limit, setLimit] = useState(Number(localStorage.getItem('affiliateSaleDetailsLimit') || 10));
    const [keyword, setKeyword] = useInputState();
    const [showCustomer, setShowCustomer] = useState(false)

    useEffect(()=>{
        localStorage.setItem('affiliateSaleDetailsLimit',String(limit))
    },[limit])

    const [total, setTotal] = useState(0);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [forceLoad, setForceLoad] = useState(0);

    const debouncedKeyword = useDebounce(keyword, 500);
    useEffect(()=>{
         setPageNumber(0);
         setForceLoad(forceLoad+1)
    }, [debouncedKeyword])

    useEffect(loadData, [pageNumber, limit, forceLoad])

    function loadData(){
        setLoading(true);
        get(`/sales`,{
            limit,
            keyword,
            offset: pageNumber * limit,
        }).then((data) => {
            setData(data.data);
            setTotal(data.total);
            setShowCustomer(data.show_customer)
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
        });
    }

    function startSearch(e){
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        setPageNumber(0);
        setForceLoad(forceLoad+1)
    }

    const showDetails = (item) => (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        console.log(item);
        setSelectedOrderId(item.order_id);
    }
    const {aff_show_pending_orders} = props;
    const columns = [
        {
            title: <FormattedMessage id={"Date"}/>,
            dataIndex: 'order_date',
            render: (order_date) => {
                return <span className={""}>
                    <span className={"mr-1"}>
                        <FormattedTime value={new Date(order_date)} minute={"2-digit"} hour12 hour={"2-digit"}/>,
                    </span>
                    <FormattedDate value={new Date(order_date)} month={"short"} day={"2-digit"}/>
                </span>
//					return fecha.format(new Date(order_date),'hh:mm a, DD MMM')
            }
        },
        {
            title: <FormattedMessage id={"Order"}/>,
            dataIndex: 'site_order_number',
            render: (site_order_number, item) => <span
                className={"text-primary text-monospace"}>{`${site_order_number}`}</span>
        },
        {
            title: <FormattedMessage id={"Amount"}/>,
            dataIndex: 'order_value',
            type: 'number',
            render: (order_value) => <span className={"text-monospace"}><CurrencyNumber
                value={Number(order_value)}/></span>
        },
        //Not shown as requested by glowb
        // {
        //     title: <FormattedMessage id={"Commission"}/>,
        //     dataIndex: 'affiliate_commission',
        //     type: 'number',
        //     render: (affiliate_commission) => <span className={"text-monospace"}>
        //         <CurrencyNumber value={Number(affiliate_commission)}/>
        //     </span>
        // }

    ];
    if (aff_show_pending_orders) {
        columns.push({
                title: <FormattedMessage id={"Status"}/>,
                dataIndex: 'status',
                render: (order_status) => {
                    switch (order_status) {
                        case "approved":
                            return <span className="badge badge-success">
                                <FormattedMessage id={"approved"}/>
                            </span>
                        case "new":
                            return <span className="badge badge-warning">
                                <FormattedMessage id={"pending"}/>
                            </span>
                        default:
                            return null
                    }
                }
            },
        )
    }
    if(showCustomer){
        columns.splice(1,0, {
                title: <FormattedMessage id={"Customer"}/>,
                dataIndex: 'customer_email',
                render: (customer_email, {customer_name}) => {
                    return <><div>
                        {customer_name}
                    </div>
                        <small className={"gdpr_sensitive_info"}>{customer_email}</small>
                    </>
                }
            },
        )
    }
    return (
        <div className={''}>
            <div className="row">
                    <div className="col-sm-6 col-md-8">
                        <h3 className="my-1">
                            {/*Changed title from details -> personal sales*/}
                            <FormattedMessage id={'Personal Sales'}/>
                        </h3>
                    </div>
                    <form onSubmit={startSearch} className="col-sm-6 col-md-4">
                        <FormGroup>
                            <input type="search" placeholder={intl.formatMessage({id:'Search'})} value={keyword} onChange={setKeyword} className="form-control"/>
                        </FormGroup>
                 </form>
            </div>
            <AffiliateSalesLineItemsModalNew order_id={selectedOrderId} onClose={()=>setSelectedOrderId(null)}/>
            {
                loading ? <div className="p-4 mb-4 border text-center">
                        <Spinner size={"lg"}/>
                    </div> :
                    <BootStrapTable
                        onRowClick={showDetails}
                        dataSource={data}
                        columns={columns}
                    />
            }
            <div className="d-flex justify-content-end">
                <Pagination
                    count={total}
                    goToPage={(p)=>setPageNumber(p)}
                    limit={limit}
                    setLimit={setLimit}
                    page={pageNumber}
                />
            </div>
        </div>
    );
}


function mapStateToProps(state) {
    const {aff_order_details_table_info} = state.settings;
    let show_product = true;
    let show_shipping = true;
    let show_customer = true;
    let show_fulfillment = state.settings.aff_show_order_fulfillment;
    if (aff_order_details_table_info) {
        const opts = JSON.stringify(aff_order_details_table_info);
        show_product = opts.indexOf('product') > -1
        show_shipping = opts.indexOf('shipping') > -1;
        show_customer = opts.indexOf('customer') > -1
        show_fulfillment = opts.indexOf('fulfillment') > -1
    }

    return {
        created: state.settings.created,
        aff_show_pending_orders: state.settings.aff_show_pending_orders,
        show_product, show_customer, show_shipping, show_fulfillment
    }
}


export default connect(mapStateToProps)(AffiliateSalesDetails);
