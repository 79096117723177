import {getCurrentQueryObject} from '../../tools/func';
import * as localStorage from '../../tools/storage';

function isEmbedded(){
	const { embedded } = getCurrentQueryObject();
	return embedded;
}


function isShopifyAutoLogin(){
	const {is_shopify_login} = getCurrentQueryObject();
	return String(is_shopify_login) === "1"
}


const defaultSettings = {
	access_token: localStorage.getItem('access_token'),
	name: '',
	email: '',
	instagram: '',
	website: '',
	twitter: '',

	default_currency: '',
	payment_method: '',
	payment_details_data: '{}',
	ref_code: '',
	store_logo: null,
	locale: 'en',
	themePrimaryColor: '#2780E3',

	authGoogle: false,
	authFacebook: false,
	signupName: true,
	signupTwitter: false,
	signupInsta: true,
	signupWebsite: false,
	show_commission_on_landing: true,
	isEmbedded: isEmbedded(),
	isShopifyAutoLogin: isShopifyAutoLogin(),
};

export default (state = defaultSettings, action) => {
	switch (action.type) {
		case 'merge-settings':
			//console.log('merging', state, action.settings);
			return { ...state, ...action.settings };
		case "set-commissions":
			const {commissions} = action
			return {...state,
				mlm_enabled: commissions && commissions.mlm && commissions.mlm.standard && commissions.mlm.standard.length > 0

			}
		default:
			return state;
	}
};
