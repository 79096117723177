import React, {useEffect, useMemo, useState} from 'react';
import CopyButton from '../cmp/CopyButton';
import {Navigation, Pagination, Scrollbar} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react';
import CurrentBonusProgressBar from './glowb/CurrentBonusProgressBar';
import CurrentLeaderboard from './glowb/CurrentLeaderboard';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import {useSelector} from 'react-redux';
import useMediaQuery from '../cmp/hooks/useMediaQuery';
import AffiliateSalesDetails from "./home/AffiliateSalesDetails";
function useRefLink(u){
    const {ref_code, website} = useSelector((state)=>state.settings)
    const [url, setUrl] = useState(u)
    const link = useMemo(()=>{
        const u = url || website;
        if(!u || !ref_code) return null;
        const ur = new URL(u.startsWith("http") ? u : `https://${u}`);
        ur.searchParams.append('ref',ref_code)
        return ur.href
    },[website, url, ref_code])
   return [link, setUrl]
}


const Home = () => {
    const {name, affiliate_id, signup_data, website} = useSelector((state)=>state.settings)
    const profileURL = useMemo(()=>{
        if(signup_data){
            const obj = JSON.parse(signup_data)
            return obj && obj.profile_photo && obj.profile_photo.value && obj.profile_photo.value.url
        }
    },[signup_data])
    const isMobile = useMediaQuery('(max-width: 576px)');

   
    return <main style={{backgroundColor: "#FDE7EA"}}>
        <section className={"row"}>
            <div className={"col-md-6 d-flex align-items-start"}>
                {
                    profileURL ?
                    <img src={profileURL} alt={"User Profile"}
                         className={"border mr-2"}
                         style={{overflow:'hidden', borderRadius:36, objectFit:'cover' }} width={66} height={66}/> : null
                }

                <div>
                    <h2 className={"mb-0"}>{name}</h2>
                    <p className={"m-0"}>ID: #{affiliate_id}</p>
                </div>
            </div>
            <div className="col-md-6 d-flex align-items-end justify-content-md-end">
            <p className={"border p-2 pl-3"} style={{width:isMobile ? '100%' : 'auto', backgroundColor: "#FCC7D1"}}>
                Follow us
                <SocialMediaLink route={'https://glowb.shop/'} image={'/glowb-images/glowb-dribbble.jpeg'} className={"ml-3 mr-2"} />
                <SocialMediaLink route={'https://www.instagram.com/glowb.shop/'} image={'/glowb-images/glowb-insta.jpeg'} className={"mr-2"} />
                <SocialMediaLink route={'https://www.facebook.com/Glowbshop-111279211329639'} image={'/glowb-images/glowb-fb.jpeg'} className={"mr-2"} />
                <SocialMediaLink route={'https://www.youtube.com/channel/UCUmypqxxzVcEsW0mZ1tx0rQ'} image={'/glowb-images/glowb-yt.jpeg'} />
            </p>
            </div>
        </section>
        {
            isMobile ? <section>
                    <div className="mb-2">
                        <RenderWelcomeOffer />
                        <ImageLink href={`https://${website}/collections/skincare`} src={'/glowb-images/skincare-widget.jpg'}
                                   alt={"Clearance Sale"}
                                   className={"w-100 mt-3"}/>
                    </div>
                    <div className="mb-2">
                        <CardLayout heading={"My Bonus Progress"}
                                    bgColor={"#FCC7D1"}>
                            <CurrentBonusProgressBar/>
                        </CardLayout>
                    </div>
                <div className="mb-2">
                    <DiscountCodeCard />
                </div>
                <div className="mb-2">
                    <RefLinkCard />
                </div>
                <div className="mb-2">
                    <CardLayout heading={"Product Link"}
                                bgColor={"#FCC7D1"}>
                        <RenderProducts/>
                    </CardLayout>
                    <ImageLink href={'https://www.brandox.com/glowb'} src={"/glowb-images/get content.jpg"} alt={"Get Content"} className={"w-100 mt-3"} />
                    <ImageLink href={'https://vimeo.com/user/123302654/folder/7756180'} src={"/glowb-images/start training11.jpg"} alt={"Start your training"} className={"w-100 mt-3"} />
                </div>

                <div className="mb-2">
                    <CardLayout heading={"Leaderboard"} bgColor={"#FCC7D1"}>
                        <CurrentLeaderboard/>
                    </CardLayout>
                </div>

                <CardLayout bgColor={"#FCC7D1"}>
                    <AffiliateSalesDetails />
                </CardLayout>
            </section> :
                <section className={"row"}>
                    <div className={"col-12 col-lg-6"}>
                        <CardLayout heading={"My Bonus Progress"}
                                    bgColor={"#FCC7D1"}>
                            <CurrentBonusProgressBar/>
                        </CardLayout>
                        <RefLinkCard />
                        <DiscountCodeCard />

                        <CardLayout heading={"Leaderboard"} bgColor={"#FCC7D1"}>
                            <CurrentLeaderboard/>
                        </CardLayout>

                        <CardLayout bgColor={"#FCC7D1"}>
                            <AffiliateSalesDetails />
                        </CardLayout>
                    </div>

                    <div className={"col-12 col-lg-6"}>
                        <RenderWelcomeOffer />
                        <ImageLink href={`https://${website}/collections/skincare`} src={'/glowb-images/skincare-widget.jpg'}
                                   alt={"Clearance Sale"}
                                   className={"w-100 mt-3"}/>
                        <CardLayout heading={"Product Link"}
                                    bgColor={"#FCC7D1"}>
                            <RenderProducts/>
                        </CardLayout>
                        <ImageLink href={'https://www.brandox.com/glowb'} src={"/glowb-images/get content.jpg"} alt={"Get Content"} className={"w-100 mt-3"} />
                        <ImageLink href={'https://vimeo.com/user/123302654/folder/7756180'} src={"/glowb-images/start training11.jpg"} alt={"Start your training"} className={"w-100 mt-3"} />
                    </div>
                </section>
        }
    </main>
}

function RenderWelcomeOffer(){
    const [welcomeCode, setWelcomeCode] = useState(null)
    const {ref_code, my_coupons, created} = useSelector((state)=>state.settings)
    const [tried, setTried] = useState(false);
    useEffect(()=>{
        console.log({tried, welcomeCode})
        if(tried) return;
        if(welcomeCode) return;
        const canUseWelcomeOffer = created ? Date.now() - 5 * 24 * 60 * 60 * 1000 < new Date(created).getTime() : false;
        console.log({canUseWelcomeOffer, ref_code, my_coupons})
        if(!canUseWelcomeOffer || !ref_code || !my_coupons) return;
        const found = my_coupons.find(({code, coupon})=>(code||coupon).startsWith("WELCOME9"));
        console.log({my_coupons, found})
        if(!found){
            console.log('not found', my_coupons)
            fetch('https://qv3kx4cvw4.execute-api.eu-central-1.amazonaws.com/default/directscale-goaffpro-v2?username='+ref_code)
            .then((data)=>data.json())
            .then(({suffix})=>setWelcomeCode('WELCOME9'+suffix))
            .then(()=>setTried(true))
        }else{
            setWelcomeCode(found.code || found.coupon)
        }
    }, [my_coupons, ref_code, created, tried, welcomeCode])

    if(!welcomeCode) return null;
    const canUseWelcomeOffer = created ?  Date.now() - 5 * 24 * 60 * 60 * 1000 < new Date(created).getTime() : false;
    if(!canUseWelcomeOffer) return null;
    return <ImageLink href={`https://glowb.shop/cart/41618373181608:1?ref=${ref_code}&discount=${welcomeCode}&currency=GBP&access_token=03ac709b3fc672b83f8727935d3d2d55`}
    src={'/glowb-images/gonglowcoll.jpg'}
    alt={"welcome-offer"}
    className={"w-100 mt-3"}/>
}



const ImageLink = ({ href, className, src, alt, style }) => {
    return <a href={href} target="_blank" rel="noopener noreferrer">
        <img src={src} className={className} alt={alt} style={style} />
    </a>
}

const SocialMediaLink = ({ route, image, className }) => {
    return <a target={"_blank"} rel="noreferrer nofollow" href={route}>
        <img src={image} alt={"Social Media Link"} className={`rounded ${className}`} width={24} height={24} />
    </a>
}

const CardLayout = ({ heading, children, bgColor, cardBodyClassName }) => {
    return <div className={"card mt-3 border-0"} style={{backgroundColor: bgColor}}>
        <div className={`card-body ${cardBodyClassName}`}>
            {heading ? <h4 className={"text-center text-white"}>{heading}</h4> : null}
            {children}
        </div>
    </div>
}

function DiscountCodeCard(){
    const {my_coupons} = useSelector((state)=>state.settings)

    return  <CardLayout heading={"My Discount Code"}
                        bgColor={"#FCC7D1"}>
        <div className={"text-center"}>
            {
                my_coupons.filter(
                    (item) => !item.coupon.startsWith(
                        'WELCOM')).map(({coupon}) => {
                    return <div>
                        <p className={"m-0"}>{coupon}</p>
                        <CopyButton text={coupon}
                                    className={"text-white shadow-sm rounded mt-1 py-0 px-4"}
                                    style={{backgroundColor: "#FE91B0"}}/>
                    </div>
                })
            }
        </div>
    </CardLayout>
}

function RefLinkCard(){
    const [refLink] = useRefLink()
    const [networkLink] = useRefLink(new URL('/create-account',document.location.href).href)

    return     <CardLayout heading={"My Referral links"}
                           bgColor={"#FCC7D1"}>
        <div
            className={"row"}>
            <div className={"col-md-6 text-center mb-2"}>
                <p>My shop link</p>
                <small className={"d-block"}>{refLink}</small>
                <CopyButton text={refLink}
                            className={"text-white shadow-sm rounded mt-1 py-0 px-4"}
                            style={{backgroundColor: "#FE91B0"}}/>
            </div>

            <div className={"col-md-6 text-center mb-2"}>
                <p>My team link</p>
                <small className={"d-block"}>{networkLink}</small>
                <CopyButton text={networkLink}
                            className={"text-white shadow-sm rounded mt-1 py-0 px-4"}
                            style={{backgroundColor: "#FE91B0"}}/>
            </div>
        </div>
    </CardLayout>
}

function getImageForProduct(name, handle){
    const prefix = '/glowb-images/products/'
    if(handle === "not-another-pink-mask-1") return prefix +"mask.png"
    if(handle === "not-another-pink-mask") return prefix +"maskplastic.png"
    if(handle === "shes-got-guts") return prefix+"sgg.png";
    if(handle === "hyaluronic-chill") return prefix+"hyaluron plastic.png";
    if(handle === "glow-hero") return prefix + "glow plast.png";
    if(handle === "glisten-up") return prefix + "glisten plastic.png"
    if(handle === 'freaky-clean-2') return prefix+'freakyclean.png';
    if(name.includes('Queen & She')) return prefix+'2sgg3queen.png'
    if(name.includes('3x Freaky')) return prefix+'3freky.png';
    if(name.includes('Glisten')) return prefix+'glisten.png'
    if(name.includes('Queen')) return prefix+'3queen.png';
    if(name.includes('Got Guts')) return prefix+'3sgg.png';
    if(name.includes('Bundle')) return prefix+'bundle.png';

    if(name.includes('Freaky Clean')) return prefix+'freakyplastic.png';
    if(name.includes('Dirtier')) return prefix+'dirtier.png';
    if(name.includes('Triple Beauty')) return prefix+'3queen.png';
    if(name.includes('Glow Hero')) return prefix+'glow.png';
    if(name.includes('Hyaluronic & Glow')) return prefix+'hyal glow.png';
    if(name.includes('Hyaluronic + Chill')) return prefix+'hyaluronic.png';
//    if(name.includes('Hydrating Misc')) return prefix+'hyaluronic.png';

}

function RenderProducts(){
    const [products, setProducts] = useState([])
    const {website, ref_code} = useSelector((state)=>state.settings)
    useEffect(()=>{
       fetch('/glowb-products-trimmed.json').then((data)=>data.json())
           .then((d)=>setProducts(d))
    },[])

    return <>
        <Swiper className={"py-2"}
                modules={[Navigation, Pagination, Scrollbar]}
                pagination={{clickable: true, dynamicBullets: true}}
                navigation={true}
                scrollbar={{draggable: true}} spaceBetween={50}>
        {
            products.map((item)=>{
                return <SwiperSlide>
                    <div className="text-center pb-4">
                        <img src={getImageForProduct(item.title, item.handle) || item.src} alt="" style={{height:300}}/>
                        <h5>{item.title}</h5>
                        <CopyButton title={"Copy link"} className={"text-white shadow-sm rounded mt-1 px-4 py-1"} style={{backgroundColor: "#FE91B0"}} text={`https://${website}/products/${item.handle}?ref=${ref_code}`}/>
                    </div>
                </SwiperSlide>
            })
        }
        </Swiper>
    </>
}
export default Home
