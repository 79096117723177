import React, {Component} from 'react';
import {post} from '../../api';
import StyledButton from '../cmp/themed/StyledButton';
import StyledProgressLoader from '../cmp/themed/StyledProgressLoader';
import {FormattedMessage} from 'react-intl';
import FormattedHelmet from '../cmp/intl/FormattedHelmet';
import {connect} from 'react-redux';
import Label from '../auth/components/Label';
import Spinner from "../cmp/Spinner";

class ContactUs extends Component {

    state = {
        message: '',
        queryType: 'General',
        success: false,
        loading: false,
        name: '',
        email: ''
    }

    onInput = (key) => (e) => {
        this.setState({[key]: e.target.value});
    }

    onSubmit = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        const {queryType, message, loading, name, email} = this.state;
        if (!this.submitEnabled()) return;
        if (loading) return;
        this.setState({loading: true, success: false, error: false});
        post('/partner-contact', {
            message, queryType, name, email
        }).then((data) => {
            if (data.success) {
                this.setState({success: true, message: '', name: '', email: ''});
            }
            if (data.error) {
                this.setState({error: data.error});
            }
            this.setState({loading: false});
        }).catch(() => {
            this.setState({loading: false});
        });
    }

    emailValidator = new RegExp(/\S+@\S+\.\S+/);

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.contactPageRedirect();
    }

    componentDidMount() {
        this.contactPageRedirect();
    }

    contactPageRedirect = () => {
        if (this.props.contact_page_url) {
            window.location = this.props.contact_page_url;
        }
    }


    submitEnabled = () => {
        const {name, email, message} = this.state;
        if (this.props.affiliate_id) {
            return message && message.length > 0;
        }
        return name && email && message && message.length > 0 && this.emailValidator.test(email);

    }

    render() {
        if (this.props.contact_page_url) {
            return <div className="container d-flex align-items-center justify-content-center" style={{minHeight: 400}}>
                <Spinner size={'lg'}/>
            </div>;
        }
        const {name, email, message, loading, success} = this.state;
        return (
            <div className={'mt-4 d-flex justify-content-center'}>
                <FormattedHelmet id={'contactUs'}/>
                <div className={'col-md-6'}>
                    <h1 className={'text-center'}>
                        <FormattedMessage id={'contactUs'}/>
                    </h1>
                    <div className={'card'}>
                        <form className={'card-body'} onSubmit={this.onSubmit}>
                            <FormattedMessage id={'pageDesc'} tagName={'p'}/> {
                            success ?
                                <p className={'alert alert-success'}>
                                    <FormattedMessage id={'messageSentSuccess'}/>
                                </p> : null
                        }
                            {
                                this.state.error && <p className="alert alert-danger">{this.state.error}</p>
                            }
                            {
                                !this.props.affiliate_id && <div>
                                    <div className="form-group">
                                        <Label required><FormattedMessage id={'yourName'}/></Label>
                                        <input type="text" name={'name'} value={name} autoComplete={'name'}
                                               onInput={this.onInput('name')} className="form-control"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <Label required><FormattedMessage id={'emailAddress'}/></Label>
                                        <input type="text" name={'email'} value={email} autoComplete={'email'}
                                               onInput={this.onInput('email')} className="form-control"
                                        />
                                    </div>
                                </div>
                            }
                            <div className={'form-group'}>
                                <label htmlFor="queryType"><FormattedMessage id={'issueTypeLabel'}/></label>
                                <select className={'form-control'} value={this.state.queryType}
                                        onChange={this.onInput('queryType')}>
                                    <Opt value={'General'} id={'issueTypeGeneral'}/>
                                    <Opt value={'Technical'} id={'issueTypeTechnical'}/>
                                    <Opt value={'Feedback'} id={'issueTypeFeedback'}/>
                                    <Opt value={'Payment'} id={'issueTypePayment'}/>
                                    <Opt value={'Account Deletion Request'} id={'issueTypeDelAcct'}/>
                                </select>
                            </div>
                            <div className={'form-group'}>
                                <Label required><FormattedMessage id={'yourMessage'}/></Label>
                                <textarea className={'form-control'} value={message} onInput={this.onInput('message')}/>
                            </div>
                            <div className="d-flex justify-content-between">

                                {
                                    loading ? <StyledProgressLoader/> :
                                        <StyledButton
                                            className={'btn btn-primary'}
                                            onClick={this.onSubmit}
                                            disabled={!this.submitEnabled()}
                                        >
                                            <FormattedMessage id={'sendMessage'}/>
                                        </StyledButton>
                                }
                                <small className={'text-danger'}>* - <FormattedMessage id={'required'}/></small>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function Opt({value, id}) {
    return <FormattedMessage id={id}>{txt => <option value={value}>{txt}</option>}</FormattedMessage>
}

function mapStateToProps(state) {
    return {
        affiliate_id: state.settings.affiliate_id,
        contact_page_url: state.settings.contact_page_url
    };
}

export default connect(mapStateToProps)(ContactUs);
