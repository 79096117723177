import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import CurrencyNumber from "../../cmp/CurrencyNumber";
import {get} from "../../../api";
import Spinner from "../../cmp/Spinner";

class CouponPage extends Component {

    state = {

        discount_value: 20,

        discount_type: 'percentage',

        coupon: 'ABC',

        affiliate_name: 'Shatava',

    }

    componentDidMount() {
        this.getCouponData();
    }
    getCouponData = () => {

        const {coupon} = this.props;

        this.setState({loading: true});

        get('/coupon_details?coupon=' + coupon)

            .then((data) => {

                this.setState({...data, loading: false});

            }).catch(() => {

            this.setState({loading: false, error: "Network Error"})

        })

    }



    render() {

        const {discount_type, discount_value, affiliate_name, loading, error} = this.state;

        if (loading) {

            return <div className={"bg-light min-vh-100"}>

                <div className={"text-center"} style={{paddingTop: 100}}>

                    <Spinner size={"lg"}/>

                </div>

            </div>

        }

        if (error) {

            return <div className={"bg-light min-vh-100"}>

                <div className={"text-center"} style={{paddingTop: 100}}>

                    <p className={"alert alert-danger"}>{error}</p>

                </div>

            </div>

        }

        const {coupon, store_name, website} = this.props

        let discount = discount_type === "percentage" ? discount_value + "%" : <CurrencyNumber value={discount_value}/>

        return (

            <div className={"bg-light min-vh-100"}>

                <div className={"row"} style={{paddingTop: 100}}>

                    <div className={"col-lg-4"}/>

                    <div className={"col-lg-4"}>

                        <div className="card shadow-lg">

                            <div className="card-body">

                                <div className={"text-center"}>

                                    <img src={"/images/gift.svg"} alt={"COUPON"} className={"mb-2"} width={"50%"}

                                         height={"50%"}/>

                                    <h2>

                                        <FormattedMessage id={"discount_page_heading"}

                                                          defaultMessage={"Here is your {discount} OFF"} values={{

                                            discount

                                        }}/>

                                    </h2>

                                    <p className={"text-muted"}>

                                        <FormattedMessage id={"discount_page_text"}

                                                          defaultMessage={"{affiliate_name} thinks you'll love {store_name}, so here's {discount} off!"}

                                                          values={{

                                                              store_name: store_name,

                                                              affiliate_name: affiliate_name,

                                                              discount

                                                          }}



                                        />

                                    </p>

                                    <input type="text"

                                           className="form-control form-control-lg mt-4 text-center text-uppercase"

                                           value={coupon}/>

                                    <a target={"_blank"} rel={"noopener nofollow"}

                                       href={`https://${website}/discount/${coupon}`}

                                       className={"btn btn-primary btn-lg"} style={{width: '100%'}}>

                                        <FormattedMessage id={"SHOP NOW"}/>

                                    </a>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className={"col-lg-4"}/>

                </div>

            </div>

        );

    }

}



function mapStateToProps(state, props) {

    return {

        themePrimaryColor: state.settings.themePrimaryColor,

        store_name: state.settings.store_name,

        website: state.settings.website,

        coupon: props.match.params.coupon

    }

}

export default connect(mapStateToProps)(CouponPage);
