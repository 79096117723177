import React from 'react';
import {connect} from 'react-redux';
import {buildRefLink} from "../../../../aff-client-commons/tools/functions";
import InnerHTML from 'dangerously-set-html-content'
function HTMLComponent({html}){
    return <InnerHTML html={html}/>
}

const reg2 = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/gm;

function mapStateToProps(state, props) {
    const {coupon, name, email, ref_code, website, partner_portal_subdomain, ref_identifiers, referral_link_params} = state.settings;
    let ident = 'ref';
    try{
        ident = typeof ref_identifiers === 'string' ? JSON.parse(ref_identifiers) : ref_identifiers;
    }catch(e){
    }
    let html = props.html || '';
    html = html.replace(/\{coupon\}/g, coupon);
    html = html.replace(/\{name\}/g, name);
    html = html.replace(/\{email\}/g, email);
    html = html.replace(/\{ref_code\}/g, ref_code);
    html = html.replace(reg2, (x) => {
        try {
            const url = new URL(x);
            if (url) {
                //there is problem with embedded facebook videos, so only append ref if the hostname contains website.
                if (website.includes(url.hostname)) {
                    const primary_identifier = ident && ident.length > 0 ? ident[0] : undefined;
                    if(primary_identifier){
                        let exists = primary_identifier === "hash" ? url.hash : url.searchParams.get(primary_identifier);
                        if(!exists){
                            return buildRefLink(ref_code, url.href, ref_identifiers, referral_link_params, state.settings)
                        }
                    }
                }
                return url.href;
            }
        } catch (e) {

        }
        return x;
    });

    return {
        html
    };
}

export default connect(mapStateToProps)(HTMLComponent);
