import React, {Component} from 'react';
import SignatureInput from "../form/SignatureInput";
import CountrySelector from "../../auth/components/CountrySelector";
import Option from "../form/Option";
import LazyMultipleSelector from "../multiple-selector/LazyMultipleSelector";
import {FormattedMessage} from "react-intl";
import Checkbox from "../form/Checkbox";
import RadioGroup from "../form/RadioGroup";
import LegalDocModal from "../../auth/signup/LegalDocModal";
import LazyPhoneInput from "../form/phone/LazyPhoneInput";
import SimpleFileUpload from "../SimpleFileUpload";
import Label from "../../auth/components/Label";
import MaskedInput from "../form/MaskedInput";
import LazyDatePicker from "../flatpickr/LazyDatePicker";
import moment from "moment";
import SimpleDateInput from "../../SimpleDateInput";

class RenderFormInput extends Component {
    keys = ['isRequired', 'label','value','placeholder','id','prepend','type','size','options','onChange','inputMask']

    shouldComponentUpdate(nextProps){
        return !!this.keys.find((key)=>{
            return this.props[key] !== nextProps[key]
        })
    }

    onChange = (id)=>e=>{
        this.props.onChange(id)(e)
    }

    render() {

        const { isRequired, label, value, placeholder, info, id, prepend, append, type, size, options, inputMask } = this.props;
        const onChange = this.onChange(id)
        let inputCmp = null;
        switch (type) {
            case "signature":
                inputCmp = <SignatureInput onChange={onChange} value={value}/>
                break;
            case 'textarea':
                inputCmp = (<textarea id={id} name={fieldNameMapper[id] || label}
                                      value={value} onInput={onChange}
                                      className={'form-control'}
                />);
                break;
            case 'country':
                inputCmp = (
                    <CountrySelector value={value} onChange={onChange}/>
                );
                break;
            case 'select':
                const opts = typeof options === "string" ?
                    options.split('\n').map((item) => item.trim()).filter((i) => i)
                        .map((item) => <Option id={item} value={item}/>)
                    : options.map((item) => <Option id={item.label} value={item.value}/>)
                inputCmp = (
                    options &&
                    <select className={'form-control'} value={value} id={id} name={label} onChange={onChange}>
                        <option/>
                        {opts}
                    </select>
                );
                break;
            case "multiple-select":
                const ar = value ? typeof value === "string" ? value.split(",") : value : [];
                const va = ar.map((val) => {
                    return {value: val, label: val}
                });
                inputCmp = (
                    options &&
                    <LazyMultipleSelector options={options.split('\n').map((item) => item.trim()).filter((i) => i)
                        .map((item) => {
                            return {
                                label: item,
                                value: item,
                            }
                        })} value={va} onSelected={(val) => onChange({target: {value: val.map(({value}) => value)}})}/>
                )
                break;
            case "fileupload":
                let inputProps = {};
                if (id === "profile_photo") {
                    inputProps = {
                        accept: 'image/jpg, image/png, image/jpeg'
                    }
                }
                inputCmp = <SimpleFileUpload inputProps={inputProps} {...value || {}} imgWidth={200}
                                             onChange={(data) => onChange({target: {value: data}})}/>
                break;
            case 'radio':
                inputCmp = (options &&
                    <RadioGroup options={options.split('\n').map((item) => item.trim()).filter((i) => i)}
                                onChange={onChange} checked={value} name={label}
                    />
                );
                break;
            case 'checkbox':
                return <div className={`form-group col-${12 / (size || 1)}`}>
                    <Checkbox label={<FormattedMessage id={label} defaultMessage={label} values={
                        {
                            terms: text => <LegalDocModal to={"/program-legal/terms"}>{text}</LegalDocModal>,
                            privacy: text => <LegalDocModal to={"/program-legal/privacy"}>{text}</LegalDocModal>
                        }
                    }/>} checked={value} value={label} onChange={(e) => onChange({target: {value: e.target.checked}})}/>
                </div>;
            case "phone":
                inputCmp = <LazyPhoneInput onChange={onChange} value={value}/>
                break;
            case "date":
                const val = new Date(value).toString() !== "Invalid Date" ? moment(value).format('YYYY-MM-DD') : value
                inputCmp = (<SimpleDateInput placeholder={placeholder} id={id} name={fieldNameMapper[id] || label} value={val} onChange={onChange} />);
                break;
            default:
                if (id === "phone") {
                    inputCmp = <LazyPhoneInput onChange={onChange} value={value}/>
                } else if(id==="date_of_birth"){
                    inputCmp = <LazyDatePicker config={{dateFormat:"d F, Y"}} onChange={(date)=>{
                        onChange({target:{value: moment(date[0]).format('YYYY-MM-DD')}})
                    }} date={value} />
                } else{
                    inputCmp = (<MaskedInput type={type || inputTypeMapper[id] || 'text'} id={id} name={fieldNameMapper[id] || label}
                                             value={value} onChange={onChange} autoComplete={autofillFieldMapper[id] || 'on'}
                                             mask={inputMask}
                    />);
                }
        }
        return (<div className={`form-group col-${12 / (size || 1)}`}>
            {
                label &&
                <Label required={isRequired}><FormattedMessage id={label} defaultMessage={label}/></Label>
            }
            {
                (prepend || append) ?
                    <div className="input-group">
                        {
                            prepend &&
                            <div className="input-group-prepend">
                                <span className="input-group-text">{prepend}</span>
                            </div>
                        }
                        {
                            inputCmp
                        }
                        {
                            append &&
                            <div className="input-group-append">
                                <span className="input-group-text">{append}</span>
                            </div>
                        }
                    </div> : inputCmp

            }
            {
                info && <small className="text-muted" dangerouslySetInnerHTML={{__html:info}} />
            }
        </div>);
    }
}
const autofillFieldMapper = {
    name: 'name',
    fname: 'given-name',
    phone: 'tel',
    address: 'address',
    address_1: 'address-line1',
    address_2: 'address-line2',
    lname: 'family-name',
    email: 'email',
    password: 'new-password',
    state: 'address-level1',
    city: 'address-level2',
    zip: 'postal-code',
    country: 'country'
};


const inputTypeMapper = {
    email: 'email',
    phone: 'tel',
    paypal: 'email',
};
const fieldNameMapper = {
    name: 'name',
    fname: 'fname',
    lname: 'lname',
    phone: 'phone',
    email: 'email',
    address_1: 'address',
    address: 'address',
    city: 'city',
    state: 'state',
    country: 'country',
    zip: 'zip'
};
export default RenderFormInput;
