import React from 'react';
import StyledProgressLoader from './themed/StyledProgressLoader';

function LazyLoadingBar(props) {
    if (props.error) {
        return <div>Error! <button onClick={props.retry}>Retry</button></div>;
    } else if (props.pastDelay) {
        return <StyledProgressLoader/>;
    }
    return null;

}

export default LazyLoadingBar;
