import React from "react";
import {connect} from "react-redux";

function MobileAppsFooter({aff_show_mobile_apps}) {
    if (!aff_show_mobile_apps) return null;

    return <div className="text-center">
        <a href={"https://play.google.com/store/apps/details?id=com.goaffpro.app"} target={"_blank"}>
            <img src={"/images/Get_it_on_Google_play.svg"} alt={"Download from Google play"}/>
        </a>
        <div className="d-none d-sm-inline">
            <span className={"px-2"}/>
        </div>
        <div className="d-sm-none d-block">
            <div className="py-1"/>
        </div>
        <a href={"https://apps.apple.com/us/app/pro-affiliates/id1489316147?ls=1"} target={"_blank"}>
            <img src={"/images/Download_on_the_App_Store_Badge.svg"} alt={"Download from App Store"}/>
        </a>
    </div>
}

function mapStateToProps(state) {
    return {
        aff_show_mobile_apps: state.settings.aff_show_mobile_apps
    }
}

export default connect(mapStateToProps)(MobileAppsFooter);
