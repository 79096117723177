import prettyBytes from "pretty-bytes";
import {FormattedMessage} from "react-intl";
import CopyButton from "../../cmp/CopyButton";
import React from "react";
import {connect} from "react-redux";
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import {facebookShare, openShareWindow, pinterestShare, twitterShare, whatsAppShare} from "../../../tools/func";
import {ThreeDotsHorizontalIcon} from "../../../icons/Icons";
import {
    buildRefLink,
    isFile,
    isHtml,
    isImage,
    isVideo,
    mediaDownloadUrl,
    updateMediaDescriptionWithRefcode
} from '../../../aff-client-commons/tools/functions';
import LazyVideoLoader from "../../cmp/video/LazyVideoLoader";

class CreativeItem extends React.PureComponent {

    downloadHtml = () => {
        const {media_url, media_description, ref_code, ref_identifiers} = this.props;
        let link = '';
        const product_link = this.getProductLink()
        let l = undefined;
        if (product_link) {
            l = buildRefLink(ref_code, product_link, ref_identifiers)
            if (l) {
                link = `<a href="${l}" rel="sponsored" target="_blank">${product_link}</a>`;
            }
        }
        const desc = updateMediaDescriptionWithRefcode(media_description, ref_code) || '';
        let img = `<img src="${media_url}" alt="${desc}" />`
        if (l) {
            img = `<a href="${l}" rel="sponsored" target="_blank">${img}</a>`
        }
        return `<div>
                ${img}
                <p>${desc}</p>
                ${link}
            </div>`
    }


    text = () => {
        return updateMediaDescriptionWithRefcode(this.props.media_description, this.props.ref_code) || '';
    }

    imageDownloadLink = () => {
        return this.props.media_url + "?download=1"
    }

    getProductLink = () => {
        const {product_link, ref_code, website,ref_identifiers, referral_link_params, settings} = this.props;
        if (product_link || website) {
            try {
                return buildRefLink(ref_code, product_link || website, ref_identifiers, referral_link_params, settings)
            }catch (e){
                return buildRefLink(ref_code, website, ref_identifiers, referral_link_params, settings)
            }
        } else {
            return product_link;
        }
    }

    getProductDescription = () => {
        const {media_description, product_link, media_url, ref_code} = this.props;
        let desc = updateMediaDescriptionWithRefcode(media_description, ref_code) || '';
        if (product_link) {
            desc += " \n\n" + media_url;
        }
        return desc;
    }

    creativeLink = ()=> {
        const { ref_code, ref_identifiers, media_id, referral_link_params, settings} = this.props;
        return buildRefLink(ref_code, "https://goaff.pro/c:" + media_id, ref_identifiers, referral_link_params, settings)
    }

    shareOnFacebook = () => {
        const {media_url,media_description, media_id} = this.props;
        const desc = this.getProductDescription();
        const product_link = this.getProductLink();
        if(product_link || media_description){
            openShareWindow(facebookShare(this.creativeLink(), media_description, "facebook","width=500, height=500"))
        }else {
            openShareWindow(facebookShare(product_link || media_url, desc), "facebook", "width=500, height=500")
        }
    }
    shareOnTwitter = () => {
        const {media_url} = this.props;
        const desc = this.getProductDescription();
        const product_link = this.getProductLink();
        openShareWindow(twitterShare(product_link || media_url, desc), 'twitter', 'width=600,height=300');
    }
    shareOnPinterest = () => {
        const {media_url} = this.props;
        const desc = this.getProductDescription();
        const product_link = this.getProductLink();
        openShareWindow(pinterestShare(product_link || media_url, desc), "pinterest", "width=600,height=300")
    }
    shareOnWhatsApp = () => {
        const {media_url} = this.props;
        const desc = this.getProductDescription();
        const product_link = this.getProductLink();

        window.location = whatsAppShare(product_link || media_url, desc)
    }

    copyText = () => {
        const desc = this.getProductDescription();
        const link = this.getProductLink();
        return link + "\n\n" + desc;
    }

    render() {
        const {
            media_thumb, filename, media_url, width, height, size,
            media_description, ref_code, product_link,
            media_id,
            ref_identifiers, referral_link_params,
            settings
        } = this.props;
        const u = new URL(media_url);
        u.pathname = "/thumbnail" + u.pathname
        let thumbnailUri = media_thumb || u.href;
        return (<div className={'card'}>
            {
                isImage(filename) && <a href={media_url} target={'_blank'} download className={'appearance-none'}>
                    <img src={thumbnailUri} alt={'Creative'} className="card-img-top"/>
                </a>
            }

            {
                isVideo(filename) && <LazyVideoLoader
                        poster={media_thumb}
                        source={media_url} style={{width: '100%'}} height="240" controls>
                    <source src={media_url} type="video/mp4"/>
                    Your browser does not support the video tag.
                </LazyVideoLoader>
            }
            {
                isHtml(filename) && <iframe src={media_url} style={{border: 0, width: '100%', minHeight: 300}}/>
            }

            <div className={'card-body'}>
                {
                    isFile(filename) && <a href={mediaDownloadUrl(media_url, ref_code)} target={'_blank'} download>
                        {
                            filename && filename.toLowerCase().endsWith(".pdf") && <>
                                <div className="text-center">
                                    <img src={"/icons/pdf.svg"} alt={"PDF Icon"} height={100} />
                                </div>
                            </>
                        }
                        <div className={'my-2 text-center'}>{filename}</div>
                    </a>

                }
                <div className={'row'}>
                    <div className={'col-auto'}>
                        {
                            width > 0 && height > 0 ?
                                <small>{prettyBytes(size)} &middot; {width}x{height} </small> :
                                <small>{prettyBytes(size)}</small>
                        }
                    </div>
                    <div className={'col text-right'}>
                        <UncontrolledDropdown>
                            <DropdownToggle onClick={this.show} color={"transparent"} className={"p-0"}>
                                <span><ThreeDotsHorizontalIcon/></span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <CopyButton className={"dropdown-item text-dark"}
                                                text={this.downloadHtml()}
                                                title={"Copy as HTML"}/>
                                <CopyButton className={"dropdown-item text-dark"}
                                            text={this.copyText()}
                                            title={"Copy Text"}/>
                                <a href={this.imageDownloadLink()} className={"btn dropdown-item"} download>
                                    <FormattedMessage id={'Download Media'}/>
                                </a>
                                <DropdownItem header>
                                    <FormattedMessage id={"Share"}/>
                                </DropdownItem>
                                <DDItem onClick={this.shareOnFacebook} title={"Facebook"} src={"/images/facebook.svg"}/>
                                <DDItem onClick={this.shareOnTwitter} title={"Twitter"} src={"/images/twitter.svg"}/>
                                <DDItem onClick={this.shareOnPinterest} title={"Pinterest"}
                                        src={"/images/pinterest.svg"}/>
                                <DDItem onClick={this.shareOnWhatsApp} title={"WhatsApp"} src={"/images/whatsapp.svg"}/>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>

                {
                    media_description && (
                        <>
                            <hr/>
                            <blockquote className={'text-justify'} style={{whiteSpace: 'pre-line'}}>
                                {
                                    media_description
                                }
                            </blockquote>
                            <CopyButton text={updateMediaDescriptionWithRefcode(media_description, ref_code)}/>
                        </>
                    )
                }
                {
                    product_link && (
                        <>
                            <hr/>
                            <CopyButton text={this.getProductLink()}
                                        title={product_link}/>
                        </>
                    )
                }
            </div>
        </div>);
    }
}

function DDItem({onClick, title, src}) {
    return <DropdownItem onClick={onClick}>
        <SocialIcon title={title} src={src}/>
    </DropdownItem>
}

function SocialIcon({src, title}) {
    return <div className={"d-flex align-items-center"}>
        <img alt={title} src={src} width={18}/>
        <span className={"ml-2"}>{title}</span>
    </div>
}

function mapStateToProps(state) {
    const {ref_code, ref_identifiers, referral_link_params, website} = state.settings;
    return {
        ref_code,
        ref_identifiers,
        referral_link_params,
        website,
        settings: state.settings
    };
}

export default connect(mapStateToProps)(CreativeItem);
