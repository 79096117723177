import ReactGA from 'react-ga';
import {history} from '../../config';

import {Component} from 'react';

class ReactGaIntegration extends Component {

    componentDidMount() {
        ReactGA.initialize(this.props.google_analytics);
        ReactGA.pageview(window.location.pathname + window.location.search);
        this.unlisten = history.listen((location, action) => {
            ReactGA.pageview(location.pathname + location.search);
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }


    render() {
        return null;
    }
}

export default ReactGaIntegration;

