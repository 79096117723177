import React, {useEffect} from 'react';
import {post} from '../../../api';
import StyledProgressLoader from '../../cmp/themed/StyledProgressLoader';
import * as localStorage from '../../../tools/storage';
import {connect} from "react-redux";
function LogoutPage({isEmbedded}){
    const redirUrl = isEmbedded ? '/login?embedded=1' : '/login'
    useEffect(()=>{
        post('/logout').then((data) => {
            localStorage.removeItem('access_token')
            document.location = redirUrl;
        }).catch(() => {
            document.location = redirUrl;
        });
    }, [])
    return (
        <div className={'min-vh-100 d-flex align-items-center justify-content-center'}>
            <StyledProgressLoader/>
        </div>
    );
}
function mapStateToProps(state){
    return {
        isEmbedded: state.settings.isEmbedded
    }
}
export default connect(mapStateToProps)(LogoutPage);
