import React, {Component} from 'react';
import PropTypes from 'prop-types';

class YoutubePlaylistNativeEmbed extends Component {

    render() {
        const {playlistId} = this.props;
        return (
            <div
                className="video"
                style={{
                    position: "relative",
                    paddingBottom: "56.25%" /* 16:9 */,
                    paddingTop: 25,
                    height: 0
                }}
            >
                <iframe id="ytplayer" style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                }} src={`https://www.youtube.com/embed/videoseries?list=${playlistId}`} title={"Youtube videos"}
                        allowFullScreen={"allowfullscreen"} frameBorder="0"/>
            </div>
        );
    }
}


YoutubePlaylistNativeEmbed.propTypes = {
    playlistId: PropTypes.string.isRequired,
};

export default YoutubePlaylistNativeEmbed;
