import React, {Component} from 'react';
import {post} from '../../api';
import {FormattedMessage} from 'react-intl';

class EmailAddressNotVerified extends Component {

    state = {
        sendStatus: 'default'
    }

    resend = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState({sendStatus: 'sending'});

        post('/sendverificationlink').then((data) => {
            this.onEmailSent();
        });
    }

    onEmailSent = () => {
        this.setState({sendStatus: 'sent'});
        setTimeout(() => {
            this.setState({sendStatus: 'default'});
        }, 3000);
    }

    getCmp = () => {
        switch (this.state.sendStatus) {
            case 'default':
                return <SendLink onClick={this.resend}/>;
            case 'sending':
                return <Sending/>;
            case 'sent':
                return <Sent/>;
        }
        return null;
    }

    render() {
        let cmp = this.getCmp();

        return (
            <p className={'alert alert-info'}>
                <FormattedMessage id={'emailUnverified'}/>
                <span className={'ml-1'}/>
                {cmp}
            </p>
        );
    }
}


function SendLink({onClick}) {
    return (<a href="#resend" className={'alert-link'} onClick={onClick}>
        <FormattedMessage id={'resendEmail'}/>
    </a>);
}

function Sending() {
    return <span className={'font-weight-bold'}>...</span>;
}

function Sent() {
    return <span className={'font-weight-bold'}><FormattedMessage id={'emailSent'}/></span>;
}

export default EmailAddressNotVerified;
