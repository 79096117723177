import CurrencyNumber from "../../../cmp/CurrencyNumber";
import RenderInterval from "./RenderInterval";
import Button from "../../../auth/components/Button";
import {FormattedMessage} from 'react-intl'

export default function PlanCard({
                      cost, plan_title, num_plans, plan_id, plan_type, perks, description,
                      interval_count, interval_unit,
                      onChoose, loading, isChosen, currency
                  }) {
    return <div className={"card"}>
        <div className="card-body">
            <div className="pb-2 text-center">{plan_title}</div>
            <div className={'display-4 text-center'}>
                <CurrencyNumber currency={currency} value={cost}/>
                {plan_type === "recurring" && <span className={"text-muted"} style={{fontSize: '1rem'}}>
                        /<RenderInterval interval_count={interval_count} interval_unit={interval_unit}/>
                    </span>
                }
            </div>
        </div>
        {
            perks && perks.split("\n").length > 0 && <ul className={"list-group list-group-flush"}>
                {
                    perks && perks.split("\n").filter((item) => item)
                        .map((item) => <li key={item} className={"text-center list-group-item"}>{item}</li>)
                }
            </ul>
        }
        {
            description && <div className="card-body" dangerouslySetInnerHTML={{__html: description}}/>
        }
        {
            num_plans > 1 &&
            <div className="card-footer text-center">
                <Button
                    disabled={loading && !isChosen}
                    loading={loading && isChosen}
                    className={`btn btn-primary`} onClick={onChoose}>
                    <FormattedMessage id={"Choose"}/>
                </Button>
            </div>
        }
    </div>
}
