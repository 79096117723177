import React, {Component} from 'react';
import Modal from "../../cmp/Modal";
import MarkdownLoader from "../../pages/landing/MarkdownLoader";
import {connect} from "react-redux";

class LegalDocModal extends Component {
    state = {
        visible: false,
        isMarkdown: true
    }
    openModal = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState({visible: true})
    }
    closeModal = () => {
        this.setState({visible: false})
    }

    render() {
        let source = undefined
        switch (this.props.to) {
            case "/program-legal/terms":
                source = this.props.terms_url;
                break;
            case '/program-legal/privacy':
                source = this.props.privacy_url;
        }
        const isMarkdown = source.startsWith("/") || source.startsWith("https://static")
        if (isMarkdown) {
            return (
                <>
                    <Modal visible={this.state.visible} onClose={this.closeModal} title={this.props.children}>
                        {
                            this.state.visible && source && (
                                isMarkdown ? <MarkdownLoader source={source}/>
                                    :
                                    <a href={source} target={"_blank"}>
                                        {source}
                                    </a>
                            )
                        }
                    </Modal>
                    <a href={this.props.to} target={"_blank"} onClick={this.openModal}>{this.props.children}</a>
                </>
            );
        } else {
            return <a href={source} target={"_blank"}>{this.props.children}</a>

        }
    }
}

function mapStateToProps(state) {
    return {
        terms_url: state.settings.terms_url || '/assets/Terms.md',
        privacy_url: state.settings.privacy_url || '/assets/PrivacyNotice.md'
    }
}

export default connect(mapStateToProps)(LegalDocModal);
