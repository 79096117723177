import React from 'react';
import Loadable from 'react-loadable';
import Loading from '../../../cmp/LazyProgressLoader';

export default Loadable({
    loader: () => import('./W9FormWizard'),
    loading: LoadingBar
});


function LoadingBar(props) {
    return <div className="d-flex w-100 my-4 py-4 justify-content-center align-items-center">
        <Loading {...props} />
    </div>
}
