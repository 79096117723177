import React, {Component} from 'react';
import * as localStorage from "../../tools/storage";

class AutoLogin extends Component {
    componentDidMount() {
        const {access_token} = this.props.match.params;
        if (access_token) {
            localStorage.setItem('access_token', access_token);
            window.location = "/"
        }
    }

    render() {
        return null;
    }
}

export default AutoLogin;
