import React, {Component} from 'react';
import {facebookShare, openShareWindow, pinterestShare, twitterShare, whatsAppShare} from "../../tools/func";
import CopyButton from "./CopyButton";
import SocialShareIcon from "./SocialShareIcon";
import PropTypes from 'prop-types';
import QrCodeModal from "./qrcodeloader/QRCodeModal";
import {connect} from "react-redux";

class ShareBar extends Component {
    state = {
        showQRModal: false
    }
    getRefLink = () => {
        return this.props.link;
    }
    shareOnFacebook = () => {
        openShareWindow(facebookShare(this.getRefLink()), "facebook", "width=500, height=500")
    }
    shareOnTwitter = () => {
        openShareWindow(twitterShare(this.getRefLink()), 'twitter', 'width=600,height=300');
    }
    shareOnPinterest = () => {
        openShareWindow(pinterestShare(this.getRefLink(), '', this.props.store_logo), "pinterest", "width=600,height=300")
    }
    shareOnWhatsApp = () => {
        window.open(whatsAppShare(this.getRefLink()))
    }

    toggleQRModal = () => {
        this.setState({showQRModal: !this.state.showQRModal})
    }

    render() {
        const {link} = this.props;
        return (
            <div className="d-flex">
                <CopyButton text={link}/>
                <div className={"ml-4"}>
                    <SocialShareIcon disabled={!link} onClick={this.shareOnFacebook} alt={"Facebook"}
                                     src={"/images/facebook.svg"}/>
                    <SocialShareIcon disabled={!link} onClick={this.shareOnTwitter} alt={"Twitter"}
                                     src={"/images/twitter.svg"}/>
                    <SocialShareIcon disabled={!link} onClick={this.shareOnPinterest} alt={"Pinterest"}
                                     src={"/images/pinterest.svg"}/>
                    <SocialShareIcon disabled={!link} onClick={this.shareOnWhatsApp} alt={"WhatsApp"}
                                     src={"/images/whatsapp.svg"}/>
                    <SocialShareIcon disabled={!link} onClick={this.toggleQRModal} alt={"QR Code"}
                                     src={"/images/qr-code.svg"}/>
                </div>
                <QrCodeModal value={link} onClose={this.toggleQRModal} visible={this.state.showQRModal}/>
            </div>
        );
    }
}

ShareBar.propTypes = {
    link: PropTypes.string.isRequired
}

function mapStateToProps(state) {
    return {
        store_logo: state.settings.store_logo
    }
}


export default connect(mapStateToProps)(ShareBar);
