import React, {Component} from 'react';
import YoutubePlayListRender from '../../../cmp/YoutubePlayListRender';
import YoutubePlaylistNativeEmbed from '../../../cmp/YoutubePlaylistNativeEmbed';

class YoutubePlaylistComponent extends Component {
    render() {
        return (
            <div>
                <div className="d-sm-none d-md-block">
                    <YoutubePlayListRender playlistId={this.props.playlistId}/>
                </div>
                <div className="d-sm-block d-md-none">
                    <YoutubePlaylistNativeEmbed playlistId={this.props.playlistId}/>
                </div>
            </div>
        );
    }
}

export default YoutubePlaylistComponent;
