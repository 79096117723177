import React, {Component} from 'react';

import {FacebookIcon} from '../../../icons/Icons';
import {facebookAuthConfig} from '../../../config';
import {FormattedMessage} from 'react-intl';

class FacebookButton extends Component {

    onClick = (e) => {
        let authorize = facebookAuthConfig.oauth_proxy + '?';
        authorize += `callback_uri=${encodeURIComponent(facebookAuthConfig.callback_uri)}&`;
        authorize += `proxy_uri=`;
        authorize += encodeURIComponent(`https://www.facebook.com/v3.2/dialog/oauth?response_type=token&client_id=${facebookAuthConfig.client_id}&redirect_uri=${facebookAuthConfig.oauth_proxy}&scope=email`);
        document.location = authorize;
    }


    render() {
        return (
            <div className={'my-2'}>
                <button className={'btn btn-block'} onClick={this.onClick}
                        style={{backgroundColor: '#3b5999', color: '#fff'}}>
                    <div className={'row no-gutters'}>
                        <div className={'col-auto'}>
                            <FacebookIcon style={{fontSize: '18px'}}/>
                        </div>
                        <div className={'col'}>
                            <small><FormattedMessage id={'signInFacebook'}/></small>
                        </div>
                    </div>
                </button>
            </div>
        );
    }
}

export default FacebookButton;
