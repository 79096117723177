import React from 'react';
import Loading from './LazyProgressLoader';

function LoadingBar(props) {
    return <div className="d-flex min-vh-100 justify-content-center align-items-center">
        <Loading {...props} />
    </div>
}

export default LoadingBar
