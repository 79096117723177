import React from 'react';
import Loadable from 'react-loadable';
import Loading from '../../cmp/LazyProgressLoader';

export default Loadable({
    loader: () => import('./StoreIndex'),
    loading: LoadingBar
});

function LoadingBar(props) {
    return <div className="d-flex min-vh-100 justify-content-center align-items-center">
        <Loading {...props} />
    </div>
}
