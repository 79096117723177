import React, {Component} from 'react';
import GoogleButton from './components/GoogleButton';
import FacebookButton from './components/FacebookButton';
import {connect} from 'react-redux';
import OrDivider from "../cmp/OrDivider";

class OAuthSignIn extends Component {
    render() {
        const {authGoogle, authFacebook} = this.props;
        if (!authGoogle && !authFacebook) return null;
        return (
            <div className={'my-2'}>
                <OrDivider/>
                <div className={'row'}>
                    <div className={authGoogle ? 'col-md-6' : 'd-none'}>
                        <GoogleButton/>
                    </div>
                    <div className={authFacebook ? 'col-md-6' : 'd-none'}>
                        <FacebookButton/>
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const {authGoogle, authFacebook, isEmbedded} = state.settings;
    return {
        authGoogle: isEmbedded ? false : authGoogle,
        authFacebook: isEmbedded ? false : authFacebook
    };
}

export default connect(mapStateToProps)(OAuthSignIn);
