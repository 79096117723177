import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import FormRenderer from "../../cmp/formbuilder/FormRenderer";
import {actionMergeSetting} from "../../../redux/settings/settingsAction";
import {post} from "../../../api";
import Modal from "../../cmp/Modal";
import RenderTipaltiIframe from "../tipalti/RenderTipaltiIframe";

class PaymentInputPage extends Component {

    state = {
        payment_details_data: {}
    }

    save = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        const payment_method = this.getValue('payment_method');
        this.setState({saving: true})
        post('/', {
            payment_method,
            payment_details_data: JSON.stringify(this.getPaymentDetailsData())
        }).then((data) => {
            this.props.updateSettings(data);
            this.setState({saving: false});
            this.props.onClose();
        }).catch((e) => {
            this.setState({saving: false});
        });
    }

    updatePaymentMethod = (e) => {
        this.setState({payment_method: e.target.value, payment_details_data: {}});
    }

    onChange = (payment_details_data) => {
        this.setState({payment_details_data});
    }

    getPaymentDetailsData = () => {
        return {...this.props.payment_details_data, ...this.state.payment_details_data}
    }

    getValue = (key) => {
        return this.state[key] === undefined ? this.props[key] : this.state[key];
    }

    shouldEnable = (form)=>{
        const values = this.getPaymentDetailsData();
        for (let x = 0; x < form.length; x++) {
            const {id, isRequired, isEnabled} = form[x];
            if (isEnabled && isRequired){
                if(typeof values[id] === "string"){
                    if(!values[id].trim()){
                        return false;
                    }
                }else if(!values[id] || values[id].length === 0) {
                    return false;
                }
            }
        }
        return true;
    }

    render() {
        const {available_payment_methods} = this.props
        const payment_method = this.getValue('payment_method')
        const {form} = available_payment_methods.find((item)=>item.key===payment_method) || {form: []}
        return (
            <Modal
                size={payment_method === "tipalti" ? 'lg' : null}
                onCancel={this.props.onClose}
                visible={this.props.visible}
                disabled={!this.shouldEnable(form)}
                onSubmit={this.save} confirmLoading={this.state.saving}
                okText={<FormattedMessage id={"Submit"} />}>
                <div className={'form-group'}>
                    <label className={'text-muted'}>
                        <small><FormattedMessage id={'Payment Mode'}/></small>
                    </label>
                    <select className={'form-control'} value={payment_method} onChange={this.updatePaymentMethod}>
                        <Opt value={''} id={'No payment mode set'}/>
                        {
                            available_payment_methods.map((item)=>{
                                return <Opt key={item.key} id={item.title} value={item.key} />
                            })
                        }
                    </select>
                </div>
                {
                    payment_method === "tipalti" ? <RenderTipaltiIframe type={"payment_details"} /> : null
                }
                <FormRenderer formFields={form}
                              onChange={this.onChange}
                              value={this.getPaymentDetailsData()} />
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    const {available_payment_methods} = state.settings;
    return {
        available_payment_methods,
        payment_method: state.settings.payment_method,
        payment_details_data: state.settings.payment_details_data && typeof state.settings.payment_details_data === "string" ? JSON.parse(state.settings.payment_details_data) : {},
    };
}

function Opt({id, value}) {
    return <FormattedMessage id={id || " "}>{text => <option value={value}>{text}</option>}</FormattedMessage>
}

function mapDispatchToProps(dispatch, props) {
    return {
        updateSettings: (change) => dispatch(actionMergeSetting(change))
    };
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(PaymentInputPage);
