import FormattedNumber from './FormattedNumber';
import CurrencyNumber from './CurrencyNumber';
import React from 'react';

function RenderCommission({commission_type, commission_value}) {
    switch (commission_type) {
        case 'percentage':
            return <span><FormattedNumber value={commission_value}/>%</span>;
        default:
            return <CurrencyNumber value={commission_value}/>;
    }
}

export default RenderCommission;
