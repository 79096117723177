import queryString from "query-string";
// const url = require('url');
export const getExt = (filename) => {
    const parts = filename.split('.');
    return parts && parts.length > 0 ? parts[parts.length-1].toLowerCase() : undefined;
};

const imageExtensions = ['gif','jpeg','jpg','png', 'bmp','jfif'];
const videoExtensions = ['mp4','mov','avi','3gp','mkv'];

export const isImage = (filename) => {
    const ext = getExt(filename);
    return ext && (imageExtensions.indexOf(ext) > -1);
};

export const isVideo = (filename) => {
    const ext = getExt(filename);
    return ext && (videoExtensions.indexOf(ext) > -1);
};
export const isHtml = (filename) =>{
    const ext = getExt(filename);
    return ext && (ext === "html" || ext === "htm")
}

export const isFile = (filename) => !isImage(filename);


export function mediaDownloadUrl(media_url, ref_code){
    const extensions = ['html','txt','htm','php','js'];
    const parts = media_url.split(".");
    if(parts.length > 0) {
        const extension = parts[parts.length - 1]
        if(extensions.indexOf(extension) > -1){
            return `https://api.goaffpro.com/partner/auto_ref?ref=${ref_code}&u=${media_url}`
        }
    }
    return media_url;
}

const reg2 = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/gm;


export function updateMediaDescriptionWithRefcode(media_description, ref_code){
    if (media_description) {
        return media_description.replace(reg2, (x) => {
            const url = new URL(x);
            if (url) {
                url.searchParams.append('ref', ref_code);
                return url.href;
            }
            return x;
        });
    }
    return media_description;
}


function getRefIdentifier(ref_identifiers){
    let identifier = "ref";
    try {
        identifier = ref_identifiers ? JSON.parse(ref_identifiers).length > 0 ? JSON.parse(ref_identifiers)[0] : "ref" : "ref"
    } catch (e) {
        console.error(e);
    }
    return identifier
}

function addToQuery(url, key, val){
    const obj = queryString.parse(url.search);
    obj[key]= val;
    url.search = queryString.stringify(obj);
    return url;
}

export function buildNetworkLink(network_link_page, partner_portal_subdomain, ref_code){
    if(network_link_page){
        const u = network_link_page.startsWith("http") ? network_link_page : `https://${network_link_page}`;
        const page = addToQuery(new URL(u), 'ref',ref_code);
        return {
            homepage: page.href,
            signup_page: page.href
        }
    }else{
        return {
            homepage: addToQuery(new URL(`https://${partner_portal_subdomain}`), 'ref',ref_code).href,
            signup_page: addToQuery(new URL(`https://${partner_portal_subdomain}/create-account`), 'ref',ref_code).href
        }
    }
}

function replaceParams(string, object) {
    let str = string || '';
    if(object) {
        Object.keys(object).forEach((key) => {
            str = str.replace("{" + key + "}", object[key])
        })
    }
    return str || '';
}

function buildRefLinkInternal(url, ref_code, ref_identifiers, referral_link_params, dataobject = {}){
    const u = new URL(url)
    const identifier = getRefIdentifier(ref_identifiers);
    const queryObj = queryString.parse(u.search)
    switch(identifier){
        case "hash":
            u.hash = encodeURIComponent(ref_code)
            break;
        case "/":
            u.pathname = u.pathname + "/" + encodeURIComponent(ref_code)
            break;
        default:
            queryObj[identifier] = ref_code;
    }
    let params = replaceParams(referral_link_params, dataobject).trim();
    if(params) {
        params.split("\n").map((item) => {
            const parts = item.split("=");
            queryObj[parts[0]] = parts.length > 1 ? parts[1] : ''
        })
    }
    if(Object.keys(queryObj).length > 0){
        u.search = queryString.stringify(queryObj)
    }
    return u.href
}

export function buildRefLink(ref_code, website, ref_identifiers,referral_link_params, object) {
    if(!website) return ''
    const w = new URL(website.startsWith('http') ? website : `https://${website}`);
    return buildRefLinkInternal(w, ref_code, ref_identifiers, referral_link_params, object)
}


const emailValidator = new RegExp(/\S+@\S+\.\S+/);

export const isValidEmail = (email)=>{
    return email && emailValidator.test(email)
}
