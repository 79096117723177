import * as localStorage from './tools/storage';
export const isDev = process.env.NODE_ENV === 'development' || localStorage.getItem('isdev');
/*
let isKualaLumpur = false;
try {
	isKualaLumpur = process.env.REACT_APP_DEPLOYMENT_TYPE === "custom-domain" || Intl.DateTimeFormat().resolvedOptions().timeZone.includes("Lumpur")
}catch(e){

}
 */
//console.log({isDev, isKualaLumpur})
function getServer(){
/*	if(isKualaLumpur){
		return 'https://api-server-4.goaffpro.com'
	}else */if(isDev){
		return 'https://api-server-1.goaffpro.com'
	}else{
	   return 'https://api2.goaffpro.com'
	}
}

export const apiServer = getServer();
export const apiUrl = getServer()+'/partner'
export function buildUri(endpoint, query){
	const baseUri = endpoint && endpoint.substr(0,8) === "https://" ? endpoint : `${apiUrl}${endpoint}`;
	if (query) {
		const u = new URL(baseUri);
		Object.keys(query).forEach((item) => {
			if (query[item] !== undefined) {
				u.searchParams.append(item, query[item]);
			}
		});
		return u.href;
	}
	return baseUri;

}

export function post(endpoint, data){
	return fetch( buildUri(endpoint), {
		method: 'POST',
		credentials: 'include',
		headers: buildHeaders(),
		body: JSON.stringify(data)
	}).then((data) =>{
		console.log(data.status);
		return data.json();
	})
}

export function upload(endpoint, body){
	const headers = buildHeaders();
	//remove the content type header
	delete headers['Content-Type'];
	return fetch( buildUri(endpoint), {
		method: 'POST',
		credentials: 'include',
		headers,
		body
	}).then((data) => data.json());
}

function buildHeaders(){
	const headers =  {
		'Content-Type': 'application/json',
	};
	if(localStorage.getItem('access_token')) {
		headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`
	}

	if (isDev){
		headers['x-shop'] = 'helloworld.goaffpro.com';
	}
	if(isDev){
		headers['x-shop'] = 'affiliate.glowb.shop'
	}
	//businessbombshellshq
	/*
	if(window.app_proxy_partner_portal){
		headers['x-shop'] = window.app_proxy_partner_portal
	}
	 */
	if(localStorage.getItem('x-shop')){
		headers['x-shop'] = localStorage.getItem('x-shop');
	}
	return headers;
}


export function get(endpoint, query){
	return fetch(buildUri(endpoint, query),{
		method: 'GET',
		credentials: 'include',
		headers: buildHeaders()
	}).then((response) => {
		if (response.ok){
			return response;
		}else if(response.status === 403) {
			localStorage.removeItem('access_token');
		}else {
			throw response
		}
	}).then((data) => data ? data.json() : {});
}

export function del(endpoint){
	return fetch(buildUri(endpoint),{
		method: 'DELETE',
		credentials: 'include',
		headers: buildHeaders()
	}).then((response) => {
		if (response.ok){
			return response;
		}
		throw new Error(response.status);

	}).then((data) => data.json());
}
