import React, {useEffect, useState} from 'react';
import { IoLogoFacebook, IoLogoInstagram, IoLogoYoutube } from "react-icons/all";
import axios from "axios";
import '../styles/glowb.scss'
import { Helmet } from "react-helmet";
import { HiOutlineArrowRight } from "react-icons/hi";
import {Link} from "react-router-dom";

const EmptyPage = () => {
    const [email, setEmail] = useState('')
    const [isScrolledToTop, setIsScrolledToTop] = useState(false)
    const [dataFromIG, setDataFromIG] = useState([])
    console.log(dataFromIG)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
            setIsScrolledToTop(true)
        }
        else if (scrolled <= 300){
            setIsScrolledToTop(false)
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    window.addEventListener('scroll', toggleVisible);

    // useEffect(() => {
    //     (async () => {
    //         try {
    //             const res = await axios.get('https://graph.instagram.com/me/media', {
    //                 params: {
    //                     fields: 'id,media_url,caption',
    //                     //Adding access token here will fetch images from IG
    //                     access_token: ''
    //                 }
    //             })
    //
    //             setDataFromIG(res.data.data.slice(0, 6))
    //         } catch (e) {
    //             console.error(e)
    //         }
    //     })()
    // }, [])

    return <main className={"text-center glowbFont glowbHeroFont"}>
        <section>
            <div className={"position-relative"}>
                <button className={"btn position-absolute p-0 mr-2 mr-lg-3 mt-1 fontLarge"} style={{right: 0}}>
                    <HiOutlineArrowRight className={"fontSmall mr-1"} />
                    <Link to={'/login'} className={"text-dark text-decoration-none"}>Login</Link>
                </button>
            </div>

            <div className={"d-block d-md-none"}>
                <img src={'/glowb-images/af1.png'} alt={"main-banner"} className={"w-100"} />

                <div className={"mx-4 mt-2"}>
                    <h2>
                        Join our Glowb
                        <br />
                        Affiliate Rewards Programme.
                    </h2>
                    <p className={"h5"}>
                        Share the love and earn
                        <br />
                        money while you do it.
                    </p>
                    <ButtonLink className={"glowbBgPink2 mt-2"} text={"JOIN THE PARTY"} />
                </div>
            </div>

            <a href={'/signup'} target={"_blank"}>
                <img src={'/glowb-images/aff1.jpg'} alt={"main-banner"} className={"w-100 d-none d-md-block"} />
            </a>
        </section>

        <section className={"glowbLightBgPink py-3 mt-3 mt-md-0 py-md-4"}>
            <div className={"mx-5 px-sm-5"}>
                <p className={"h4"}>Being a Glowb affiliate comes with all the treats honey...</p>
                <h4>How Does It Work?</h4>
            </div>

            <div className={"d-block d-md-none container px-4"}>
                <img src={'/glowb-images/af2.png'} alt={"How does it work?"} className={"w-100"} />

                <div className={"mt-3 mx-2"}>
                    <p className={"h5"}>Want to make money whilst sharing your passion for the glowb products?</p>
                    <p className={"h5"}>As a Glowb Affiliate, you will access an exclusive discount code, training page and be the first to know about any upcoming updates for Glowb. You'll get the inside scoop every damn time.</p>
                    <p className={"h5"}>Simply sign up to our Glowb Affiliate Rewards Programme and earn commission on each and every sale generated by you and your babes.</p>
                    <ButtonLink text={"CREATE AN ACCOUNT"} className={"mt-2 border-white glowbBgPink3"} textColor={"text-white"} />
                </div>
            </div>

            <div className={"d-none d-md-block container-fluid"}>
                <div className={"row text-md-left mt-md-4"}>
                    <div className={"col-md-1"} />

                    <img src={'/glowb-images/aff2.png'} alt={"How does it work?"} className={"w-100 h-75 mt-md-5 col-md-5 pt-md-4 pt-lg-0 mt-lg-0"} />

                    <div className={"col-md-4 mx-md-5 mt-md-4"}>
                        <p className={"h5"}>Want to make money whilst sharing your passion for the glowb products?</p>
                        <p className={"mt-md-4 h5"}>Simply sign up to our Glowb Affiliate Rewards Programme and earn commission on each and every sale generated by you and your babes</p>
                        <p className={"mt-md-4 h5"}>As a Glowb Affiliate, you will access an exclusive discount code, training page and be the first to know about any upcoming updates for Glowb. You'll get the inside scoop every damn time</p>
                        <ButtonLink text={"CREATE AN ACCOUNT"} className={"mt-md-4 border-white glowbBgPink3"} textColor={"text-white"} />
                    </div>

                    <div className={"col-md-2"} />
                </div>
            </div>
        </section>

        <section className={"mt-3 mt-md-4 pt-3 pb-3 glowbBgPink2 w-md-75 container-md rounded"}>
            <p className={"m-0 h3"}>What's in it for me sis?</p>

            <div className={"row mx-2 mt-3 mt-md-4"}>
                <IconText src={'/glowb-images/afff1.png'} text={"Earn up to 30% on every sale made through your affiliate link."} />
                <IconText src={'/glowb-images/afff2.png'} text={"Free access to the training page."} />
                <IconText src={'/glowb-images/afff3.png'} text={"Social media content created for you."} />
                <IconText src={'/glowb-images/afff4.png'} text={"A unique discount code to share with your friends and followers."} />
            </div>

            <div className={"row mx-2 mt-3 mt-md-4"}>
                <div className={"col-1"} />
                <IconText src={'/glowb-images/afff5.png'} className={"ml-2"} text={"Commission paid out on your team sales."} />
                <IconText src={'/glowb-images/afff6.png'} text={"Your own online portal."} className={"mx-3"} />
                <IconText src={'/glowb-images/afff8.png'} text={"Simple & Easy to use."} />
                <div className={"col-1"} />
            </div>

            <ButtonLink className={"bg-white mt-4 mt-md-5"} text={"JOIN NOW"} />
        </section>

        <section className={"mt-3 mt-md-4 pt-2 glowbLightBgPink pb-5"}>
            <p className={"m-0 h3"}>Get the benefits in 4 easy steps!</p>
            <h3>Let us talk you through it, honey:</h3>

            <div className={"container px-lg-5 d-none d-md-block"}>
                <StepsSection titleClassName={"w-75 mt-md-4"} buttonClassName={"mt-md-4"} step={1} title={"It all starts with an account."} imgRight={'/glowb-images/af3.png'} buttonText={'CREATE AN ACCOUNT'} />
                <StepsSection titleClassName={"w-75 mt-md-4"} step={2} title={"Grab your favourite Glowb skincare."} imgLeft={'/glowb-images/af4.png'} details={"Tell your audience why you are obsessed with Glowb"} />
                <StepsSection titleClassName={"w-75 mt-md-4"} step={3} title={"Create fun & engaging content."} imgRight={'/glowb-images/af5.png'} details={"Be playful, be YOU! Snap photos, videos, reels, post on your socials and share with the world what you love about Glowb skincare"} />
                <StepsSection buttonClassName={"mt-lg-4"} titleClassName={"mt-lg-3"} step={4} title={"Share it & earn money."} imgLeft={'/glowb-images/af6.png'} details={"Earn up to 30% commission on your sales and bump up your paycheque through your affiliate sales as well"} buttonText={'CREATE AN ACCOUNT'} />
            </div>

            <div className={"container d-block d-md-none"}>
                <StepsSectionMobile step={1} link={"/signup"} imgAffiliate={'/glowb-images/af3.png'} imgText={'/glowb-images/step1.jpg'} />
                <StepsSectionMobile className={"flex-row-reverse"} step={1} imgAffiliate={'/glowb-images/af4.png'} imgText={'/glowb-images/step2.jpg'} />
                <StepsSectionMobile step={1} imgAffiliate={'/glowb-images/af5.png'} imgText={'/glowb-images/step3.jpg'} />
                <StepsSectionMobile className={"flex-row-reverse"} step={1} link={"/signup"} imgAffiliate={'/glowb-images/af6.png'} imgText={'/glowb-images/step4.jpg'} />
            </div>
        </section>

        <section>
            <div className={"d-block d-md-none"}>
                <img src={'/glowb-images/af7.png'} alt={"Share the love and get paid"} className={"w-100"}/>
                <h2 className={"mt-3 mx-5"}>Share The Love & Get Paid</h2>
                <ButtonLink className={"glowbBgPink2 mt-1"} text={"BECOME AN AFFILIATE"} />
            </div>

            <a href={'/signup'} target={"_blank"}>
                <img src={'/glowb-images/aff8.jpg'} alt={"Share the love and get paid"} className={"w-100 d-none d-md-block"} />
            </a>

            <p className={"m-0 h3 glowbPink3 mt-4"}>LET'S GET SOCIAL</p>
            <h4 className={"glowbDarkPink"}>@glowb.shop</h4>

            {/*TODO - Enable after adding auth token in useEffect*/}
            {/*<div className={"container-fluid"}>*/}
            {/*    <div className={"row"}>*/}
            {/*        {dataFromIG.map(({ id, media_url }) => <div key={id} className={"col-2"}>*/}
            {/*            <img src={media_url} alt={id} className={"w-100"} style={{objectFit: "cover"}} />*/}
            {/*        </div>)}*/}
            {/*    </div>*/}
            {/*</div>*/}
        </section>

        <hr className={"glowbBgPink3"} style={{height: '1.5px'}} />

        <section className={"text-left d-flex flex-column flex-md-row"}>
            <div className={"d-flex align-items-center"}>
                <div className={"ml-sm-3"}>
                    <img src={'/glowb-images/glowb logo.png'} alt={"glowb-logo"} className={"w-75 w-md-50 mt-md-4"} />

                    <div className={"ml-2 ml-md-0"}>
                        <a href={'https://www.facebook.com/Glowbshop-111279211329639'} target={"_blank"} className={"btn pr-1"}>
                            <IoLogoFacebook style={{fontSize: "x-large"}} />
                        </a>
                        <a href={'https://www.instagram.com/glowb.shop/'} target={"_blank"} className={"btn px-0"}>
                            <IoLogoInstagram style={{fontSize: "x-large"}} />
                        </a>
                        <a href={'https://www.youtube.com/channel/UCUmypqxxzVcEsW0mZ1tx0rQ'} target={"_blank"} className={"btn pl-1"}>
                            <IoLogoYoutube style={{fontSize: "x-large"}} />
                        </a>
                    </div>
                </div>

                <div className={"btn mt-5 text-right d-block d-md-none"} onClick={scrollToTop}>
                    <img src={'/glowb-images/back to.png'} alt={"glowb-logo"} className={"w-75"} />
                </div>
            </div>

            <div className={"w-md-100 glowbFooterMargin mr-md-5 ml-3 ml-md-0"}>
                <p className={"mr-5 mt-3 mt-md-0 h4"}>
                    WE ONLY SEND CHEEKY
                    <br />
                    OFFERS SIGN UP NOW TO GRAB THE
                    <br />
                    GOSSIP EMAIL
                </p>

                <input type={"email"} value={email} className={"border border-dark p-3 pt-4 w-75 w-md-100 mt-md-0 placeholder-change mt-2"} placeholder={"YOUR EMAIL"} onChange={(e) => setEmail(e.target.value)} />
            </div>

            <div className={"btn mt-5 text-right d-none d-md-block"} onClick={scrollToTop}>
                <img src={'/glowb-images/back to.png'} alt={"glowb-logo"} className={"w-75 w-md-50 pt-md-5 mt-md-3"} />
            </div>
        </section>

        <p className={"small mx-5 mt-4 mt-lg-5"}>2021, glowb | BOMBSHELLS INVESTMENTS LIMITED. St Mary's House, Crewe Road, Alsager, Stoke-On-Trent, United Kingdom, ST7 2EW CRN: 12596237 | help@glowb.shop | +442081020615</p>
    </main>
}

const StepsSectionMobile = ({ imgAffiliate, imgText, step, link, className }) => {
    return  <div className={`d-flex mt-4 ${className}`}>
        <img src={imgAffiliate} alt={step} className={"w-50 h-50"} />

        {link
            ? <a href={link} target={"_blank"}>
                <img src={imgText} alt={step} className={"w-100 position-relative"} style={{top: "9%", left: `${className ? "9%" : "-9%"}`}} />
            </a>
            : <div>
                <img src={imgText} alt={step} className={"w-100 position-relative"} style={{top: "9%", left: `${className ? "9%" : "-9%"}`}} />
            </div>
        }
    </div>
}

const StepsSection = ({ imgLeft, imgRight, step, title, buttonClassName, titleClassName, buttonText, details }) => {
    return  <div className={`row mt-4`}>
        {imgLeft ? <img src={imgLeft} alt={step} className={"col-md-6 w-md-25"} /> : null}

        <div className={"col-md-6"}>
            <div className={`text-left bg-white mt-lg-5 pl-3 pt-3 position-relative h-md-85 h-lg-50`} style={{top: '8%', left: `${imgRight ? '11%' : '-11%'}`, zIndex: 9000}}>
                <p className={"h4"}>Step {step}.</p>
                <h3 className={titleClassName}>{title}</h3>

                {details ? <p className={"mt-2"}>{details}</p> : null}
                {buttonText ? <ButtonLink className={`glowbBgPink2 ${buttonClassName}`} text={buttonText} /> : null}
            </div>
        </div>

        {imgRight ? <img src={imgRight} alt={step} className={"col-md-6"} /> : null}
    </div>
}

const IconText = ({ src, text, className }) => {
    return <div className={"col-sm-3 mt-3 mt-sm-0 " +className}>
        <div className={"d-flex flex-column align-items-center"}>
            <img src={src} alt={"what's in it icon"} width={50} />
            <span className={"mt-1 mt-sm-0"}>{text}</span>
        </div>
    </div>
}

const ButtonLink = ({ className, href, text, textColor }) => {
    return <button className={"btn p-2 border border-dark px-5 " +className} style={{borderRadius: '2px'}}>
        <a href={'/signup'} target={"_blank"} className={`${textColor ? textColor : 'text-dark'} text-decoration-none`}>{text}</a>
    </button>
}

export default EmptyPage