import React, { useEffect, useState } from 'react';
import Modal from '../../cmp/Modal';
import BootStrapPagedTable from '../../cmp/BootStrapPagedTable';
import CurrencyNumber from '../../cmp/CurrencyNumber';
import {FormattedMessage} from "react-intl";
import {get} from "../../../api";
import Label from "../../auth/components/Label";
import fecha from "fecha";
import CopyButton from "../../cmp/CopyButton";
import ProgressLoader from "../../cmp/ProgressLoader";
import Spinner from "../../cmp/Spinner";
import {connect} from "react-redux";

const columns = [
    {
        title: 'Product',
        dataIndex: 'name'
    }, {
        title: 'Quantity',
        dataIndex: 'quantity',
        type: 'number',
        render: (quantity) => quantity || 1
    },
    {
        title: 'Price',
        type: 'number',
        dataIndex: 'total',
        render: (total) => <CurrencyNumber value={Number(total)}/>
    },
    {
        title: 'Commission',
        type: 'number',
        dataIndex: 'commission',
        render: (commission) => <CurrencyNumber value={Number(commission)}/>
    }
];

function AffiliateSalesLineItemsModal({order_id, onClose, show_shipping, show_customer, show_product}){
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        if(order_id){
            setVisible(true);
            setData({})
            setLoading(true);
            get('/sales/order_details',{
                    order_id
            }).then((data)=>{
                setData(data.data)
                setLoading(false);
            }).catch(()=>{
                setLoading(false);
            })
        }else{
            setVisible(false)
        }
    },[order_id])

    const { fulfillment_data, site_order_number, line_items, customer, order_value, affiliate_commission, discount_adjustment, shipping_address, shipping_price} = data;

    function hide() {
        setVisible(false);
        onClose()
    }

    return (
            <Modal visible={visible} onOk={hide} onClose={hide}
                   title={site_order_number ? String(site_order_number) : undefined}>
                {
                    loading ? <div className="text-center">
                        <Spinner/>
                    </div> : <>
                        <div className={'row mb-2'}>
                            <div className="col-6">
                                <div className={'p-2  bg-light rounded'}>
                                    <small>
                                        <strong><FormattedMessage id={"Order"}/></strong>
                                    </small>
                                    <div>{`${site_order_number}`}</div>
                                </div>
                            </div>
                            {
                                show_customer && customer &&
                                <RenderCustomer customer={customer} />
                            }
                        </div>
                        {
                            show_shipping && shipping_address &&
                            <div className="bg-light p-2 mb-2">
                                <strong><FormattedMessage id={"Shipping Address"}/></strong>
                                <ShopifyAddress {...shipping_address} />
                            </div>
                        }
                        <RenderFullfillment data={fulfillment_data}/>
                        <div className={'row mb-2'}>
                            <div className="col-6">
                                <div className={'p-2  bg-light rounded'}>
                                    <small>
                                        <strong><FormattedMessage id={"Order Total"}/></strong>
                                    </small>
                                    <div><CurrencyNumber value={Number(order_value)}/></div>
                                </div>
                            </div>
                            <div className="col-6 ">
                                <div className={'p-2 bg-light rounded'}>
                                    <small><strong><FormattedMessage id={"Commission"}/></strong></small>
                                    <div><CurrencyNumber value={affiliate_commission}/></div>
                                </div>
                            </div>
                        </div>

                        {
                            show_product && line_items &&
                            <BootStrapPagedTable small dataSource={line_items} columns={columns}/>
                        }
                        {
                            show_product && shipping_price !== undefined ?
                                <div className="d-flex justify-content-between">
                                    <span><FormattedMessage id={"Shipping"}/></span>
                                    <span><CurrencyNumber value={shipping_price}/></span>
                                </div> : null
                        }
                        {
                            show_product && discount_adjustment ? <div className="d-flex justify-content-between">
                                <span><FormattedMessage id={"Discount Adjustment"}/></span>
                                <span className="text-danger"><CurrencyNumber value={discount_adjustment}/></span>
                            </div> : null
                        }
                    </>
                }
            </Modal>
        );
}

function RenderCustomer({customer, customer_email, phone, customer_name}){
    const {name, first_name, last_name, orders_count, email} = customer || {};
    const n = [first_name || '', last_name || ''].filter((item)=>item).join(" ");
    return  <div className="col-6">
        <div className={'p-2 bg-light rounded'}>
            <small><strong><FormattedMessage id={"Customer"}/></strong></small>
            <div>{customer_name || name || n || "-"}
                <RenderNew
                    orders_count={orders_count}/>
            </div>
            <small className={"gdpr_sensitive_info"}>{customer_email || email || phone || customer.phone}</small>
        </div>
    </div>
}

function ShopifyAddress({name, address1, address_1, address2, address_2, company, phone, city, zip, state, province, country}) {
    return <div>
        <div>{name}</div>
        <div className="gdpr_sensitive_info">
        <div>{company}</div>
        <div>{address1 || address_1}</div>
        <div>{address2 || address_2}</div>
        <div>{city}, {province || state} {zip}</div>
        {phone && <div>{phone}</div>}
        </div>
    </div>
}

function RenderNew({orders_count}) {
    switch (orders_count) {
        case 1:
            return <span className={"ml-2 badge badge-secondary"}><FormattedMessage id={"NEW"} defaultMessage={"NEW"}/></span>
        default:
            return null;
    }
}

function RenderFullfillment({data, loading}) {
    if (loading) {
        return <div className="text-center py-2">
            <ProgressLoader/>
        </div>
    }
    if (!data) {
        return null;
    }
    if (data.length === 0) {
        return <div className={"bg-light p-2 mb-2"}>
            <Label>Shipping Status</Label>
            <h3>Processing</h3>
        </div>
    }
    console.log(data)
    return <>
        <div className="list-group list-group-flush bg-light p-2 mb-2">
            {
                data.filter((item) => ['cancelled', 'error', 'failure']
                    .indexOf(item.status) === -1)
                    .map((item) => {
                        const {
                            id, tracking_company, status, shipment_status, tracking_numbers, tracking_urls, created_at, updated_at
                        } = item;
                        if (!id) return null;
                        return <div key={id} className={"list-group-item"}>

                            <div className="d-flex justify-content-between align-items-start">
                                <Label>Shipping status</Label>
                                <span>{fecha.format(new Date(created_at), 'hh:mm a, Do MMM')}</span>
                            </div>
                            <h3><RenderShipmentStatus shipment_status={shipment_status}/></h3>
                            <div className={"lead mt-2"}>{tracking_company}</div>
                            <Label>
                                <FormattedMessage id={"tracking_number"}
                                                  defaultMessage={`Tracking {numbers, plural, one {Number} other {Numbers}}`}
                                                  values={{numbers: tracking_numbers.length}}/></Label>
                            <p>{tracking_numbers.map((item) => <div key={item}>{item}</div>)}</p>
                            <Label>
                                <FormattedMessage id={"tracking_number"}
                                                  defaultMessage={`Tracking {numbers, plural, one {Link} other {Links}}`}
                                                  values={{numbers: tracking_urls.length}}/>
                            </Label>
                            <p>{
                                tracking_urls.map((item) => <div className={"d-flex align-items-center"}>
                                    <CopyButton text={item} title={"Copy Link"}/>
                                    <a href={item} target={"_blank"} className={"ml-4"} rel={"noopener nofollow"}
                                       key={item}>Open Link</a>
                                </div>)
                            }
                            </p>

                            <Label title={"Shipment Status"}/>
                        </div>
                    })
            }
        </div>
    </>
}

function RenderShipmentStatus({shipment_status}) {
    switch (shipment_status) {
        case "label_printed":
        case "label_purchased":
            return "Processing";
        case "attempted_delivery":
            return "Delivery attempted";
        case "ready_for_pickup":
            return "Ready for pickup";
        case "confirmed":
            return "Shipping Confirmed"
        case "in_transit":
            return "In Transit"
        case "out_for_delivery":
            return "Out for delivery"
        case "delivered":
            return "Delivered"
        case "failure":
            return "Error"
        default:
            return "";
    }
}


function mapStateToProps(state) {
    const {aff_order_details_table_info, aff_show_order_fulfillment} = state.settings;
    let show_product = true;
    let show_shipping = true;
    let show_customer = true;
    let show_fulfillment = aff_show_order_fulfillment;
    if (aff_order_details_table_info) {
        const opts = JSON.stringify(aff_order_details_table_info);
        show_product = opts.indexOf('product') > -1
        show_shipping = opts.indexOf('shipping') > -1;
        show_customer = opts.indexOf('customer') > -1
        show_fulfillment = opts.indexOf('fulfillment') > -1
    }

    return {
        show_product, show_customer, show_shipping, show_fulfillment
    }
}


export default connect(mapStateToProps)(AffiliateSalesLineItemsModal);
