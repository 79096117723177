import {FormattedMessage} from "react-intl";
import React from "react";

export default () => {
    return <div className={'row align-items-center'}>
        <div className={'col'}>
            <hr/>
        </div>
        <div className={'col-auto'}>
            <FormattedMessage id={'OR'}/>
        </div>
        <div className={'col'}>
            <hr/>
        </div>
    </div>
}
