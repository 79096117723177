import Loadable from "react-loadable";
import LazyLoadingBar from "../LazyProgressLoader";
import PropTypes from "prop-types";

const LazyRangePicker = Loadable({
    loader: () => import('./DatePicker'),
    loading: LazyLoadingBar,
});

LazyRangePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    config: PropTypes.any,
    date: PropTypes.instanceOf(Date),
    prefix: PropTypes.string
};


export default LazyRangePicker
