const defaultForms = {
    "w9_2020": {}
}

export default (state = defaultForms, action) => {
    switch (action.type) {
        case "set-forms":
            return state;
        case "update-form":
            return {...state, [action.id]: {...state[action.id], ...action.change}};
        default:
            return state;
    }
}
