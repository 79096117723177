import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import RenderCommission from '../../cmp/RenderCommission';

class ProductCommissionsTableNew extends Component {

    state = {
        product_commissions: [],
        collection_commissions: [],
        royalty_commissions: [],
    }

    componentDidMount() {
        this.setCommissions()

    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.standard !== this.props.standard){
            this.setCommissions()
        }
    }
    setCommissions = ()=>{
        if(!this.props.standard){
            return
        }
        const product_commissions = this.props.special ? this.props.special.filter((item) => item && item.product && item.product.id) : [];
        const collection_commissions = this.props.special ? this.props.special.filter((item) => item && item.collection && item.collection.id) : [];
        const royalty_commissions = this.props.royalties || [];
        const {commission_value, commission_type} = this.props.standard;
        this.setState({
            commission_value,
            commission_type,
            product_commissions: this.groupByValue(product_commissions),
            collection_commissions: this.groupByValue(collection_commissions),
            royalty_commissions: this.groupByValue(royalty_commissions)
        });
    }

    groupByValue = (rows) => {
        const map = {};
        rows.forEach((item) => {
            const {commission_value, commission_type} = item;
            const key = commission_value + ":" + commission_type
            if (!map[key]) {
                map[key] = [];
            }
            map[key].push(item);
        })
        return Object.keys(map).map((key) => {
            const items = map[key];
            const {commission_type, commission_value} = items[0];
            return {commission_value, commission_type, items};
        })
    }

    alphabeticalSorter = (a, b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        else return 0
    }

    render() {
        const {
            royalty_commissions,
            product_commissions,
            collection_commissions,
            commission_type, commission_value
        } = this.state;
        return (
            <>
                {
                    commission_value > 0 && <FormattedMessage
                        id={'A {commission_value} commission is given on sale of every product (except those listed below)'}
                        defaultMessage={'A {commission_value} commission is given on sale of every product'}
                        tagName={'p'} values={{
                        commission_value: <strong><RenderCommission commission_type={commission_type}
                                                                    commission_value={commission_value}/></strong>
                    }}/>
                }
                {
                    royalty_commissions.length > 0 || product_commissions.length > 0 || collection_commissions.length > 0 ?
                        <table className="table table-striped table-sm  mb-0">
                            {
                                product_commissions.length > 0 && <>
                                    <TableHead columns={['Product','Commission']} />
                                    <tbody>
                                    {
                                        product_commissions.map(({items, commission_type, commission_value}, index) => (
                                            <tr key={String(index)}>
                                                <td>{items.sort(this.alphabeticalSorter).map(({product, variation}) =>
                                                    <div key={String(product.name)}>{product.name} {variation ? <small
                                                        className={"text-muted"}>{variation.name}</small> : null}</div>)}</td>
                                                <td><RenderCommission commission_type={commission_type}
                                                                      commission_value={commission_value}
                                                /></td>
                                            </tr>))
                                    }
                                    </tbody>
                                </>
                            }
                            {
                                collection_commissions.length > 0 && <>
                                    <TableHead columns={['Category','Commission']} />
                                    <tbody>
                                    {
                                        collection_commissions.map(({items, commission_type, commission_value}, index) => (
                                            <tr key={String(index)}>
                                                <td>{items
                                                    .sort(this.alphabeticalSorter)
                                                    .map(({collection}) => <div key={String(collection.name)}>{collection.name}</div>)}</td>
                                                <td><RenderCommission commission_type={commission_type}
                                                                      commission_value={commission_value}
                                                /></td>
                                            </tr>))
                                    }
                                    </tbody>
                                </>
                            }
                            {
                                royalty_commissions.length > 0 && <>
                                    <TableHead columns={['Item','Commission']} />
                                    <tbody>
                                    {
                                        royalty_commissions.map(({items, commission_type, commission_value}, index) => (
                                            <tr key={String(index)}>
                                                <td>{items
                                                    .sort(this.alphabeticalSorter)
                                                    .map(({product, collection}) => <div
                                                        key={(product && product.name) || (collection && collection.name)}>{(product && product.name) || (collection && collection.name)}</div>)}</td>
                                                <td><RenderCommission commission_type={commission_type}
                                                                      commission_value={commission_value}
                                                /></td>
                                            </tr>))
                                    }
                                    </tbody>
                                </>
                            }

                        </table> : null
                }
            </>
        );
    }
}

function TableHead({columns}){
    return <thead>
    <tr>
        {
            columns.map((item)=>  <th key={String(item)}><FormattedMessage id={item}/></th>)
        }
    </tr>
    </thead>
}

function mapStateToProps(state) {
    return state.commissions

}


export default connect(
    mapStateToProps
)(ProductCommissionsTableNew);
