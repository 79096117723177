import {CookieStorage} from 'cookie-storage';

const cookieStorage = new CookieStorage();

export function setItem(key, value){
	if(localStorage) {
		localStorage.setItem(key, value);
	}
	cookieStorage.setItem(key, value);
}
export function getItem(key){
	return (localStorage && localStorage.getItem(key)) || cookieStorage.getItem(key);
}


export function removeItem(key){
	if(localStorage) {
		localStorage.removeItem(key);
	}
	cookieStorage.removeItem(key);
}
