import React from "react";

function SocialShareIcon({disabled, onClick, alt, src}) {
    return <button disabled={disabled} className={"btn px-1 share_" + alt.toLowerCase()} type={"button"}
                   onClick={onClick}>
        <img alt={alt} role={"button"} tabIndex={0} src={src} width={24} height={24} onClick={onClick}/>
    </button>
}

export default SocialShareIcon;
