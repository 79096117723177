import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

// Note: this API requires redux@>=3.1.0
const store = createStore(
	rootReducer,
	applyMiddleware(thunk)
);


export default store;
