import React, {Component} from 'react';
import debounce from "lodash.debounce";
import {connect} from "react-redux";
import CurrencyNumber from "../../cmp/CurrencyNumber";
import Spinner from "../../cmp/Spinner";

class ShopifyProductSearchAutoComplete extends Component {
    state = {
        dropdownVisible: false,
        kwd: '',
        data: [],
        selection: -1,
    }
    searchProduct = () => {
        const {kwd} = this.state;
        if (kwd.startsWith("http")) {

        } else if (this.props.website) {
            this.setState({loading: true})
            const u = new URL('https://' + this.props.website + '/search/suggest.json');
            u.searchParams.append("q", kwd);
            u.searchParams.append('resources[type]', 'product')
            fetch('https://proxy.goaffpro.workers.dev/' + u.href).then((data) => data.json())
                .then(({resources}) => resources ? resources.results.products : [])
                .then((items) => {
                    this.setState({data: items, loading: false})
                })
        } else {
            this.setState({data: []})
        }
    }
    debounced = debounce(this.searchProduct, 300);
    onChange = e => {
        this.setState({kwd: e.target.value})
        if (e.target.value.startsWith("http")) {
            this.onURL(e.target.value)
        } else {
            this.debounced();
        }
    }
    keyCodes = {
        up: 38,
        down: 40,
        enter: 13,
        esc: 27
    }

    onURL = (url) => {
        if (this.props.onURLChange) {
            this.props.onURLChange({target: {value: url}})
        }
    }

    onEscape = () => {
        this.setState({dropdownVisible: false})
    }
    onSelected = (index) => () => {
        const selectedItem = this.state.data[index]
        const url = 'https://' + this.props.website + "/products/" + selectedItem.handle;
        this.setState({kwd: selectedItem.title, selection: -1, data: []}, () => {
            this.onURL(url)
        })
        if (this.input) {
            this.input.blur()
        }
        if (this.resultsContainer) {
            this.resultsContainer.blur()
        }
    }

    onEnter = () => {
        const {selection} = this.state
        this.onSelected(selection)();
    }
    clamp = (num, lower_bound, upper_bound) => {
        return num > upper_bound ? upper_bound : num < lower_bound ? lower_bound : num;
    }

    handleKeyDown = (e) => {
        switch (String(e.keyCode)) {
            case "38": {//up
                const selection = this.clamp(this.state.selection - 1, -1, this.state.data.length - 1);
                this.setState({selection})
                if (selection === -1 && this.input) {
                    this.input.focus()
                }
                break;
            }
            case "40": {      //down
                const selection = this.clamp(this.state.selection + 1, -1, this.state.data.length - 1)
                if (selection === -1 && this.input) {
                    this.input.focus()
                }
                this.setState({selection})
                break;
            }
            case "13": //enter
                this.onEnter();
                break;
            case "27": //esc
                this.onEscape()
                break;
            default:
            //
        }
        return false;
    }

    inputRef = (ref) => {
        this.input = ref;
    }

    resultsContainerRef = (ref) => {
        this.resultsContainer = ref;
    }

    inputKeyDown = (e) => {
        if (this.resultsContainer && e.keyCode === 40 && this.state.data.length > 0) {
            this.resultsContainer.focus();
            this.setState({selection: 0})
        }
    }

    onResultsBlur = () => {
        this.setState({selection: -1})
    }

    render() {
        return (
            <>
                <input ref={this.inputRef} type="text"
                       onKeyDown={this.inputKeyDown}
                       className="form-control" value={this.state.kwd} onChange={this.onChange}/>
                <div style={{position: 'relative'}}>
                    <ul ref={this.resultsContainerRef} style={{
                        zIndex: 1,
                        overflowY: 'scroll',
                        backgroundColor: 'white',
                        position: 'absolute',
                        width: '100%',
                        maxHeight: 300
                    }} onBlur={this.onResultsBlur} className={"shadow-lg list-group"} tabIndex={0}
                        onKeyDown={this.handleKeyDown}>
                        {
                            this.state.loading ?
                                <div style={{paddingTop: 48, paddingBottom: 48}} className={"text-center bg-light"}>
                                    <Spinner/></div> :
                                this.state.data.map((item, index) => {

                                    return <ProductItem {...item}
                                                        selected={this.state.selection === index}
                                                        key={item.id} onClick={this.onSelected(index)}/>
                                })
                        }
                    </ul>

                </div>
            </>
        );
    }
}

function ProductItem({handle, image, price, title, onClick, selected}) {
    return <div onClick={onClick}
                className={`btn list-group-item-action d-flex align-items-center list-group-item ${selected ? 'bg-light' : ''}`}>
        <img alt={""} src={image} style={{height: 100}} className={"rounded"}/>
        <div className={"ml-4"}>
            <div>
                <strong>{title}</strong>
            </div>
            <CurrencyNumber value={price}/>
        </div>
    </div>
}

function mapStateToProps(state) {
    return {
        website: state.settings.website
    }
}

export default connect(mapStateToProps)(ShopifyProductSearchAutoComplete);
