import React, {Component} from 'react';
import TopNavBar from './components/TopNavBar';
import LandingFooter from './components/LandingFooter';
import LandingContainer from './components/LandingContainer';
import ContactUs from '../ContactUs';
import HeroComponent from "./components/HeroComponent";

class LandingContact extends Component {
    render() {
        return (
            <LandingContainer>
                <TopNavBar/>
                <div className={'bg-dark'}>
                    <HeroComponent/>
                </div>
                <div className={'my-4 pb-4'}>
                    <ContactUs/>
                </div>
                <LandingFooter/>
            </LandingContainer>
        );
    }
}

export default LandingContact;
