import React, {Component} from 'react';
import {get} from '../../../api';
import {FormattedMessage} from 'react-intl';
import ProgressLoader from '../../cmp/ProgressLoader';
import uniq from 'lodash.uniq';
import CreativeItem from "./CreativeItem";
import {connect} from "react-redux";

class CreativesGrid extends Component {

    state = {
        creatives: [],
        loading: false,
        category: ''
    }


    componentDidMount() {
        this.loadCreatives();
    }

    onChange = (key) => (e) => {
        this.setState({[key]: e.target.value});
    }

    loadCreatives = (category) => {
        this.setState({loading: true});
        const q = category && {category};
        get('/creatives', q).then((data) => {
            let creatives = data.creatives || data;
            const categories = uniq(creatives.map(({media_category}) => media_category));
            this.setState({
                creatives,
                loading: false,
                categories,
                category: creatives.length > 100 && categories.length > 0 ? categories[0] : ''
            });
        }).catch(() => {
            this.setState({loading: false});
        });
    }

    render() {
        const {categories, creatives, category} = this.state;
        if (creatives.length > 0) {
            const cmp = [];
            creatives.filter(({media_category, media_id}) => {
                if (!category) {
                    return true;
                }
                return media_category === this.state.category;
            }).forEach((item) => {
                cmp.push(
                    <CreativeItem
                        key={String(item.media_id)}
                        {...item}
                    />
                );
            });
            return (
                <>
                    <div className="d-flex justify-content-between align-items-center">
                        <h3><FormattedMessage id={'mediaAssets'}/></h3>
                        {
                            categories.length > 0 && <div className="d-flex my-2">
                                <select className="form-control" value={category} onChange={this.onChange('category')}>
                                    {
                                        creatives.length < 100 ? <FormattedMessage id={"Show all"}>{text =><option value="">{text}</option>}</FormattedMessage> : null
                                    }
                                    {
                                        categories.map((item) => <option key={item} value={item}>{item}</option>)
                                    }
                                    {
                                        creatives.length >= 100 ? <FormattedMessage id={"Show all"}>{text =><option value="">{text}</option>}</FormattedMessage> : null
                                    }
                                </select>
                            </div>
                        }
                    </div>
                    {
                        this.state.loading && <ProgressLoader/>
                    }

                    <div className="d-flex"/>
                    <div className={'card-columns'}>
                        {cmp}
                    </div>
                </>
            );
        }
        return null;

    }
}

function mapStateToProps(state) {
    return {
        aff_file_upload: state.settings.aff_file_upload
    }
}

export default connect(mapStateToProps)(CreativesGrid)
