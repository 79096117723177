import React, { useEffect } from 'react';
import useQuery from "../../../cmp/hooks/useQuery";
import {post} from "../../../../api";
import Spinner from "../../../cmp/Spinner";
import {useHistory} from 'react-router-dom'
function StripeOnCallback(){
    const {session_id, plan_id} = useQuery()
    const history = useHistory()
    useEffect(()=>{
        if(session_id && plan_id){
            post('/paymentplans/activate',{
                data:{
                    session_id,
                },
                plan_id,
                payment_gateway:'stripe',
                }).then((data)=>{
                if(data.success){
                    window.location = "/"
                }else{
                    history.replace("/choose_plan")
                }
            })
        }
    },[session_id, plan_id])

    return <div className="d-flex min-vh-100 align-items-center justify-content-center">
        <Spinner size={"lg"}/>
    </div>;
}

export default StripeOnCallback
