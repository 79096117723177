import React from "react";
import Label from "../../auth/components/Label";

export default function FormGroup({children, label, required, htmlFor, info}){
    return <div className="form-group">
        {
            label && <><Label title={label} htmlFor={htmlFor}/>{required && <small className={"ml-1 text-danger"}>*</small>}</>
        }
        {children}
        {
            info && <small className={"text-muted"}>{info}</small>
        }
    </div>
}
