import React, {Component} from 'react';
import {connect} from 'react-redux';
import AuthContainer from './components/AuthContainer';
import FormattedHelmet from '../cmp/intl/FormattedHelmet';
import LogoRender from '../cmp/LogoRender';
import {FormattedMessage} from 'react-intl';
import RenderError from './components/RenderError';
import FormInput from './components/FormInput';
import StyledProgressLoader from '../cmp/themed/StyledProgressLoader';
import StyledButton from '../cmp/themed/StyledButton';
import Checkbox from '../cmp/form/Checkbox';
import * as localStorage from '../../tools/storage';
import {post} from '../../api';

class InvitationPage extends Component {
    state = {
        password: '',
        loading: '',
        show_password: true
    }

    onChange = (key) => (e) => {
        this.setState({[key]: e.target.value});
    }

    handleFormSubmit = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState({loading: true});
        const utm_source = localStorage.getItem('utm_source');
        post('/invite-register', {
            invite_key: this.props.match.params.invitation_key,
            password: this.state.password,
            utm_source,
        }).then((data) => {
            if (data.access_token) {
                localStorage.setItem('access_token', data.access_token);
                window.location = `/${this.props.isEmbedded ? '?embedded=1' : ''}`;
            } else {
                const {code} = data;
                this.setState({code, loading: false});
            }
        }).catch(() => {
            this.setState({loading: false});
        });
    }

    togglePasswordVisibility = (e) => {
        this.setState({show_password: !this.state.show_password});
    }

    isEnabled = () => this.state.password && this.state.password.length > 3

    render() {
        const {password, loading, show_password} = this.state;
        return (
            <AuthContainer>
                <FormattedHelmet id={'Create Password'}/>
                <div className={'text-center mb-4'}>
                    <LogoRender/>
                </div>
                <h1><FormattedMessage id={'Create Password'}/></h1>
                <div className={'card shadow-sm'}>
                    <div className={'card-body'}>
                        {
                            this.state.code && <RenderError code={this.state.code}/>
                        }
                        <form onSubmit={this.handleFormSubmit}>
                            <FormInput id={'password'}>
                                <input type={show_password ? 'text' : 'password'} autoComplete={'current-password'}
                                       value={password} name={'password'} onInput={this.onChange('password')}
                                       className={'form-control'}/>
                            </FormInput>
                            <Checkbox label={<FormattedMessage id={'Show password'} />} checked={show_password}
                                      onChange={this.togglePasswordVisibility} value={'show_password'}/>
                            {
                                loading ? <StyledProgressLoader/> :
                                    <StyledButton
                                        disabled={!this.isEnabled()}
                                        onClick={this.handleFormSubmit}
                                        className={'btn btn-primary'}>
                                        <FormattedMessage id={'Login'}/>
                                    </StyledButton>
                            }
                        </form>
                    </div>
                </div>
            </AuthContainer>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps
)(InvitationPage);
