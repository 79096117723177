import React, { useEffect, useState} from 'react';
import {FormattedMessage} from "react-intl";
import Button from "../../auth/components/Button";
import {get, post} from "../../../api";
import {connect} from "react-redux";
import Spinner from "../../cmp/Spinner";
import {Link} from "react-router-dom";
import PlanCard from "./components/PlanCard";

function SubscribePayment() {
    const [membership_plans, setMembershipPlans] = useState(null)
    const [loading, setLoading] = useState(false);
    const [planId, setPlanId] = useState(null)
    const [error, setError] = useState(null)
    useEffect(()=>{
        setLoading(true)
        get('/paymentplans/').then(({plans}) => {
            setLoading(false);
            setMembershipPlans(plans.sort((a, b) => a.cost - b.cost));
        })
    },[])

    const currentPlanId = () => {
        const morethanone = membership_plans.length > 1;
        return planId || (morethanone ? undefined : membership_plans[0].plan_id)
    }

    const onActivate = (plan_id) => {
        setLoading(true)
        setPlanId(plan_id)
        post('/paymentplans/payment_url', {
            plan_id
        }).then((data) => {
            setLoading(false);
            const {payment_gateway} = data;
            if(payment_gateway === "stripe"){
                document.location = data.session.url;
            }else if(payment_gateway === "paypal"){
                const {approve, error} = data;
                if (error) {
                    setError(error);
                } else {
                    document.location = approve;
                }
            }
        })
    }


    if (!membership_plans) {
        if (loading) {
            return <BigCentralSpinner/>
        }
        return null;
        }
        const num_plans = membership_plans.length;
        return <VerticalCenterContainer>
            <div className={"container"}>
                <h2 className={'text_on_bg text-center mb-4'}>
                    <FormattedMessage id={"Activate membership"}/>
                </h2>
                <div className={"row justify-content-center"}>
                    {
                        membership_plans.map((item) => {
                            const cls = Math.max(3, Math.min(12 / num_plans, 6))
                            return <div className={"mb-2 col-lg-" + cls} key={String(item.plan_id)}>
                                    <PlanCard loading={loading}
                                              isChosen={item.plan_id === planId}
                                              {...item}
                                              num_plans={num_plans} onChoose={()=>onActivate(item.plan_id)}/>
                                </div>
                            })
                        }
                    </div>
                    {
                        currentPlanId() && num_plans === 1 &&
                        <div className={"text-center mt-2"}>
                            {
                                error && <p className={"text-danger"}>{error}</p>
                            }
                            <Button className={"btn btn-primary"}
                                    loading={loading}
                                    onClick={()=>onActivate(currentPlanId())}>
                                <FormattedMessage id={"Continue"}/>
                            </Button>
                        </div>
                    }
                    <div className="mt-4 text-center">
                        <small>
                            <Link to={"/logout"}>
                                <FormattedMessage id={"Logout"}/>
                            </Link>
                        </small>
                    </div>
                </div>
            </VerticalCenterContainer>
}

function BigCentralSpinner() {
    return <VerticalCenterContainer>
        <Spinner size={"lg"}/>
    </VerticalCenterContainer>
}

function VerticalCenterContainer({children}) {
    return <div className="bg-light min-vh-100 d-flex align-items-center justify-content-center">
        {children}
    </div>
}


function mapStateToProps(state) {
    return {
        name: state.settings.name,
        email: state.settings.email
    }
}

export default connect(mapStateToProps)(SubscribePayment);
