import React from 'react';
import {connect} from 'react-redux';
import currencies from '../../aff-client-commons/currencies.json'
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
function CurrencyNumber({currency, value, intl}) {
//    return <FormattedNumber style={"currency"} value={value} currency={currency} />
    if(!currency || !currencies[currency.toUpperCase()]) return null;
    const {symbol_native, decimal_digits} = currencies[currency.toUpperCase()]
    if(decimal_digits) {
        return symbol_native + intl.formatNumber(value, {maximumFractionDigits: decimal_digits, minimumFractionDigits: decimal_digits})
    }else{
        return symbol_native + intl.formatNumber(value)
    }
}

CurrencyNumber.propTypes = {
    value: PropTypes.any.isRequired
};

const mapStateToProps = (state, props) => ({
    currency: props.currency || (state.settings.default_currency || 'USD')
});

export default connect(mapStateToProps)(injectIntl(CurrencyNumber));
