import {connect} from 'react-redux';
import currencies from '../../aff-client-commons/currencies.json';


const mapStateToProps = (state) => {
    return {
        default_currency: state.settings.default_currency || "USD"
    }
};


export default connect(mapStateToProps)((props) => {
    return currencies[props.default_currency].symbol_native
})
