import React, {Component} from 'react';
import {connect} from "react-redux";
import settingsStateToProps from "../../../redux/settingsStateToProps";
import FormGroup from "../../cmp/form/FormGroup";
import CopyButton from "../../cmp/CopyButton";
import {FormattedMessage}  from 'react-intl';

//exact copy for partner-frontend
class RenderUserPayment extends Component {

    render() {
        const { payment_method, payment_details_data, custom_payment_methods } = this.props;
        const payment_details_obj = payment_details_data && payment_details_data !== "null" ? typeof payment_details_data === "object" ? payment_details_data : JSON.parse(payment_details_data) : {};
        switch (payment_method) {
            case "tipalti": {
                return <div>
                    <string>Tipalti</string>
                </div>
            }
            case "cash": {
                return <div><strong>Cash</strong></div>
            }
            case "payoneer": {
                const {payoneer_email} = payment_details_obj;
                return <div>
                    <strong>Payoneer</strong>
                    <div>{payoneer_email}</div>
                </div>
            }
            case "cashapp": {
                const {cash_tag} = payment_details_obj;
                return <div>
                    <strong>CashApp</strong>
                    <div>{cash_tag}</div>
                </div>
            }
            case 'paypal': {
                const {paypal_email} = payment_details_obj;
                return (<div>
                    <strong>PayPal</strong>
                    <div>{paypal_email}</div>
                </div>);
            }
            case 'bank': {
                const {bank_name, account_number} = payment_details_obj;
                return (<div>
                    <strong>Bank Transfer</strong>
                    <div>{bank_name} - {account_number}</div>
                </div>);
            }
            case 'sepa': {
                const {account_number} = payment_details_obj
                return (
                    <div>
                        <strong>SEPA</strong>
                        {
                            account_number &&
                            <div>IBAN - <span>{account_number}</span></div>
                        }
                    </div>
                )
            }
            case 'cheque': {
                const {pay_to, address} = payment_details_obj;
                return (
                    <div>
                        <strong>Cheque</strong>
                        <div>{pay_to}</div>
                        <address>
                            {address}
                        </address>
                    </div>
                );
            }
            case 'venmo': {
                const {venmo_id} = payment_details_obj;
                return (<div>
                    <strong>Venmo</strong>
                    <div>{venmo_id}</div>
                </div>);
            }
            case 'upi': {
                const {upi_id} = payment_details_obj;
                return (<div>
                    <strong>UPI</strong>
                    <div>{upi_id}</div>
                </div>);
            }
            case 'paytm': {
                const {paytm_number} = payment_details_obj;
                return (<div>
                    <strong>PayTM</strong>
                    <div>{paytm_number}</div>
                </div>);
            }
            case "mpesa": {
                const {mobile_number} = payment_details_obj;
                return <div>
                    <strong>MPESA</strong>
                    <div>{mobile_number}</div>
                </div>
            }
            case "discountcoupon": {
                const c = payment_details_obj.coupon || payment_details_obj.code
                return (
                    <div>
                        <FormattedMessage id={"Store Discount Coupon"} tagName={"strong"}/>
                        {
                            c && <CopyButton
                                className={"d-block text-center bg-light py-2 text-monospace mt-2"}
                                style={{border: '1px dashed', width: '100%', letterSpacing: 2, fontSize: 24}} title={c.toUpperCase()}
                                text={c}/>
                        }
                    </div>
                )
            }
            case "storegiftcard": {
                const c = payment_details_obj.coupon || payment_details_obj.code
                return (
                    <div>
                        <FormattedMessage id={"Store Gift Card"} tagName={"strong"}/>
                        {
                            c && <CopyButton
                                className={"d-block text-center bg-light py-2 text-monospace mt-2"}
                                style={{border: '1px dashed', width: '100%', letterSpacing: 2, fontSize: 24}} title={c.toUpperCase()}
                                text={c}/>
                        }
                    </div>
                )
            }
            case 'debitcard': {
                const {cardnumber} = payment_details_obj;
                return (
                    <div>
                        <strong>Debit Card</strong>
                        <div>{cardnumber}</div>
                    </div>
                );
            }
            case 'mercado_pago': {
                const {email, document_number} = payment_details_obj;
                return (
                    <div>
                        <strong>Mercado Pago</strong>
                        <div>{email} - {document_number}</div>
                    </div>
                );
            }
            case "alipay": {
                const {alipay_id} = payment_details_obj;
                return (
                    <div>
                        <strong>AliPay</strong>
                        <div>{alipay_id}</div>
                    </div>
                )
            }
            case "wechat": {
                const {wechat_id} = payment_details_obj;
                return (
                    <div>
                        <strong>WeChat</strong>
                        <div>{wechat_id}</div>
                    </div>
                )
            }
            case "paynow": {
                const {paynow_mobile} = payment_details_obj;
                return (
                    <div>
                        <strong>PayNow</strong>
                        <div>{paynow_mobile}</div>
                    </div>
                )
            }
            case "bkash": {
                const {bkash_mobile} = payment_details_obj;
                return (
                    <div>
                        <strong>BKash</strong>
                        <div>{bkash_mobile}</div>
                    </div>
                )
            }
        }

        const custom_payment = custom_payment_methods && custom_payment_methods
            .find((item)=>item.payment_method_id === payment_method);
        if(custom_payment) {
            return <div>
                <strong>{custom_payment.title}</strong>
                {
                    custom_payment.form.map((item) => {
                        return <FormGroup key={item.id}>
                            <small>{item.label}</small>
                            <div>{payment_details_obj[item.id]}</div>
                        </FormGroup>
                    })
                }
            </div>
        } else {
            return <span className={'text-danger'}>-</span>
        }
    }
}


export default connect(settingsStateToProps(['custom_payment_methods']))(RenderUserPayment);
