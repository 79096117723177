import React, {Component} from 'react';
import {del, get} from '../../api';
import CurrencyNumber from '../cmp/CurrencyNumber';
import fecha from 'fecha';
import RenderUserPayment from './payments/RenderUserPaymentSettings';
import {FormattedDate, FormattedMessage} from 'react-intl';
import FormattedHelmet from '../../components/cmp/intl/FormattedHelmet';
import Big from 'big.js';
import {connect} from "react-redux";
import PaymentInputPage from "./payments/PaymentInputPage";
import StoreDiscountCouponAutoRedeem from "./payments/StoreDiscountCouponAutoRedeem";
import CardWithBody from "../cmp/layout/CardWithBody";
import Spinner from "../cmp/Spinner";
import PaymentInvoiceUploader from "./payments/modals/PaymentInvoiceUploader";
import {actionMergeSetting} from "../../redux/settings/settingsAction";
import Button from "../auth/components/Button";
import StyledButton from "../cmp/themed/StyledButton";
import {ThreeDotsHorizontalIcon} from '../../icons/Icons'
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import UncontrolledDropdown from 'reactstrap/lib/UncontrolledDropdown'
import DropdownMenu from "reactstrap/lib/DropdownMenu";

class Payments extends Component {

    state = {
        amount_paid: 0,
        amount_pending: 0,
        last_payment: 0,
        data: []
    }

    loadPayments = () => {
        this.setState({loading: true})
        get('/payments').then(({data, amount_earned, amount_paid, amount_pending, amount_payable, payout_lag_days, last_payment, amount_under_request}) => {
            this.setState({
                loading: false,
                amount_paid,
                amount_pending,
                last_payment,
                data,
                amount_payable,amount_under_request,
                payout_lag_days
            });
        });
    }

    componentDidMount() {
        this.loadPayments();
    }

    toggleEditPayments = () => {
        this.setState({showPaymentsModal: !this.state.showPaymentsModal});
    }

    toggleAutopayModal = () => {
        this.setState({autopay_dc_modal: !this.state.autopay_dc_modal}, () => {
            if (!this.state.autopay_dc_modal) {
                this.loadPayments();
            }
        })
    }

    toggleInvoiceUploadModal = (result) => {
        if(result) {
            this.loadPayments()
        }
        this.setState({invoice_upload_modal: !this.state.invoice_upload_modal})
    }

    requestPayment = () => {
        this.toggleInvoiceUploadModal();
    }
	cancelPaymentRequest = ()=>{
		this.setState({cancellingPaymentRequest: true})
		del('/payments/request/'+this.props.active_payment_request_id).then((row)=>{
		    if(row.success) {
                this.setState({cancellingPaymentRequest: false});
                this.loadPayments();
                this.props.mergeSettings({
                    active_payment_request_id: null
                })
            }
		})
	}

    render() {
        const {amount_paid, amount_pending, last_payment, amount_under_request, data, amount_payable, payout_lag_days} = this.state;
        const {payment_method, payment_details_data} = this.props;
        if (this.state.loading) {
            return <div className="my-4 py-4 text-center">
                <Spinner size={"lg"}/>
            </div>
        }
        return (
            <>
                <FormattedHelmet id={'navlinkPayments'}/>
                <div className={'mb-4'}>
                    <div className={'row'}>
                        <div className={'col'}>
                            <h2><FormattedMessage id={'navlinkPayments'}/></h2>
                        </div>
                        <div className={'col-auto text-right'}>
                            {
                                payment_method ?<>
                                        <RenderUserPayment payment_method={payment_method}
                                                           payment_details_data={payment_details_data}/>
                                        <button className={'btn btn-link btn-sm px-0'} onClick={this.toggleEditPayments} style={{color: "#FE91B0"}}>
                                        <FormattedMessage id={'Change'}/></button>
                                        </>
                                    : <StyledButton className={'btn btn-primary btn-sm'} onClick={this.toggleEditPayments}>
                                        <FormattedMessage id={'Setup'}/></StyledButton>
                            }
                            <button className={'btn btn-outline-secondary d-none'}><FormattedMessage id={'Download'}/>
                            </button>
                            <PaymentInputPage visible={this.state.showPaymentsModal} onClose={this.toggleEditPayments}/>
                        </div>
                    </div>
                    <div className="d-flex align-items-end justify-content-between">
                        <h4 className={'mt-4'}><FormattedMessage id={'summary'}/></h4>
                        {
                            this.props.minimum_payout &&
                            <div className={"text-muted"}><FormattedMessage id={'minimumPayout'}/> {':'} <CurrencyNumber
                                value={this.props.minimum_payout}/></div>
                        }
                    </div>

                    <div className={'card-group'}>
                        <AmountPaid amount_paid={amount_paid} />
                        <CardWithBody>
                            <div className="d-flex align-items-start justify-content-between">
                                <h3><CurrencyNumber value={amount_payable}/></h3>
                                {
                                    payment_method === "discountcoupon" && this.props.autopay_dc_enabled && <>
                                        <Button title={"Redeem"} className="btn btn-sm btn-outline-primary" onClick={this.toggleAutopayModal}/>
                                    </>
                                }
                                {
                                    payment_method !== "discountcoupon" && this.props.can_request_payments && amount_payable > 0 && !this.props.active_payment_request_id &&
                                    <Button title={"Request payment"} className="btn btn-sm btn-outline-primary"
                                            onClick={this.requestPayment}
                                    />
                                }
                            </div>
                            <FormattedMessage tagName={"small"} id={'amountPending'}/>
                        </CardWithBody>
                        {
                            this.props.active_payment_request_id  ? <CardWithBody>
                                <div className="d-flex align-items-start justify-content-between">
                                    <h3><CurrencyNumber value={amount_under_request}/></h3>
                                    {
                                        this.props.can_request_payments && amount_payable > 0 &&
                                        <Button title={"Cancel"} className="btn btn-sm btn-outline-danger"
                                                onClick={this.cancelPaymentRequest}
                                                loading={this.state.cancellingPaymentRequest}
                                        />
                                    }
                                </div>
                                <FormattedMessage tagName={"small"} id={'Amount Requested'}/>
                            </CardWithBody> : null
                        }
                        {
                            Number(payout_lag_days) && Big(amount_pending || 0).minus(amount_payable || 0).gte(0) ? <>
                                <CardWithBody>
                                    <div className="d-flex align-items-start justify-content-between">
                                        <h3><CurrencyNumber
                                            value={Big(amount_pending).minus(amount_payable).minus(amount_under_request).toString()}/></h3>
                                    </div>
                                    <small><FormattedMessage
                                        defaultMessage={"Due in {x, number} days"}
                                        id={'Due in x days'} values={{
                                            x: payout_lag_days,
                                            due: new Date(Date.now() + Number(payout_lag_days)*86400000)
                                        }}/></small>
                                </CardWithBody>
                            </> : null
                        }
                        <LastPayment last_payment={last_payment}/>
                    </div>
                    <h4 className={'mt-4'}><FormattedMessage id={'details'}/></h4>
                    {
                        data.length > 0 ?
                        <div className={'card-columns'}>
                            {
                                data.map((item) => <PayoutSectionItem  aff_invoices_enabled={this.props.aff_invoices_enabled} key={item.payout_id} {...item} />)
                            }
                        </div> : <p className={'alert alert-info'}>
                            <FormattedMessage id={'noPayments'}/>
                        </p>
                    }
                </div>
                {
                    payment_method === "discountcoupon" && this.props.autopay_dc_enabled &&
                    <StoreDiscountCouponAutoRedeem visible={this.state.autopay_dc_modal}
                                                   onClose={this.toggleAutopayModal}/>
                }
                {
                    payment_method !== "discount_coupon" &&
                    <PaymentInvoiceUploader min={0} max={amount_payable} visible={this.state.invoice_upload_modal}
                                            onClose={this.toggleInvoiceUploadModal}/>
                }
            </>
        );
    }
}

function LastPayment({last_payment}){
    return <CardWithBody>
        <h3>{last_payment ? fecha.format(new Date(last_payment), 'D MMM YYYY') : '-'}</h3>
        <small><FormattedMessage id={'lastPayment'}/></small>
    </CardWithBody>
}
function AmountPaid({amount_paid}){
    return  <CardWithBody>
        <h3><CurrencyNumber value={amount_paid}/></h3>
        <small><FormattedMessage id={'amountPaid'}/></small>
    </CardWithBody>
}

function PayoutSectionItem({ aff_invoices_enabled,downloadToken,  amount, currency, payout_date, partner_message, payment_method, payment_details_data}) {

    return (
        <div className={'card'}>
            <div className={'card-body'}>
                <div className={'row justify-content-between'}>
                    <div className={'col-md-6'}>
                        <h3><CurrencyNumber value={amount}/></h3>
                    </div>
                    <div className={'col-md-6'}>
                        {
                            aff_invoices_enabled && <div className="text-right">
                                <UncontrolledDropdown>
                                    <DropdownToggle color={"transparent"} className={"text-muted p-0"}>
                                        <span><ThreeDotsHorizontalIcon/></span>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <a href={`https://api-server-1.goaffpro.com/partner/payments/download/${downloadToken}`} className={"btn dropdown-item"} download>
                                            <FormattedMessage id={'Download invoice'}/>
                                        </a>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        }
                        <small className={'text-muted d-block text-right'}>{<FormattedDate value={new Date(payout_date)} month={"short"} year="numeric" day={"2-digit"}/>}</small>

                    </div>
                </div>
                <RenderUserPayment payment_method={payment_method} payment_details_data={payment_details_data}/>
            </div>
            {
                partner_message &&
                <p className={'bg-light m-2 p-2 rounded'} style={{whiteSpace: 'pre-line'}}>{partner_message}</p>
            }
        </div>
    );
}

function mapStateToProps(state) {
    const {active_payment_request_id, can_request_payments} = state.settings;
    return {
        minimum_payout: state.settings.minimum_payout,
        payment_method: state.settings.payment_method,
        payment_details_data: state.settings.payment_details_data,
        autopay_dc_enabled: state.settings.autopay_dc_enabled,
        w9_enabled: state.settings.w9_enabled,
        aff_invoices_enabled: state.settings.aff_invoices_enabled,
        can_request_payments,
        active_payment_request_id,

        payment_request_need_invoice: true || state.settings.payment_request_need_invoice,
    }
}

function mapDispatchToProps(dispatch){
	return {
		mergeSettings: (change)=>dispatch(actionMergeSetting(change))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
