import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import LogoRender from '../../cmp/LogoRender';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import FormattedHelmet from '../../cmp/intl/FormattedHelmet';
import RenderLegalDocLink from '../../cmp/RenderLegalDocLink';
import AccountCreationForm from './AccountCreationForm';
import {CheckIcon} from '../../../icons/Icons';

class CreateAccount extends Component {

    state = {
        loading: false,
        error: null
    }

    render() {
        const {bgImage, html, benefits} = this.props.signupData;
        return (
            <div className={'container-fluid px-0 min-vh-100'} style={{marginTop: 0}}>
                <FormattedHelmet id={this.props.signupPageTitle}/>
                <div className="row no-gutters" style={this.props.isEmbedded ? {} : {height: '100vh'}}>
                    <div className="col-md-6 d-none d-md-block">
                        <div className="d-flex align-items-center" id={"signupBannerImage"} style={{
                            backgroundImage: `${html && html.length > 5 ? 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),' : ''} url("${bgImage}")`,
                            height: '100%',
                            width: '100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: `${html && html.length > 5 ? 'cover' : 'contain'}`,
                            padding: 64,
                            overflowY: 'scroll',
                            maxHeight: this.props.isEmbedded ? '' : '100vh',
                            scrollbarWidth: 'none'
                            //position: 'relative'
                        }}
                        >
                            <div style={{color: 'white', margin: 'auto'}}>
                                <div dangerouslySetInnerHTML={{__html: html}}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6" style={this.props.isEmbedded ? {} : {
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        height: '100%'
                    }}>
                        <div className="d-block d-md-none">
                            <div className="d-flex align-items-center" id={"signupBannerImage"} style={{
                                backgroundImage: `${html && html.length > 5 ? 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),' : ''} url("${bgImage}")`,
                                width: '100%',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: `${html && html.length > 5 ? 'cover' : 'contain'}`,
                                scrollbarWidth: 'none'
                                //position: 'relative'
                            }}
                            >
                                <div style={{color: 'white', margin: 'auto'}} className={"p-4 m-4"}>
                                    <div dangerouslySetInnerHTML={{__html: html}}/>
                                </div>
                            </div>
                        </div>
                        <div className="row  justify-content-center" style={{paddingTop: 12}}>
                            <div className="col-md-9 col-11">
                                <div className="text-right">
                                    <Link to={'/login'} className={'btn btn-outline-secondary btn-sm signup_login_btn'}>
                                        <FormattedMessage id={"Login"}/>
                                    </Link>
                                </div>


                                <div className="text-center mb-2" style={{paddingTop: 48}}>
                                    <LogoRender/>
                                </div>
                                <h2 className={'text_on_bg text-center mb-4'}>
                                    <FormattedMessage id={this.props.signupPageTitle}/>
                                </h2>
                                {
                                    benefits && benefits.length > 0 && <BenefitsTable benefits={benefits}/>

                                }
                                <div className="card rounded-lg shadow">
                                    <div className="card-body">
                                        <AccountCreationForm page_data={this.props.page_data}
                                                             page_handle={this.props.page_handle}/>
                                    </div>
                                </div>

                                <small className={'d-block text-muted mt-4 px-4 text-center signup_footer_text'}>
                                    <FormattedMessage id={'tosAgree'}/>
                                </small>
                                <CreateAcctFooter isEmbedded={false}/>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function BenefitItem({text}) {
    return (<div className={'col-md-6 align-items-center py-2'}>
        <CheckIcon className={'text-success mr-2'}/>
        <span>{text}</span>
    </div>);
}

function BenefitsTable({benefits}) {
    return (<div className="p-2 mb-2 rounded border">
        <div className="row flex-wrap">
            {
                benefits.split('\n')
                    .map((text) => text.trim())
                    .filter((text) => text)
                    .map((text) => <BenefitItem key={text} text={text}/>)
            }
        </div>
    </div>);
}

function CreateAcctFooter({isEmbedded}) {

    return (<div>
        {
            !isEmbedded && <hr/>
        }
        {
            !isEmbedded &&
            <div className={'mt-4 mb-4 d-flex justify-content-between'}>
                <RenderFooterUrl type={'terms_url'}/>
                <RenderFooterUrl type={'privacy_url'}/>
            </div>
        }
    </div>);
}


function RenderFooterUrl({type}) {
    return (<small><RenderLegalDocLink type={type}/></small>);
}


function mapStateToProps(state, props) {
    const {isEmbedded, signupData, signupPageTitle} = props.page_data ? props.page_data : state.settings;
    return {
        signupData: signupData ? JSON.parse(signupData) : {},
        isEmbedded,
        page_data: props.page_data,
        signupPageTitle: signupPageTitle || 'Create Account',
    };
}

export default connect(mapStateToProps)(CreateAccount);
