import React, {useEffect, useMemo, useState} from 'react';
import FormGroup from '../../cmp/form/FormGroup';
import CurrencyNumber from '../../cmp/CurrencyNumber';
import Big from 'big.js';
import {useSelector} from 'react-redux';
import {FormattedDate} from 'react-intl';
import dayjs from '../../../mydayjs';
function getRateForGen(gen){
  if(gen  === 1) return 8;
  else if(gen === 2) return 4;
  else return 2;
}

let availableOptions = []
let start = dayjs.tz().month(0).year(2022).startOf('month').add(10, 'days')
const end = dayjs.tz().endOf('month')
do{
  availableOptions.push({
    value: start.format('MMMM[_]YYYY'),
    label: start.format('MMMM[,] YYYY')
  })
  start = start.add(1,'month')
}while(start.isBefore(end))

function EarningsReport(props) {
  const affiliate_id = useSelector((state)=>state.settings.affiliate_id)
  const [data, setData] = useState(null)
  const [key, setKey] = useState(dayjs.tz().endOf('month').format('MMMM_YYYY'))
  useEffect(()=>{
    if(affiliate_id){
      fetch('https://custom-api.goaffpro.com/glowb/comp-plan-data?affiliate_id='+affiliate_id+'&key='+key)
          .then((data)=>data.json())
          .then((data)=>{
            return setData(data)
      })
    }
  },[key, affiliate_id])
  const totalPayout = useMemo(()=>{
    if(!data) return 0;
    const {commission, genEarnings, totalBonus} = data;
    return Big(commission || 0).plus((genEarnings || []).reduce((acc, cur)=>acc.plus(cur), Big(0))).plus(totalBonus || 0).toNumber()
  },[data])
  if(!data) return null;
  const {volume, rate,totalTeamVolume, commission, genEarnings, genVolumes, totalBonus} = data;
  return (
      <div>
        <FormGroup label={"Choose month"}>
          <select className={"form-control"} value={key} onChange={(e)=>setKey(e.target.value)}>
            {
              availableOptions.map((item)=>{
                return <option key={item.value} value={item.value}>{item.label}</option>
              })
            }
          </select>
        </FormGroup>
        <div className="card-group mb-4">
          <div className="card">
            <div className="card-body">
              <h3>
                <CurrencyNumber value={volume} />
              </h3>
              <small>Sale Volume</small>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h3>
                <CurrencyNumber value={genVolumes ? genVolumes.reduce((acc, cur)=>acc.plus(cur), Big(0)).toString() : 0} />
              </h3>
              <small>Generation Volume</small>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h3>
                <CurrencyNumber value={totalTeamVolume || 0} />
              </h3>
              <small>Team Volume</small>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <h3>
                <CurrencyNumber value={Big(volume).plus(genVolumes ? genVolumes.reduce((acc, cur)=>acc.plus(cur), Big(0)) : 0).toString()} />
              </h3>
              <small>Total Volume</small>
            </div>
          </div>
        </div>
          <table className="table bg-white">
              <thead>
                <tr>
                  <th>Type</th>
                  <th className={"text-right"}>Volume</th>
                  <th className={"text-right"}>Commission</th>
                  <th className={"text-right"}>Earnings</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Sale Commission</td>
                  <td className={"text-right"}><CurrencyNumber value={volume}/></td>
                  <td className={"text-right"}>{rate}%</td>
                  <td className={"text-right"}><CurrencyNumber value={commission}/></td>
                </tr>
                {
                  genEarnings ? genEarnings.filter((item)=>item)
                  .map((item, index)=>{
                    return  <tr key={String(index)}>
                      <td>Team Generation {index+1}</td>
                      <td className={"text-right"}><CurrencyNumber value={genVolumes[index]}/></td>
                      <td className={"text-right"}>{getRateForGen(index+1)}%</td>
                      <td className={"text-right"}><CurrencyNumber value={item}/></td>
                    </tr>
                  }) : null
                }
                <RenderBonusTable totalBonus={totalBonus} />
              <tr>
                <th colSpan={3}>Total</th>
                <th className={"text-right"}><CurrencyNumber value={totalPayout}/></th>
              </tr>
              </tbody>
          </table>

          <div className="card mb-2">
            <div className="card-body text-center">
              <h2><CurrencyNumber value={totalPayout}/></h2>
              <small>Current payout</small>
            </div>
          </div>


              <ul className="list-group">
                <SalesReportItem data={data} />
                {
                  genVolumes ? genVolumes.filter((item)=>item).map((item, index)=>{
                    return <GenerationReportItem key={String(index)} generation={index+1} data={data}/>
                  }) : null
                }
              </ul>


      </div>
  );
}

function GenerationReportItem({generation, data}){
  const [visible, setVisible] = useState(false)
  return <>
    <li onClick={()=>setVisible(!visible)} className="list-group-item">Team Generation {generation} report</li>
    {
      visible &&
      <RenderGenerationTable generation={generation} data={data}/>
    }
  </>
}

function SalesReportItem({data}){
  const [visible, setVisible] = useState(false)
  return <>
    <li onClick={()=>setVisible(!visible)} className="list-group-item">Sales Report</li>
    {
      visible &&
          <RenderSalesTable data={data}/>
    }
  </>
}

function RenderSalesTable({data}){
  const {sales, rate, volume, commission} = data;
  const withEarnings = useMemo(()=>{
    if(sales) {
      return sales.map((item) => {
        return {
          ...item,
          commission: Big(item.cv).times(rate).div(100).toNumber(),
          order_date: new Date(item.order_date)
        }
      }).sort((a, b)=>new Date(b.order_date).getTime() - new Date(a.order_date).getTime())
    }
  },[sales, rate])
  if(!withEarnings) return null;
  return <>
    <table className="table bg-white">
      <thead>
      <tr>
        <th>Date</th>
        <th>Order Number</th>
        <th  className={"text-right"}>Volume</th>
        <th  className={"text-right"}>Earning</th>
      </tr>
      </thead>
      <tbody>
      {
        withEarnings.map((item)=>{
          return <tr key={item.site_order_number}>
            <td><FormattedDate value={item.order_date} month={"short"} day={"2-digit"} /></td>
            <td>{item.site_order_number}</td>
            <td  className={"text-right"}><CurrencyNumber value={item.cv} /></td>
            <td className={"text-right"}><CurrencyNumber value={item.commission} /></td>
          </tr>
        })
      }
      <tr>
        <td colSpan={2}  className={"text-right"}>Total</td>
        <td  className={"text-right"}><CurrencyNumber value={volume} /></td>
        <td  className={"text-right"}><CurrencyNumber value={commission} /></td>
      </tr>
      </tbody>
    </table></>
}

function RenderGenerationTable({generation, data}){

  const sales = useMemo(()=>{
    if(data.genSales) {
      const {sales} = data.genSales.find((item) => Number(item.generation) === Number(generation)) || {}
      if(!sales) {
        return null;
      }
      return sales.map((item) => {
        const rate = getRateForGen(generation);
        return {
          ...item,
          rate,
          order_date: new Date(item.order_date),
          earning: Big(rate).times(item.cv).div(100).toNumber()
        }
      }).sort((a, b)=>b.order_date.getTime() - a.order_date.getTime())
    }
    return null
  },[data, generation])
  if(!sales) return null;
  return  <>
    <table className="table bg-white">
      <thead>
      <tr>
        <th>Date</th>
        <th>Team Member</th>
        <th>Order Number</th>
        <th  className={"text-right"}>Volume</th>
        <th  className={"text-right"}>Rate</th>
        <th  className={"text-right"}>Earning</th>
      </tr>
      </thead>
      <tbody>
      {
        sales.map((item)=>{
          return <tr key={item.site_order_number}>
            <td><FormattedDate value={item.order_date} month={"short"} day={"2-digit"} /> </td>
            <td>{item.name}</td>
            <td>{item.site_order_number}</td>
            <td  className={"text-right"}><CurrencyNumber value={item.cv} /></td>
            <td  className={"text-right"}>{item.rate}%</td>
            <td  className={"text-right"}><CurrencyNumber value={item.earning} /></td>
          </tr>
        })
      }
      <tr>
        <td  className={"text-right"} colSpan={3}>Total</td>
        <td  className={"text-right"}><CurrencyNumber value={data.genVolumes[generation-1]} /></td>
        <td></td>
        <td className={"text-right"}><CurrencyNumber value={data.genEarnings[generation-1]} /></td>
      </tr>
      </tbody>
    </table>
  </>
}

function BonusItem({title, desc}){
  return  <tr><td colSpan={3}>{title}</td><td className={"text-right"}><CurrencyNumber value={desc}/></td></tr>
}
const bonusTitles = ['2.5','5','10','20','40','80']
function bonusToArray(totalBonus){
  let left = totalBonus
  return [100,150,250,500,500,1000]
      .filter((item)=>{
        left = left - item;
        return left >= 0
  })
}

function RenderBonusTable({totalBonus}){
  return <>
    {
      totalBonus >= 100 && <BonusItem title={"2.5K Bonus"} desc={100}/>
    }
    {
      totalBonus >= 250 && <BonusItem title={"5K Bonus"} desc={150}/>
    }
    {
      totalBonus >= 450 && <BonusItem title={"10K Bonus"} desc={250}/>
    }
    {
      totalBonus >= 950 && <BonusItem title={"20K Bonus"} desc={500}/>
    }
    {
      totalBonus >= 1450 && <BonusItem title={"40K Bonus"} desc={500}/>
    }
    {
      totalBonus >= 2450 && <BonusItem title={"80K Bonus"} desc={1000}/>
    }

  </>


}

function RenderBonusList({totalBonus}){
  return <>
    {
      totalBonus >= 100 && <CardListItem title={"2.5K Bonus"} desc={100}/>
    }
    {
      totalBonus >= 250 && <CardListItem title={"5K Bonus"} desc={150}/>
    }
    {
      totalBonus >= 450 && <CardListItem title={"10K Bonus"} desc={250}/>
    }
    {
      totalBonus >= 950 && <CardListItem title={"20K Bonus"} desc={500}/>
    }
    {
      totalBonus >= 1450 && <CardListItem title={"40K Bonus"} desc={500}/>
    }
    {
      totalBonus >= 2450 && <CardListItem title={"80K Bonus"} desc={1000}/>
    }

  </>


}


function CardListItem({title, desc}){
  return   <li className="list-group-item d-flex justify-content-between align-items-center">
    <span>{title}</span>
    <span>{desc}</span>
  </li>
}

export default EarningsReport;
