import React from 'react';
import {connect} from 'react-redux';
import Loader from 'halogenium/lib/PulseLoader';

//export default style;
function mapStateToProps(state) {
    return {
        themePrimaryColor: state.settings.themePrimaryColor
    };
}


export default connect(mapStateToProps)(({themePrimaryColor}) => <Loader loading color={themePrimaryColor || '#2780E3'}
                                                                         size="16px" margin="2px"/>);
