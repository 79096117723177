import React, {Component} from 'react';
import AuthContainer from './components/AuthContainer';
import Label from './components/Label';
import {post} from '../../api';
import LogoRender from '../cmp/LogoRender';
import StyledButton from '../cmp/themed/StyledButton';
import StyledProgressLoader from '../cmp/themed/StyledProgressLoader';
import {FormattedMessage} from 'react-intl';
import FormattedHelmet from '../cmp/intl/FormattedHelmet';
import FormInput from './components/FormInput';
import RenderError from './components/RenderError';
import * as localStorage from '../../tools/storage';
import FormGroup from "../cmp/form/FormGroup";
import Checkbox from "../cmp/form/Checkbox";
import {Link} from "react-router-dom";

class ResetPassword extends Component {

    state = {
        email: '',
        password: '',
        passwordVisible: true,
        loading: false,
        error: null,
        resetkey: ''
    }

    doReset = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        const {resetkey} = this.props.match.params || this.state;
        const {email, password} = this.state;
        this.setState({loading: true, errorCode: false});
        post('/resetpassword', {
            email, password, resetkey
        }).then((data) => {
            if (data.access_token) {
                localStorage.setItem('access_token', data.access_token);
                document.location = '/';
            } else {
                this.setState({error: data.error || 'Unexpected error', errorCode: data.code, loading: false});
            }
        }).catch((e) => {
            this.setState({loading: false});
        });
    }

    togglePasswordVisibility = (e) => {
        this.setState({passwordVisible: e.target.checked})
    }


    onChange = (key) => (e) => {
        this.setState({[key]: e.target.value});
    }

    render() {
        const {email, password, loading, errorCode, passwordVisible, resetkey} = this.state;
        return (
            <AuthContainer>
                <FormattedHelmet id={'resetPassword'}/>
                <div className={'text-center mb-4'}>
                    <LogoRender/>
                </div>
                <h1><FormattedMessage id={'resetPassword'}/></h1>
                <div className={'card shadow-sm'}>
                    <div className={'card-body'}>
                        <form onSubmit={this.doReset}>
                            {
                                errorCode && <RenderError code={errorCode}/>
                            }
                            {
                                !this.props.match.params.resetkey ?
                                    <div className={'form-group'}>
                                        <Label>Password Reset Code</Label>
                                        <input className={'form-control'} type={'code'}
                                               onInput={this.onChange('resetkey')} value={resetkey}
                                        />
                                    </div> : null
                            }

                            <FormInput id={'emailAddress'}>
                                <input className={'form-control'} autoComplete={'email'} name={'email'} type={'email'}
                                       onInput={this.onChange('email')} value={email}/>
                            </FormInput>
                            <FormInput id={'newPassword'}>
                                <input className={'form-control'}
                                       autoComplete={'new-password'}
                                       type={passwordVisible ? 'text' : 'password'}
                                       onInput={this.onChange('password')}
                                       value={password}
                                />
                            </FormInput>
                            <FormGroup>
                                <Checkbox label={<FormattedMessage id={"Show password"}/>} checked={passwordVisible}
                                          onChange={this.togglePasswordVisibility} value={'passwordVisible'}/>
                            </FormGroup>
                            <div className={'form-group'}>
                                {
                                    loading ? <StyledProgressLoader/> :
                                        <StyledButton className={'btn btn-primary'}>
                                            <FormattedMessage id={'resetPassword'}/>
                                        </StyledButton>
                                }
                            </div>
                        </form>
                    </div>
                </div>
                {
                    errorCode &&
                    <div className="mt-2 text-center">
                        <Link to={"/forgot-password"}><FormattedMessage id={"forgotPassword"}/></Link>
                    </div>
                }
            </AuthContainer>
        );
    }
}

export default ResetPassword;
