import React, {Component} from 'react';
import {post} from '../../../api';
import AuthContainer from './AuthContainer';
import StyledProgressLoader from '../../cmp/themed/StyledProgressLoader';
import RenderError from './RenderError';
import * as localStorage from '../../../tools/storage';
import {connect} from "react-redux";

function getCurrentQueryObject(search) {
    const map = {};
    const s = search || document.location.search;
    s.substr(1).split('&').map((item) => item.split('=')).forEach((x) => {
        map[x[0]] = decodeURIComponent(x[1]);
    });
    return map;
}

class OAuthCallback extends Component {

    state = {
        errorCode: null
    }

    componentDidMount() {

        const {type} = this.props.match.params;
        let endpoint = null;
        switch (type) {
            case 'facebook':
                endpoint = '/facebookauth';
                break;
            case 'google':
                endpoint = '/googleauth';
                break;
        }
        if (!endpoint) {
            return;
        }

        const {access_token} = getCurrentQueryObject(document.location.hash || document.location.search);
        if (access_token) {
            if (this.props.gtag_signup_conversion_id) {
                try {
                    window.gtag('event', 'conversion', {
                        'send_to': this.props.gtag_signup_conversion_id,
                    });
                } catch (e) {
                }
            }
            if(this.props.snapchat_pixel_id){
                try{
                    window.snaptr('track','SIGN_UP')
                }catch(e){

                }
            }
            if (this.props.fb_signup_conversion_event) {
                try {
                    window.fbq('track', this.props.fb_signup_conversion_event);
                } catch (e) {
                }
            }

            post(endpoint, {
                access_token//, shop: getShop()
            }).catch((e) => {
                console.log(e);
            }).then((ret) => {
                if (ret.access_token) {
                    localStorage.setItem('access_token', ret.access_token);
                    window.location = '/';
                } else if (ret.error) {
                    this.setState({errorCode: ret.code || 'NETWORK'});
                }
            });
        } else {
            window.location = '/create-account';
        }
    }


    render() {
        return (
            <AuthContainer>
                <div className={'text-center'}>
                    {this.state.errorCode ?
                        <RenderError code={this.state.errorCode}/> : <StyledProgressLoader/>
                    }
                </div>
            </AuthContainer>
        );
    }
}

function mapStateToProps(state, props) {
    const {fb_signup_conversion_event, gtag_signup_conversion_id, snapchat_pixel_id} = state.settings
    return {
        gtag_signup_conversion_id,
        fb_signup_conversion_event: fb_signup_conversion_event || 'AccountCreated',
        snapchat_pixel_id
    };
}


export default connect(mapStateToProps)(OAuthCallback);
