import React from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import StyledButton from "./themed/StyledButton";
export default function ({confirmLoading, onOk, onCancel, onClose, okText, cancelText, title, visible, onSubmit, children, size, disabled}) {
    const OkButton = okText ? typeof okText === "string" ? <FormattedMessage id={okText} /> : okText : <FormattedMessage id={"OK"} />
    const CancelButton = cancelText ? typeof  cancelText === "string" ? <FormattedMessage id={cancelText} /> : cancelText : <FormattedMessage id={"Cancel"} />
    return (
        <Modal isOpen={visible} toggle={onCancel || onClose} size={size}>
            {
                (title || onClose) &&
                <ModalHeader toggle={onCancel || onClose}>
                    {
                        typeof title === "string" ?
                            <FormattedMessage id={title} defaultMessage={title}/>
                            : title
                    }</ModalHeader>
            }
            <Form onSubmit={onSubmit}>
                <ModalBody>
                    {children}
                </ModalBody>
                {
                    (onCancel || onSubmit || onOk || onCancel) &&
                    <ModalFooter>
                        {
                            onCancel ?
                                <button type={"button"} className={"btn btn-outline mr-1"}
                                        onClick={onCancel}>{CancelButton}</button> :
                                null
                        }
                        {
                            (onSubmit || onOk || onCancel) ?
                            <StyledButton className={"btn"}
                                          loading={confirmLoading}
                                          type={"submit"} disabled={disabled}
                                          onClick={onSubmit || onOk || onCancel}>{OkButton}</StyledButton> : null
                        }
                    </ModalFooter>
                }
            </Form>
        </Modal>
    );
}


function Form({onSubmit, children}) {
    return onSubmit ? <form onSubmit={onSubmit}>{children}</form> : children
}


Modal.propTypes = {
    title: PropTypes.string,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    confirmLoading: PropTypes.bool,
    onOk: PropTypes.func,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'sm'])
}
