import {NextIcon, PreviousIcon} from "../../icons/Icons";
import {FormattedNumber} from 'react-intl'
function Pagination({count, limit, page, goToPage, setLimit}){
    const hasNext = Math.ceil(count/limit) > page+1;
    const hasPrev = page > 0;
    function nextPage(){
        goToPage(page+1)
    }
    function prevPage(){
        goToPage(page-1);
    }

    return  <nav aria-label="Table navigation">
        <div className="d-flex">

            <ul className="pagination">
                <select className={"form-control"} value={limit} onChange={(e)=>setLimit(e.target.value)}>
                    <Opt value={10}/>
                    <Opt value={25}/>
                    <Opt value={50}/>
                    <Opt value={100}/>
                </select>
                <li className={`page-item ${hasPrev ? '':'disabled'}`}>
                    <button onClick={prevPage} className={`page-link`} disabled={!hasPrev}><PreviousIcon/></button></li>
                <li className={`page-item ${hasNext ? '' : 'disabled'}`}>
                    <button onClick={nextPage} className="page-link" disabled={!hasNext}><NextIcon/></button>
                </li>
            </ul>
        </div>
    </nav>
}

function Opt({value}){
    return    <FormattedNumber value={value}>
        {(message) => <option value={value}>{message}</option>}
    </FormattedNumber>
}

export default Pagination
