import {del, get, post} from "../../api";


export function fetchLinks(){
    return (dispatch, getState)=>{
        return get('/linkbox').then((data)=>{
            dispatch({
                type:'set-links', data: data.links
            })
            return data.links
        }).catch((e)=>{

        })
    }
}
export function deleteLink(id){
    return (dispatch, getState)=>{
        return del('/linkbox/'+id).then((data)=>{
            dispatch({
                type:'delete-link', id
            })
        }).catch((e)=>{

        })
    }
}
export function saveLink(url, type = 'manual'){
    return (dispatch, getState)=>{
        return post('/linkbox/',{
            url, type
        }).then((data)=>{
            dispatch({
                type:'insert-link',
                data,
            })
        })
    }
}
