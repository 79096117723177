

export default (state = {}, action)=>{
    switch (action.type){
        case "set-commissions":
            return action.commissions || state;
        default:
            return state
    }
}
