import axios from 'axios';
import * as localStorage from "../tools/storage";
export const isDev = process.env.NODE_ENV === 'development' || localStorage.getItem('isdev');
export const apiServer = isDev ? 'https://api-server-1.goaffpro.com' : 'https://api2.goaffpro.com';


export function getHttpClient(){
    const headers = {
    }
    if(localStorage.getItem('access_token')){
       headers.Authorization = 'Bearer '+localStorage.getItem('access_token')
    }
    headers['x-shop'] = localStorage.getItem('x-shop') || document.location.hostname;
    return axios.create({
        baseURL:apiServer+"/v1/user",
        headers
    })
}

