import React, {Component} from 'react';
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import PropTypes from 'prop-types';

function  UnsafeJsRender({partner_portal_subdomain, script}){
    if(!script) return null;
    if (!partner_portal_subdomain || partner_portal_subdomain.endsWith('.goaffpro.com')) {
        return <>
            {
                /*
                    Custom javascript can only be used on  custom domains
               */
            }
        </>
    } else {


        return (
            <>
                <Helmet>
                    <script type={"text/javascript"}>{script}</script>
                </Helmet>
            </>
        );
    }
}
function mapStateToProps(state, props) {
    const {partner_portal_subdomain} = state.settings;
    return {
        partner_portal_subdomain
    }
}

UnsafeJsRender.propTypes = {
    script: PropTypes.string
}

export default connect(mapStateToProps)(UnsafeJsRender);
