import React, { useState} from 'react';
import Modal from "../../../cmp/Modal";
import Label from "../../../auth/components/Label";
import SimpleFileUpload from "../../../cmp/SimpleFileUpload";
import {post} from "../../../../api";
import {connect} from "react-redux";
import {actionMergeSetting} from "../../../../redux/settings/settingsAction";
import DefaultCurrencyRender from "../../../cmp/DefaultCurrencyRender";
import FormGroup from "../../../cmp/form/FormGroup";
import InputGroup from "../../../cmp/form/InputGroup";
import useInputState from "../../../cmp/hooks/useInputState";
import {FormattedMessage} from 'react-intl'
import CurrencyNumber from "../../../cmp/CurrencyNumber";
function PaymentInvoiceUploader({mergeSettings, visible, max, onClose}){
    const [amount, setAmount] = useInputState('');
    const [note, setNote] = useInputState('');
    const [invoice_url, setInvoiceUrl] = useState('');
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(null)
    function onFileUpload(invoice){
        setInvoiceUrl(invoice)
    }

    function onSubmit(e){
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        setSaving(true)
        post('/payments/request',{
                invoice_url,
                amount, note,
        }).then((row)=>{
            setSaving(false)
            if(row.success){
                mergeSettings({
                    active_payment_request_id: row.active_payment_request_id
                })
                onClose(true)
            }else if(row.error){
                setError(row.error)
            }
            }).catch((e)=>{
            setSaving(false)

        })
        }
        return (
            <Modal visible={visible}
                   okText={"Submit"}
                   confirmLoading={saving}
                   title={"Request payment"}
                   onCancel={()=>onClose(false)}
                   onSubmit={onSubmit}
                   disabled={!amount}>

                <FormGroup>
                    <Label title={"Amount"} required/>
                    <InputGroup prefix={<DefaultCurrencyRender />}
                                value={amount}
                                max={max}
                                onChange={setAmount}
                                type={"number"} min={0} />
                    <small className={"text-muted"}>Max: <CurrencyNumber value={max} /> </small>
                </FormGroup>
                <FormGroup>
                    <Label title={"Invoice"}/>
                    <SimpleFileUpload onChange={onFileUpload}/>
                </FormGroup>
                <FormGroup>
                    <Label title={"Note"}/>
                    <textarea className="form-control"
                              value={note}
                              onChange={setNote}/>
                </FormGroup>
                {
                    error && <p className="text-danger text-center"><FormattedMessage id={error} /></p>
                }
            </Modal>
        );
}

function mapDispatchToProps(dispatch){
    return {
        mergeSettings: (change)=>dispatch(actionMergeSetting(change))
    }
}

export default connect(null, mapDispatchToProps)(PaymentInvoiceUploader);
