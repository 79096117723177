import React, {Component} from 'react';
import AuthContainer from './components/AuthContainer';
import LogoRender from '../cmp/LogoRender';
import FormattedHelmet from '../cmp/intl/FormattedHelmet';
import AccountPendingApproval from "../cmp/AccountPendingApproval";

class AccountPendingBlock extends Component {

    render() {
        return (
            <AuthContainer>
                <FormattedHelmet id={'Login'}/>
                <div className={'text-center mb-4'}>
                    <LogoRender/>
                </div>
                <AccountPendingApproval/>
            </AuthContainer>
        );
    }
}


export default AccountPendingBlock
