import React, {useEffect} from 'react';
import useQuery from "../../../cmp/hooks/useQuery";
import {post} from "../../../../api";
import Spinner from "../../../cmp/Spinner";
import { useHistory } from "react-router-dom";
function PaypalOnCallback(){
    const {token, payerId, plan_id, subscription_id} = useQuery()
    const history = useHistory();
    useEffect(()=>{
        if(plan_id && (token|| subscription_id)){
            post('/paymentplans/activate', {
                payment_gateway:'paypal',
                plan_id,
                data:{
                    token,
                    subscription_id,
                    payerId,
                }
            }).then((data) => {
                if (data.success) {
                    window.location = "/";
                } else {
                    history.replace("/choose_plan")
                }
            }).catch((e) => {
                history.replace("/choose_plan")
            })
        }
    },[token, payerId, plan_id, subscription_id])

    return <div className="d-flex min-vh-100 align-items-center justify-content-center">
        <Spinner size={"lg"}/>
    </div>;
}

export default PaypalOnCallback
