import React, {Component} from 'react';
import Modal from "../Modal";
import LazyRenderQRCode from "./LazyRenderQRCode";
import {FormattedMessage} from "react-intl";
import {apiServer} from "../../../api";

class QrCodeModal extends Component {
    render() {
        return (
            <Modal visible={this.props.visible} title={"QR Code"} onClose={this.props.onClose}>
                <div className={"p-4 text-center"}>
                    <LazyRenderQRCode value={this.props.value} size={240} level={'H'}/>
                </div>
                <div className="text-center">
                    <a href={`${apiServer}/extras/qr?data=${encodeURIComponent(this.props.value)}`}>
                        <FormattedMessage id={"Download"}/>
                    </a>
                </div>

            </Modal>
        );
    }
}

export default QrCodeModal;
