import React, {Component} from 'react';
import * as localStorage from "../../../tools/storage";
import {post} from "../../../api";
import Spinner from "../../cmp/Spinner";
import {connect} from "react-redux";

class ShopifyLoginIndex extends Component {
    state = {}

    componentDidMount() {
//        if (!localStorage.getItem('access_token')) {
            this.doLogin();
//        } else {
            // localStorage.setItem('access_token', localStorage.getItem('access_token'));
//            window.location = "/"
//        }
    }

    doLogin = () => {
        this.setState({loading: true, error: false})
        post('/auth/shopify-login', {
            customer_id: this.props.match.params.customer_id,
            force: !this.props.sso_no_account_page
        }).then((data) => {
            if (data.access_token) {
                localStorage.setItem('access_token', data.access_token);
                window.location = "/?embedded=1&is_shopify_login=1";
            } else if (this.props.sso_no_account_page) {
                this.props.history.replace(this.props.sso_no_account_page)
            } else {
                const {code} = data;
                this.setState({code, ...data, loading: false, error: true});
            }
        }).catch((e) => {
            this.setState({loading: false, error: true});
        });
    }

    render() {
        if (!this.props.subscription) return null;

        if (this.state.loading) {
            return <div className="container"><Spinner/></div>
        } else if (this.state.error) {
            return <div className="container">
                <p className={"text-danger"}>{this.state.error || "Error logging in"} </p>
            </div>
        }
        return null;
    }
}

function mapStateToProps(state) {
    return {
        sso_no_account_page: state.settings.sso_no_account_page,
        subscription: state.settings.subscription,
    }
}

export default connect(mapStateToProps)(ShopifyLoginIndex);
