import React, {Component} from 'react';
import HeroContent from "./HeroContent";

class HeroBanner extends Component {
    state = {
        height: 320
    }
    getHeight = () => {
        if (this.contentRef) {
            return this.contentRef.clientHeight + 80;
        }
        return 320;
    }

    handleRef = (ref) => {
        if (ref) {
            this.setState({height: ref.clientHeight + 80})
            this.contentRef = ref;
        }
    }

    render() {
        const {hero_banner, hero_align, max_height, hero_textcolor} = this.props;
        let height = max_height ? max_height : Math.max(this.state.height || this.getHeight(), 320)
        return (<div id={"hero_background"} style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${hero_banner}")`,
                height: `${height}px`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                position: 'relative'
            }}
            >
                <div style={{
                    textAlign: hero_align || 'left',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: hero_textcolor || 'white'
                }} className={'container mx-auto'}
                     ref={this.handleRef}
                >
                    <HeroContent  {...this.props} />
                </div>
            </div>
        );
    }
}

export default HeroBanner;
