import React from 'react';
import {FormattedMessage} from "react-intl";
import DefaultCommissionRender from "./DefaultCommissionRender";
import StyledLink from "../../../cmp/themed/StyledLink";

function HeroContent(props) {
    const {hero_text, hero_supertext, hero_subtext, hero_btntext} = props;
    return (<div>
        {
            hero_supertext && <span style={{whiteSpace: 'pre-line'}}> <FormattedMessage defaultMessage={hero_supertext}
                                                                                        id={hero_supertext} values={{
                commission_value: <DefaultCommissionRender/>
            }}
            /></span>
        }
        {
            hero_text && <h1 style={{whiteSpace: 'pre-line'}}>
                <FormattedMessage defaultMessage={hero_text} id={hero_text} values={{
                    commission_value: <DefaultCommissionRender/>
                }}
                /></h1>
        }
        {
            hero_subtext && <p style={{whiteSpace: 'pre-line'}}>
                <FormattedMessage id={hero_subtext}
                                  defaultMessage={hero_subtext === "herosubtext" ? ' ' : hero_subtext} values={{
                    commission_value: <DefaultCommissionRender/>
                }}/>
            </p>
        }
        {
            hero_btntext &&
            <StyledLink to={'/create-account'} className={'btn btn-primary btn-lg'}><FormattedMessage id={hero_btntext}
                                                                                                      values={{
                                                                                                          commission_value:
                                                                                                              <DefaultCommissionRender/>
                                                                                                      }}/></StyledLink>}
    </div>);
}

export default HeroContent;
