import React, {Component} from 'react';
import AuthContainer from './components/AuthContainer';
import LogoRender from '../cmp/LogoRender';
import FormattedHelmet from '../cmp/intl/FormattedHelmet';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";

class AccountBlockedBlock extends Component {

    render() {
        return (
            <AuthContainer>
                <FormattedHelmet id={'Login'}/>
                <div className={'text-center mb-4'}>
                    <LogoRender/>
                </div>
                <AccountBanned/>
            </AuthContainer>
        );
    }
}

function AccountBanned() {
    return (<p className={'alert alert-danger'}>
        <FormattedMessage id={'accountBannedMessage'}/>
    </p>);
}

function mapStateToProps() {
    return {}
}

export default connect()(AccountBlockedBlock)
