import React, {Component} from 'react';
import {connect} from 'react-redux';
import componentslist from './help/components/componentslist';
import LandingContainer from "./landing/components/LandingContainer";
import TopNavBar from "./landing/components/TopNavBar";
import LandingFooter from "./landing/components/LandingFooter";
import HeroComponent from "./landing/components/HeroComponent";

class CustomPage extends Component {


    render() {
        if(!this.props.email){
            return   <LandingContainer>
                <TopNavBar/>
                <div className={'bg-dark'}>
                    <HeroComponent/>
                </div>
                <div className={'bg-white'}>
                    <div className={'container mx-auto py-4'}>
                        <div className={'my-4 card'}>
                            <div className="card-body">
                            {
                                this.props.content.map((item, index) => {
                                    const Cmp = componentslist[item.type];
                                    return <Cmp {...item.props} key={item.blockId}/>;
                                })
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <LandingFooter/>
            </LandingContainer>
        }
        return (
            <div>
                {
                    this.props.content.map((item, index) => {
                        const Cmp = componentslist[item.type];
                        return <Cmp {...item.props} key={item.blockId}/>;
                    })
                }
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    let aff_extra_tabs = [];
    if(state.settings.aff_extra_tabs){
        aff_extra_tabs = typeof  state.settings.aff_extra_tabs === "string" ? JSON.parse(state.settings.aff_extra_tabs) : state.settings.aff_extra_tabs
    }
    const page = aff_extra_tabs.find((item) => item.id === props.match.params.page_id) || {};
    return {
        content: page.content || [],
        email: state.settings.email
    };
}

export default connect(mapStateToProps)(CustomPage);
